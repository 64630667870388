import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link, useNavigate } from 'react-router-dom';
import validator from 'validator';

import { Button } from '../atoms/Button';
import { RichInputHandler } from '../molecules/RichInput';

import { PracticeFragment } from '../../gen/graphql';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { defaultErrorMessage } from '../../const/ErrorMessage';
import { useToastsContext } from '../../context/ToastsProvider';
import { PracticeSubmitInputContent } from './PracticeSubmitInputContent';
import { getApiErrorMessage } from '../../utils/graphqlError';
import { isCommentLengthValid, isGitHubURLValidFormat } from '../../utils/PracticeSubmit';

export interface PracticeSubmitResponseProps {
  isError: boolean;
  practiceSubmitID: string;
  practiceSubmitDetailID: string;
}

export interface Edit {
  // 編集用
  practiceSubmitID: string;
  initialUrl?: string;
  initialComment?: string;
  fetch: () => Promise<void>;
}

interface PracticeSubmitInputAreaProps {
  practice: PracticeFragment;
  draft: (url: string, comment?: string) => Promise<PracticeSubmitResponseProps>;
  submit: (
    url: string,
    comment?: string,
    requestsInstructorReview?: boolean,
  ) => Promise<PracticeSubmitResponseProps>;
  // 未提出の場合はundefined
  isInstructorReviewRequested?: boolean;
  edit?: Edit;
}

export const PracticeSubmitInputArea: React.FC<PracticeSubmitInputAreaProps> = ({
  practice,
  draft,
  submit,
  isInstructorReviewRequested,
  edit,
}): JSX.Element => {
  const {
    submitButtonClicked,
    url,
    urlError,
    commentRef,
    disabledDraft,
    disabledSubmit,
    handleURLChange,
    handleCommentKeyUp,
    handleClickDraft,
    handleClickSubmit,
  } = usePracticeSubmitInput({ practice, draft, submit, edit });

  return (
    <FormContainer>
      <PracticeSubmitInputContent
        practice={practice}
        url={url}
        urlError={urlError}
        commentRef={commentRef}
        onCommentKeyUp={handleCommentKeyUp}
        onURLChange={handleURLChange}
      />
      {practice?.isSubmittable ? (
        <>
          <ButtonArea>
            {edit && (
              <PageBack to={`/practices/submits/${edit.practiceSubmitID}`}>提出画面へ戻る</PageBack>
            )}
            <DraftButton
              onClick={handleClickDraft}
              disabled={submitButtonClicked || disabledDraft}
              border
            >
              下書きを保存
            </DraftButton>
            {isInstructorReviewRequested === false && (
              <RequestInstructorReviewButton
                onClick={() => handleClickSubmit(true)}
                disabled={submitButtonClicked || disabledSubmit}
                border
              >
                講師による再レビューを依頼
              </RequestInstructorReviewButton>
            )}
            <SubmitButton
              onClick={() => handleClickSubmit(false)}
              disabled={submitButtonClicked || disabledSubmit}
            >
              課題を提出する
            </SubmitButton>
          </ButtonArea>
          {isInstructorReviewRequested === false && (
            <FooterNotice>
              AI先生のレビュー内容に誤りがある場合は、講師によるレビューを依頼してください。
            </FooterNotice>
          )}
        </>
      ) : (
        <FooterNotice>
          <p>現在、この課題の提出は受け付けておりません。</p>
          <p>※理由については、お問い合わせいただいても回答いたしかねます。</p>
        </FooterNotice>
      )}
    </FormContainer>
  );
};

const usePracticeSubmitInput = ({
  practice,
  draft,
  submit,
  edit,
}: {
  practice: PracticeFragment;
  draft: (url: string, comment?: string) => Promise<PracticeSubmitResponseProps>;
  submit: (
    url: string,
    comment?: string,
    requestsInstructorReview?: boolean,
  ) => Promise<PracticeSubmitResponseProps>;
  edit?: Edit;
}) => {
  const [disabledDraft, setDisabledDraft] = useState(true);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [urlError, setUrlError] = useState('');

  const { url, handleInputUrl } = useURLField(edit?.initialUrl ?? '');
  const { commentRef } = useCommentField(edit);
  const { handleClickDraft, handleClickSubmit, submitButtonClicked } = usePracticeSubmitButton(
    url,
    commentRef,
    draft,
    submit,
    edit,
  );

  const handleValidate = (url: string, comment: string, isAiReviewEnabled: boolean) => {
    const validComment = isCommentLengthValid(comment.trim().length);

    if (isAiReviewEnabled) {
      if (!url.length) {
        setUrlError('');
        setDisabledDraft(!validComment);
        setDisabledSubmit(true);
        return;
      }
      if (!isGitHubURLValidFormat(url)) {
        setUrlError('課題で指定されている形式でGitHubのURLを入力してください。');
        setDisabledDraft(true);
        setDisabledSubmit(true);
        return;
      }
    } else {
      if (!url.length) {
        setUrlError('');
        setDisabledDraft(!validComment);
        setDisabledSubmit(!validComment);
        return;
      }

      if (!validator.isURL(url)) {
        setUrlError('正しいURLが入力されていません。');
        setDisabledDraft(true);
        setDisabledSubmit(true);
        return;
      }
    }

    setUrlError('');
    setDisabledDraft(false);
    setDisabledSubmit(!validComment);
  };

  const handleURLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    const comment = commentRef.current?.getValue() ?? '';

    handleInputUrl(e);
    handleValidate(url, comment, practice.isAiReviewEnabled);
  };

  const handleCommentKeyUp = (e: React.KeyboardEvent) => {
    const comment = (e.currentTarget as HTMLTextAreaElement).value;
    handleValidate(url, comment, practice.isAiReviewEnabled);
  };

  useEffect(() => {
    if (edit) {
      handleValidate(url, edit?.initialComment || '', practice.isAiReviewEnabled);
    }

    // AB#15171 初期表示でhandleValidateを発火させたいため、第二引数は空で問題なし
    // editが変化した後、上記がトリガーされる可能性があるのは「下書きを保存」ボタン押下後のみで、
    // 押下前にバリデーションが実行済みであるため問題ない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    submitButtonClicked,
    url,
    urlError,
    commentRef,
    disabledDraft,
    disabledSubmit,
    handleClickDraft,
    handleClickSubmit,
    handleURLChange,
    handleCommentKeyUp,
  };
};

export const useURLField = (initialUrl: string) => {
  const [url, setUrl] = useState(initialUrl);

  const handleInputUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUrl(value);
  };

  return { url, handleInputUrl };
};

const useCommentField = (edit?: Edit) => {
  const commentRef = useRef<RichInputHandler>(null);

  useEffect(() => {
    const comment = edit?.initialComment ?? '';
    commentRef.current?.setValue(comment);
  }, [edit?.initialComment]);
  return { commentRef };
};

const usePracticeSubmitButton = (
  url: string,
  commentRef: React.RefObject<RichInputHandler>,
  draft: (url: string, comment?: string) => Promise<PracticeSubmitResponseProps>,
  submit: (
    url: string,
    comment?: string,
    requestsInstructorReview?: boolean,
  ) => Promise<PracticeSubmitResponseProps>,
  edit?: Edit,
) => {
  const navigate = useNavigate();
  const { showToast } = useToastsContext();

  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const handleClickDraft = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setSubmitButtonClicked(true);

      try {
        const { isError, practiceSubmitID, practiceSubmitDetailID } = await draft(
          url,
          commentRef.current?.getValue(),
        );

        if (isError) {
          showToast(1, defaultErrorMessage);
          return;
        }
        showToast(0, '課題を下書き保存しました。');
        if (edit) {
          await edit.fetch();
        } else {
          navigate(`/practices/submits/${practiceSubmitID}/details/${practiceSubmitDetailID}/edit`);
        }
      } catch (e) {
        showToast(1, getApiErrorMessage(e));
        return;
      } finally {
        setSubmitButtonClicked(false);
      }
    }, [draft, url, commentRef, showToast, edit, navigate]),
  );

  const handleClickSubmit = useSafeAsyncCallback(
    useCallback(
      async (requestsInstructorReview?: boolean): Promise<void> => {
        setSubmitButtonClicked(true);

        try {
          const { isError, practiceSubmitID } = await submit(
            url,
            commentRef.current?.getValue(),
            requestsInstructorReview,
          );

          if (isError) {
            showToast(1, defaultErrorMessage);
            return;
          }
          showToast(0, '課題を提出しました。');
          navigate(`/practices/submits/${practiceSubmitID}?submitted=true`);
        } catch (e) {
          showToast(1, getApiErrorMessage(e));
          return;
        } finally {
          setSubmitButtonClicked(false);
        }
      },
      [submit, url, commentRef, showToast, navigate],
    ),
  );
  return { submitButtonClicked, handleClickDraft, handleClickSubmit };
};

const FormContainer = styled.div`
  max-width: 1044px;
  margin: 1.5rem auto 0;
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;

  position: relative;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;
const PageBack = styled(Link)`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  line-height: 1.5rem;

  position: absolute;
  top: 0.5rem;
  left: 0;

  &:before {
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.625rem;
    background: transparent center / contain no-repeat;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10.6523%201.7832C10.3857%201.7832%2010.1738%201.87891%209.98926%202.05664L4.74609%207.17676C4.51367%207.40918%204.4043%207.64844%204.39746%207.94238C4.39746%208.23633%204.50684%208.48242%204.74609%208.70801L9.98926%2013.835C10.1738%2014.0127%2010.3926%2014.1084%2010.6523%2014.1084C11.1855%2014.1084%2011.6094%2013.6846%2011.6094%2013.1582C11.6094%2012.8984%2011.5%2012.6523%2011.3086%2012.4609L6.66016%207.94238L11.3086%203.43066C11.5%203.23926%2011.6094%202.99316%2011.6094%202.7334C11.6094%202.20703%2011.1855%201.7832%2010.6523%201.7832Z%22%20fill%3D%22black%22%20fillOpacity%3D%220.87%22%2F%3E%3C%2Fsvg%3E');
  }

  ${media.lessThan('medium')`
    position: static;
    order: 3;
  `}
`;

const DraftButton = styled(Button)`
  ${media.lessThan('medium')`
    order: 1;
  `}
`;
const RequestInstructorReviewButton = styled(Button)`
  ${media.lessThan('medium')`
    order: 1;
  `}
`;
const SubmitButton = styled(Button)`
  ${media.lessThan('medium')`
    order: 2;
  `}
`;

const FooterNotice = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-top: 2rem;
`;
