import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import {
  ProgramDetailFragment,
  QuestionListFragment,
  QuestionSearchInput,
  QuestionSearchType,
  QuestionSortKey,
  useGetTagsLazyQuery,
  SearchQuestionStatus,
  TagOrder,
} from '../../gen/graphql';

import { QuestionCard } from './QuestionCard';
import { QuestionFilter } from './QuestionFilter';
import { QuestionFilterModal } from './QuestionFilterModal';
import Pagination from './Pagination';

import FilterIcon from '../../static/image/icon_filter.svg';

import { FIRST_PAGE, FETCH_LIMIT_MIDDLE } from '../../const/Page';

import { getAnswered, getSolved } from '../../utils/QuestionFilter';
import { getChapters, getPractices } from '../../utils/Program';
import { chapterOrPracticeOptionValue } from '../../utils/Filter';
import { ALL, INITIAL, INITIAL_ULID } from '../../const/Filter';
import { useURL } from '../../common/customHooks/URL';
import { strToEnum } from '../../utils/common';

interface QuestionTabContentProps {
  permissionCheck: (functionType: string, permissionType: string) => boolean;
  questionsTotal: number;
  questionsItems: QuestionListFragment[];
  fetchQuestions: (input: QuestionSearchInput) => void;
  program: ProgramDetailFragment;
  // 教材QAの時設定
  programID?: number;
  // 章QAの時設定
  chapterID?: number;
  // 課題QAの時設定
  practiceID?: string;
}

export const QuestionTabContent: React.FC<QuestionTabContentProps> = ({
  permissionCheck,
  questionsTotal,
  questionsItems,
  fetchQuestions,
  program,
  programID,
  chapterID,
  practiceID,
}) => {
  const { setParams, queries } = useURL();

  const page = queries?.page ? Number(queries.page) : FIRST_PAGE;
  const sortKey = queries?.sort_key ?? QuestionSortKey.QuestionFavoriteCount;
  const answered = queries?.answered ? Number(queries.answered) : ALL;
  const solved = queries?.solved ? Number(queries.solved) : ALL;
  const searchQuestionStatus =
    strToEnum(queries?.search_question_status ?? '', SearchQuestionStatus) ??
    SearchQuestionStatus.All;
  const queryChapterID = queries?.chapter_id ? Number(queries.chapter_id) : INITIAL;
  const queryPracticeID = queries?.practice_id ? queries.practice_id : INITIAL_ULID;
  const tagID = queries?.tag_id ? Number(queries.tag_id) : INITIAL;

  const [searchTag, setSearchTag] = useState<string>('');
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const getChapterID = useCallback(() => {
    // 章QAの時URLパラメータを設定
    if (chapterID) return chapterID;
    // 教材QAの時入力値を設定
    if (queryChapterID) return queryChapterID;
    return undefined;
  }, [queryChapterID, chapterID]);

  const getPracticeID = useCallback(() => {
    // 課題QAの時URLパラメータを設定
    if (practiceID) return practiceID;
    // 教材QAの時入力値を設定
    if (queryPracticeID) return queryPracticeID;
    return undefined;
  }, [queryPracticeID, practiceID]);

  const questionQuery = useMemo(
    (): QuestionSearchInput => ({
      type: QuestionSearchType.All,
      page: page,
      limit: FETCH_LIMIT_MIDDLE,
      sortKey:
        Object.values(QuestionSortKey).find((value) => value === sortKey) ??
        QuestionSortKey.QuestionFavoriteCount,
      tagID: tagID ? tagID : undefined,
      // 教材QAの時URLパラメータを設定
      programID: programID ? programID : undefined,
      chapterID: getChapterID(),
      practiceID: getPracticeID(),
      solved: getSolved(solved),
      searchQuestionStatus: searchQuestionStatus,
      answered: getAnswered(answered),
    }),
    [
      answered,
      getChapterID,
      getPracticeID,
      page,
      programID,
      searchQuestionStatus,
      solved,
      sortKey,
      tagID,
    ],
  );

  const [getTags, { data: tagData }] = useGetTagsLazyQuery();
  const tagsData = tagData?.getTags;

  const fetchTags = useCallback(
    (name: string) => {
      getTags({
        variables: {
          input: {
            name,
            limit: FETCH_LIMIT_MIDDLE,
            page: FIRST_PAGE,
            order: TagOrder.QuestionCount,
          },
        },
      });
    },
    [getTags],
  );

  const handleIsFilterModalOpenSet = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  const handleSortKeySelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'sort_key', value: value }, { name: 'page' }]);
  };

  const handleAnsweredSelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'answered', value: value }, { name: 'page' }]);
  };

  const handleSolvedSelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'solved', value: value }, { name: 'page' }]);
  };

  const handleSearchQuestionStatusSelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'search_question_status', value: value }, { name: 'page' }]);
  };

  const handleChapterIDOrPracticeIDSelect = (value: string) => {
    const [chapterID, practiceID] = chapterOrPracticeOptionValue(value);
    // 検索条件変更時はpageをリセット
    setParams([
      { name: 'chapter_id', value: chapterID },
      { name: 'practice_id', value: practiceID },
      { name: 'page' },
    ]);
  };

  const handleSearchTagSet = (value: string) => {
    setSearchTag(value);
  };

  const handleTagReset = () => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'tag_id', value: INITIAL.toString() }, { name: 'page' }]);
  };

  const handleTagIDSelect = (value: number) => {
    // 検索条件変更時はpageをリセット
    setParams([
      { name: 'tag_id', value: value !== tagID ? value.toString() : INITIAL.toString() },
      { name: 'page' },
    ]);
  };

  const setPage = useCallback(
    (value: number) => {
      setParams([{ name: 'page', value: value.toString() }]);
    },
    [setParams],
  );

  const questionFilterModalProps = {
    isOpen: isFilterModalOpen,
    toggle: handleIsFilterModalOpenSet,
    permissionCheck: permissionCheck,
    sortKey,
    answered,
    solved,
    searchQuestionStatus,
    // 教材QAの時表示
    chapterID: programID ? queryChapterID : undefined,
    // 教材QAの時表示
    practiceID: programID ? queryPracticeID : undefined,
    searchTag,
    setSearchTag,
    tagID,
    forProgramAndChapterOrPractice: {
      chapters: getChapters(program) ?? [],
      practices: getPractices(program) ?? [],
    },
  };

  const questionFilterProps = {
    permissionCheck: permissionCheck,
    title: '絞り込み',
    displaySortKey: {
      sortKey,
      handleSortKeySelect,
    },
    displayAnswered: {
      answered,
      handleAnsweredSelect,
    },
    displaySolved: {
      solved,
      handleSolvedSelect,
    },
    displaySearchQuestionStatus: {
      searchQuestionStatus,
      handleSearchQuestionStatusSelect,
    },
    // 教材QAの時表示
    displayChapterOrPractice: programID
      ? {
          chapters: getChapters(program) ?? [],
          chapterID: queryChapterID,
          practices: getPractices(program) ?? [],
          practiceID: queryPracticeID,
          handleChapterIDOrPracticeIDSelect,
        }
      : undefined,
    displayTag: {
      searchTag,
      handleSearchTagSet,
      tags: tagsData?.items ?? [],
      tagID,
      handleTagReset,
      handleTagIDSelect,
    },
  };

  useEffect((): void => {
    if (!program) return;
    fetchTags(searchTag);
  }, [program, fetchTags, searchTag]);

  useEffect(() => {
    fetchQuestions(questionQuery);
  }, [fetchQuestions, questionQuery]);

  return (
    <QuestionContainer>
      {isFilterModalOpen && <QuestionFilterModal {...questionFilterModalProps} />}
      <ListContainer id={'qaContainer'}>
        <QuestionHeader>
          <FilterImg src={FilterIcon} alt="filter" onClick={handleIsFilterModalOpenSet} />
        </QuestionHeader>
        <QuestionList>
          {questionsTotal ? (
            questionsItems.map((question) => {
              if (
                !question.id ||
                !question.title ||
                !question.content ||
                !question.user ||
                !question.updatedAt
              )
                return;
              return <QuestionCard key={question.id} question={question} />;
            })
          ) : (
            <Err>この教材への質問はありません。</Err>
          )}
        </QuestionList>
        <Pagination
          total={questionsTotal}
          perPage={FETCH_LIMIT_MIDDLE}
          page={page}
          setPage={setPage}
        />
      </ListContainer>
      <FilterContainer>
        <QuestionFilter {...questionFilterProps} />
      </FilterContainer>
    </QuestionContainer>
  );
};

const QuestionContainer = styled.div`
  display: flex;
  width: 100%;
`;

const ListContainer = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  scroll-margin-top: 114px;

  ${media.lessThan('medium')`
    padding: 0 1rem;
    box-sizing: border-box;
  `}
`;
const QuestionList = styled.div`
  ${media.lessThan('medium')`
    margin: 0 -1rem 1rem;
  `}
`;
const Err = styled.p`
  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`;

const QuestionHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FilterImg = styled.img`
  display: none;

  ${media.lessThan('medium')`
    display: flex;
  `}
`;

const FilterContainer = styled.div`
  height: 100%;
  width: 284px;
  margin-left: 1.25rem;

  ${media.lessThan('medium')`
    display: none;
  `}
`;
