import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { StripeInvoiceFragment as StripeInvoiceResponse } from '../../gen/graphql';
import { InvoiceStatusLabels } from '../../const/Invoice';
import { format, parseISO } from 'date-fns';
import LinkIcon from '../../static/image/icon_weblink_red.svg';

interface InvoiceListProps {
  invoices: StripeInvoiceResponse[];
}

export const InvoiceList: React.FC<InvoiceListProps> = ({ invoices }) => {
  const formatDate = (date: string) => {
    return format(parseISO(date), 'yyyy/MM/dd');
  };

  return (
    <>
      <List className="title">
        <DateTitle>決済日</DateTitle>
        <NameTitle>商品名</NameTitle>
        <PriceTitle>金額</PriceTitle>
        <StatusTitle>ステータス</StatusTitle>
        <LinkTitle>請求書</LinkTitle>
      </List>
      {invoices.map((invoice) => {
        if (invoice.stripeInvoiceLines.length > 0) {
          return (
            <List key={invoice.id}>
              <Date>{formatDate(invoice.created)}</Date>
              <Name>
                {
                  // NOTE: 即時アップデートのように複数件のInvoiceが来た場合の対応として請求書を利用する
                  invoice.stripeInvoiceLines[invoice.stripeInvoiceLines.length - 1].price
                    .productDetail?.name
                }
              </Name>
              <Price>¥{invoice.total.toLocaleString()}(税込)</Price>
              <Status>{InvoiceStatusLabels[invoice.status]}</Status>
              <LinkItem>
                {invoice.invoicePDF ? (
                  <a href={invoice.invoicePDF} target="_blank">
                    詳細を見る
                    <img src={LinkIcon} />
                  </a>
                ) : (
                  '-'
                )}
              </LinkItem>
            </List>
          );
        } else {
          return (
            <List key={invoice.id}>
              <Date>{formatDate(invoice.created)}</Date>
              <Name>-</Name>
              <Price>¥{invoice.total.toLocaleString()}(税込)</Price>
              <Status>{InvoiceStatusLabels[invoice.status]}</Status>
              <LinkItem>
                {invoice.invoicePDF ? (
                  <a href={invoice.invoicePDF} target="_blank">
                    詳細を見る
                    <img src={LinkIcon} />
                  </a>
                ) : (
                  '-'
                )}
              </LinkItem>
            </List>
          );
        }
      })}
    </>
  );
};

const ItemTitle = styled.dt`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;

  ${media.lessThan('large')`
    font-size: 0.75rem;
  `}
`;
const Item = styled.dd`
  font-size: 0.875rem;

  ${media.lessThan('large')`
    font-size: 0.75rem;
  `}
`;
const List = styled.dl`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &.title {
    margin-top: 3rem;
    padding-top: 0;
  }

  ${ItemTitle} + ${ItemTitle} {
    margin-left: 1.75rem;
  }

  ${media.lessThan('large')`
    ${ItemTitle} + ${ItemTitle} {
      margin-left: 1rem;
    }
  `}

  ${media.lessThan('medium')`
    flex-wrap: wrap;
    justfy-content: space-between;

    &.title {
      display: none;
    }

    &:nth-of-type(2) {
      margin-top: 2rem;
      border-top: 1px solid rgba(0,0,0,0.1);
    }
  `}
`;
const Date = styled(Item)`
  width: 5rem;

  ${media.lessThan('medium')`
    order: 1;
    width: auto;
    margin: 0 auto 0 0;
    font-size: 1rem;
  `}
`;
const Name = styled(Item)`
  flex: 1;
  margin-left: 1.75rem;
  line-height: 1.35;

  ${media.lessThan('large')`
    margin-left: 1rem;
  `}

  ${media.lessThan('medium')`
    order: 3;
    flex: none;
    width: 100%;
    margin: 1.5rem auto 0.75rem;
    font-size: 1rem;
  `}
`;
const Price = styled(Item)`
  width: 6.75rem;
  margin-left: 1.75rem;
  font-weight: 700;

  ${media.lessThan('large')`
    margin-left: 1rem;
  `}

  ${media.lessThan('medium')`
    order: 5;
    width: auto;
    margin: 0 0 0 auto;
    font-size: 1.125rem;
  `}
`;
const Status = styled(Item)`
  width: 5.3125rem;
  margin-left: 1.75rem;

  ${media.lessThan('large')`
    width: 4.3125rem;
  `}

  ${media.lessThan('medium')`
    order: 2;
    margin: 0 0 0 auto;
    color: rgba(0,0,0,0.6);
    font-size: 0.875rem;
    font-weight: 700;
  `}
`;
const LinkItem = styled(Item)`
  width: 5.6875rem;
  margin-left: 1.75rem;

  a {
    display: flex;
    align-items: center;
    color: #e2001b;
    font-size: 0.875rem;

    img {
      display: inline-block;
      margin-left: 0.25rem;
    }
  }

  ${media.lessThan('large')`
    margin-left: 1rem;
    text-align: right;

    a {
      font-size: 0.75rem;
    }
  `}

  ${media.lessThan('medium')`
    order: 5;
    width: 100%;
    margin: 1.5rem auto 0;

    a {
      display: inline;
      font-size: 1rem;

      img {
        display: none;
      }
    }
  `}
`;
const DateTitle = styled(ItemTitle)`
  width: 5rem;
`;
const NameTitle = styled(ItemTitle)`
  flex: 1;
`;
const PriceTitle = styled(ItemTitle)`
  width: 6.75rem;
`;
const StatusTitle = styled(ItemTitle)`
  width: 5.3125rem;

  ${media.lessThan('large')`
    width: 4.3125rem;
  `}
`;
const LinkTitle = styled(ItemTitle)`
  width: 5.6875rem;
`;
