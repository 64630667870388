import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { HistoryModal } from './HistoryModal';
import { UpdateModal } from './UpdateLessonModal';

import { Button } from '../atoms/Button';
import { EmptyBlock } from '../molecules/EmptyBlock';
import { UserIcon } from './UserIcon';

import { InstructorLessonFragment as Lesson } from '../../gen/graphql';
import { FunctionType, PermissionType } from '../../const/UserPermission';

import { getFullDateJST } from '../../utils/DateJST';
interface LessonProps {
  permissionCheck: (functionType: string, permissionType: string) => boolean;
  nextLessonRegisterable: boolean;
  allLessons: Lesson[];
  followingLessons: Lesson[];
  previousLessons: Lesson[];
  refetch: () => Promise<void>;
}

export const LessonList: React.FC<LessonProps> = ({
  permissionCheck,
  nextLessonRegisterable,
  allLessons,
  followingLessons,
  previousLessons,
  refetch,
}) => {
  const next = followingLessons[0]
    ? getFullDateJST(followingLessons[0].startAt, followingLessons[0].endAt)
    : {
        date: '',
        day: '',
        fromToTime: '',
      };

  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [currentLesson, setCurrentLesson] = useState<Lesson>();

  const closeUpdateModal = () => {
    setUpdateModalOpen(false);
  };

  const closeHistoryModal = () => {
    setHistoryModalOpen(false);
  };

  return (
    <Container>
      {allLessons.length >= 1 ? (
        <div>
          <NextLessonContainer>
            <CheckList>
              <h4>専属レッスン前確認項目</h4>
              <ol>
                <li>1. 遅刻厳禁・60分以上のレッスンを行う</li>
                <li>2. アジェンダ・資料は事前に用意</li>
                <li>3. 身だしなみを整える</li>
                <li>4. Webカメラの写る範囲の背景を清潔にする</li>
                <li>5. 音声・画面接続確認を5分前に行う</li>
              </ol>
            </CheckList>
            {(nextLessonRegisterable || followingLessons.length > 0) && (
              <NextLesson>
                <h4>
                  {followingLessons.length > 0
                    ? '次回専属レッスン'
                    : '次回以降の専属レッスンが未登録の状態です。'}
                </h4>
                {followingLessons.length > 0 ? (
                  <div>
                    <section>
                      <NextLessonDate>
                        <time>
                          {next.date}
                          <span>({next.day})</span>
                        </time>
                        <time>{next.fromToTime}</time>
                      </NextLessonDate>

                      <div>
                        <h5>{followingLessons[0].title}</h5>

                        <UserIcon user={followingLessons[0].course?.student?.user} small name />
                      </div>
                    </section>
                    {permissionCheck(FunctionType.LessonForInstructor, PermissionType.Update) && (
                      <NextLessonEdit
                        onClick={() => {
                          setUpdateModalOpen(true);
                          setCurrentLesson(followingLessons[0]);
                        }}
                      >
                        編集
                      </NextLessonEdit>
                    )}
                  </div>
                ) : (
                  <EmptyNextLesson>まだ次回の専属レッスンが登録されていません</EmptyNextLesson>
                )}
              </NextLesson>
            )}
          </NextLessonContainer>
          <ContentTitle>今後の専属レッスン予定</ContentTitle>
          <LessonListContainer>
            {followingLessons.length >= 1 ? (
              followingLessons
                // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
                // eslint-disable-next-line eqeqeq
                .filter((lesson) => lesson != followingLessons[0])
                .map((lesson) => {
                  const fullDate = getFullDateJST(
                    lesson.startAt ? lesson.startAt : '',
                    lesson.endAt ? lesson.endAt : '',
                  );

                  return (
                    <LessonContent>
                      <LessonDate>
                        <h3>
                          {fullDate.date}
                          <span>({fullDate.day})</span>
                        </h3>
                        <p>{fullDate.fromToTime}</p>
                      </LessonDate>
                      <LessonInfo>
                        <LessonTitle>{lesson.title}</LessonTitle>
                        <InfoArea>
                          <UserIcon user={lesson.course?.student?.user} huge name />
                          {permissionCheck(
                            FunctionType.LessonForInstructor,
                            PermissionType.Update,
                          ) && (
                            <ButtonArea>
                              <LessonEdit
                                onClick={() => {
                                  setUpdateModalOpen(true);
                                  setCurrentLesson(lesson);
                                }}
                              >
                                編集
                              </LessonEdit>
                            </ButtonArea>
                          )}
                        </InfoArea>
                      </LessonInfo>
                    </LessonContent>
                  );
                })
            ) : (
              <EmptyBlock title="レッスン予定がありません"></EmptyBlock>
            )}
          </LessonListContainer>

          <ContentTitle>これまでの専属レッスン（計 {previousLessons.length} 回）</ContentTitle>
          <LessonListContainer>
            {previousLessons.length >= 1 ? (
              previousLessons.map((lesson, i: number) => {
                const fullDate = getFullDateJST(
                  lesson.startAt ? lesson.startAt : '',
                  lesson.endAt ? lesson.endAt : '',
                );
                return (
                  <LessonContent key={i}>
                    <LessonDate>
                      <h3>
                        {fullDate.date}
                        <span>({fullDate.day})</span>
                      </h3>
                      <p>{fullDate.fromToTime}</p>
                      {lesson.canceled ? (
                        <CancelLabel>
                          キャンセル
                          <br />
                          (レッスン消化)
                        </CancelLabel>
                      ) : (
                        ''
                      )}
                    </LessonDate>
                    <LessonInfo>
                      <LessonTitle>{lesson.title}</LessonTitle>
                      <InfoArea>
                        <StyledUserIcon user={lesson.course?.student?.user} huge name />
                        <ButtonArea>
                          {permissionCheck(
                            FunctionType.LessonForInstructor,
                            PermissionType.Update,
                          ) && (
                            <>
                              <LessonEdit
                                onClick={() => {
                                  setUpdateModalOpen(true);
                                  setCurrentLesson(lesson);
                                }}
                              >
                                編集
                              </LessonEdit>
                              <LessonEdit
                                onClick={() => {
                                  setHistoryModalOpen(true);
                                  setCurrentLesson(lesson);
                                }}
                                border={Boolean(lesson.completed)}
                              >
                                受講履歴{lesson.completed ? '編集' : '登録'}
                              </LessonEdit>
                            </>
                          )}
                        </ButtonArea>
                      </InfoArea>
                    </LessonInfo>
                  </LessonContent>
                );
              })
            ) : (
              <EmptyBlock title="まだレッスン履歴がありません">
                過去のレッスンの一覧がここに表示されます。
              </EmptyBlock>
            )}
          </LessonListContainer>
        </div>
      ) : (
        <EmptyBlock title="まだ専属レッスンが登録されていません">
          レッスン日程を登録しましょう
        </EmptyBlock>
      )}

      {currentLesson && currentLesson.id ? (
        <>
          <HistoryModal
            isOpen={historyModalOpen}
            lessonID={currentLesson.id}
            onClose={closeHistoryModal}
            refetch={refetch}
          ></HistoryModal>
          <UpdateModal
            isOpen={updateModalOpen}
            lessonID={currentLesson.id}
            onClose={closeUpdateModal}
            refetch={refetch}
          ></UpdateModal>
        </>
      ) : (
        ''
      )}
    </Container>
  );
};

const Container = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: top;
`;

const NextLessonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1.25rem;
  box-sizing: border-box;
  margin-bottom: 2rem;
`;

const CheckList = styled.div`
  flex: 1;
  padding: 1.25rem;
  background-color: #e2001b62;
  box-sizing: border-box;

  h4 {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  li {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  ${media.lessThan('large')`
    width: 100%;
  `}
`;

const NextLesson = styled.div`
  width: calc(100% - 400px);
  padding: 1.25rem;
  background-color: #ffe86d;
  box-sizing: border-box;
  line-height: 1.3;
  position: relative;

  h4 {
    margin-bottom: 1.5rem;
    font-weight: 600;
  }

  section {
    display: flex;
  }

  h5 {
    margin-bottom: 1rem;
    font-weight: 600;
  }

  ${media.lessThan('large')`
    width: 100%;
    margin-left: 0;
  `}

  ${media.lessThan('small')`
    section {
      flex-direction: column;
    }
  `}
`;
const NextLessonDate = styled.div`
  margin-right: 2rem;
  text-align: center;

  time {
    display: block;
    font-size: 0.75rem;

    &:nth-of-type(1) {
      font-size: 1.5rem;
      font-weight: 600;

      span {
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }

  ${media.lessThan('medium')`
    time:nth-of-type(1) {
      font-size: 1.25rem;

      span {
        font-size: 0.75rem;
      }
    }
  `}

  ${media.lessThan('small')`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto 1rem;

    time {
      &:nth-of-type(1) {
        margin-right: 0.5rem;
      }
    }
  `}
`;
const EmptyNextLesson = styled.p`
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: 700;
`;
const LessonEdit = styled(Button)`
  padding-left: 1rem;
  padding-right: 1rem;
`;
const NextLessonEdit = styled(LessonEdit)`
  position: absolute;
  bottom: 1.25rem;
  right: 1.25rem;
`;

const ContentTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 700;
  padding: 2rem 0;
`;

const LessonTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 700;
`;

const InfoArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: calc(100% - calc(20px + 48px));
  padding-top: 10px;

  ${media.lessThan('large')`
    width: 100%;
  `}

  ${media.lessThan('medium')`
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
  `}
`;

const LessonListContainer = styled.div`
  width: 100%;
`;
const LessonContent = styled.div`
  display: flex;
  margin: 0 auto;

  &:nth-of-type(n + 2) {
    margin-top: 3rem;
  }

  ${media.lessThan('medium')`
    height: auto;

    &:nth-of-type(n + 2) {
      margin-top: 2rem;
    }
  `}

  ${media.lessThan('small')`
    flex-direction: column;
    width: 100%;
    padding: 1rem 2rem 0;
    border-top: 1px dotted #ddd;
    box-sizing: border-box;

    &:nth-of-type(n + 2) {
      margin-top: 1rem;
    }
  `}
`;

const LessonDate = styled.div`
  width: 100px;
  margin-right: 2rem;
  vertical-align: top;
  text-align: center;

  h3 {
    margin-bottom: 4px;
    font-size: 25px;
    font-weight: 700;
  }

  span {
    font-size: 15px;
    font-weight: 400;
  }

  ${media.lessThan('small')`
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: auto;
    text-align: left;
    position: relative;

    h3 {
      margin-right: 0.5rem;
      font-size: 20px;
    }

    span,
    p {
      font-size: 12px;
    }
  `}
`;
const LessonInfo = styled.div`
  flex: 1;

  h3 {
    word-wrap: break-word;
  }

  ${media.lessThan('small')`
    margin-top: 1rem;
  `}
`;
const CancelLabel = styled.div`
  margin: 0.25rem auto 0;
  padding: 0.25rem 0;
  border: 1px solid #e2001b;
  border-radius: 0.25rem;
  color: #e2001b;
  font-size: 0.625rem;
  font-weight: 700;

  ${media.lessThan('small')`
    margin: 0;
    padding: 0.25rem 0.5rem;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
  `}
`;

const StyledUserIcon = styled(UserIcon)`
  flex: 1;
  word-break: break-all;
`;

const ButtonArea = styled.div`
  display: flex;
  gap: 1rem;

  ${media.lessThan('medium')`
    justify-content: flex-end;
    width: 100%;
  `}
`;
