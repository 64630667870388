import { startOfMonth, addMonths, endOfMonth, format } from 'date-fns';
import { isEmpty } from 'fp-ts/Array';

import { ClaimCategory as ClaimCategoryConst } from '../../common/Const';

import { Button } from '../atoms/Button';
import { Modal } from '../molecules/Modal';
import { InputWithSearch } from '../molecules/InputWithSearch';
import DatePicker from 'react-datepicker';

import {
  useGetHistoricalCoursesForInstructorLazyQuery,
  useGetClaimCategoriesForInstructorQuery,
  useUpdateClaimDetailForInstructorMutation,
  useGetSpotLessonsForInstructorQuery,
  SpotLessonFragment as SpotLesson,
  HistoricalCourseFragment as Course,
  ClaimDetailFragment as ClaimDetail,
  ClaimDetailInput,
  SpotLessonPhase,
  useAddClaimDetailForInstructorMutation,
} from '../../gen/graphql';
import { useCallback, useState } from 'react';
import { insertCommaDelimiter, removeCommaDelimiter } from '../../utils/common';
import styled from 'styled-components';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { useToastsContext } from '../../context/ToastsProvider';
import { getApiErrorMessage } from '../../utils/graphqlError';
import { filterCourseByClaimCategory, formatCourses } from '../../const/Course';
import { Errors, Fields, FieldStatuses } from '../../types/ClaimDetail';
import {
  defaultErrors,
  defaultFields,
  defaultFieldStatuses,
  hasErrors,
} from '../../const/ClaimDetail';

interface AddOrEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => Promise<void>;
  // 本モーダル初期表示時の「日付」
  initialDate: Date;
  // 請求申請月の1日(UTC)
  yearMonth: Date;
  width: number;
  claimDetail: ClaimDetail | undefined;
}

// TODO: AB#13186 Reactのバージョンアップ後に警告が表示されないことを確認。
// 現在の形だと Can't perform a React state update on an unmounted component. という警告が表示される。
// cf. https://github.com/TeamSamurai/lms-api/pull/4645#discussion_r1976851168
export const AddOrEditModal: React.FC<AddOrEditModalProps> = ({
  isOpen,
  onClose,
  refetch,
  initialDate,
  yearMonth,
  width,
  claimDetail,
}) => {
  // データ取得
  const { categories, spotLessons, courses, fetchCourses } = useFetcher(yearMonth);
  const { initialFields, initialSelectedDate } = createInitialFieldStates(
    claimDetail,
    courses,
    initialDate,
  );
  const initialFieldStatuses = changeFieldStatuses(claimDetail?.claimCategory?.id.toString());

  // 入力フォームのハンドラ
  const {
    selectedDate,
    fields,
    fieldStatuses,
    errors,
    handleChangeCategory,
    handleChangeLesson,
    handleChangeSpotLesson,
    handleChangeCourse,
    handleChangeAmount,
    handleChangeCharaAmount,
    handleChangeUnitPrice,
    handleChangeCharaUnitPrice,
    handleChangeAdditionalPayment,
    handleChangePerItemUnitPrice,
    handleChangePerItemAmount,
    handleChangeRemark,
    handleChangeSelectedDate,
    resetFields,
    validateFields,
  } = useFieldsHandler(
    initialFields,
    initialSelectedDate,
    initialFieldStatuses,
    spotLessons,
    courses,
  );

  // 送信処理
  const { handleSubmit } = useSubmit(
    fields,
    selectedDate,
    yearMonth,
    validateFields,
    refetch,
    onClose,
    resetFields,
    claimDetail,
  );

  const handleClose = () => {
    resetFields();
    onClose();
  };

  return (
    <Modal
      underlayer
      isOpen={isOpen}
      onClose={handleClose}
      header={`請求申請 ${claimDetail ? '編集' : '追加'}`}
      footer={
        <Buttons>
          <Button onClick={handleClose} gray>
            キャンセル
          </Button>
          <Button onClick={handleSubmit}>{claimDetail ? '保存' : '追加'}</Button>
        </Buttons>
      }
      width={'550px'}
    >
      <FormContainer>
        <FormDiv $able={true}>
          <label>日付</label>
          <DatePicker
            selected={selectedDate}
            onChange={handleChangeSelectedDate}
            minDate={startOfMonth(selectedDate)}
            maxDate={endOfMonth(selectedDate)}
            dateFormat="yyyy年MM月dd日"
            disabledKeyboardNavigation
            placeholderText="日程を選択"
            className="input datepicker-date"
            readOnly={fieldStatuses.dateReadOnly}
          />
        </FormDiv>

        <FormDiv $able={true}>
          <label htmlFor="category">カテゴリー</label>
          <Select
            name="category"
            id="category"
            onChange={handleChangeCategory}
            value={fields.category}
            className={errors.category ? 'err' : ''}
          >
            <option value="カテゴリーを選択してください ▼">カテゴリーを選択してください ▼</option>
            {categories.map((category, i) => {
              return (
                <option value={category.id} key={i}>
                  {category.name}
                </option>
              );
            })}
          </Select>
          <ErrText>{errors.category}</ErrText>
        </FormDiv>

        <FormDiv $able={fieldStatuses.hasStudent}>
          <InputWithSearch
            label="受講生"
            handleInput={fetchCourses}
            shownId={true}
            onSelect={(id) => {
              if (!id) return;
              handleChangeCourse(id);
            }}
            defaultValue={
              claimDetail?.course
                ? {
                    id: claimDetail?.course?.id,
                    name: `${claimDetail?.course?.student?.user?.maskedPersonalInfo?.name}: ${claimDetail?.course?.plan?.name}`,
                  }
                : undefined
            }
            options={formatCourses(filterCourseByClaimCategory(courses, fields.category))}
          />
          <ErrText>{errors.student}</ErrText>
        </FormDiv>

        <FormDiv $able={fieldStatuses.hasLesson}>
          <label htmlFor="lesson">
            専属レッスン
            <Small>※受講履歴登録済みのレッスンのみ選択可能です。</Small>
          </label>
          <Select
            name="lesson"
            id="lesson"
            value={fields.lesson}
            className={errors.lesson ? 'err' : ''}
            onChange={handleChangeLesson}
          >
            <option value="専属レッスンを選択してください ▼">
              専属レッスンを選択してください ▼
            </option>
            {fields.course?.lessons
              ?.filter((lesson) => {
                const lessonDate = lesson.startAt
                  ? format(new Date(lesson.startAt), 'yyyy/MM')
                  : null;
                const currentMonth = lessonDate === format(selectedDate, 'yyyy/MM');
                const nextMonth = lessonDate === format(addMonths(selectedDate, 1), 'yyyy/MM');
                const prevMonth = lessonDate === format(addMonths(selectedDate, -1), 'yyyy/MM');
                switch (parseInt(fields.category)) {
                  case ClaimCategoryConst.LESSON:
                    return currentMonth && lesson.completed;
                  case ClaimCategoryConst.LESSON_PREPARATION:
                    return prevMonth || currentMonth || nextMonth;
                  case ClaimCategoryConst.LESSON_CANCEL:
                    return currentMonth && lesson.canceled;
                  default:
                    return currentMonth && lesson.completed;
                }
              })
              .map((lesson, i: number) => {
                return (
                  <option value={lesson.id} key={i}>
                    {lesson.title}({lesson.startAt ? formatDate(new Date(lesson.startAt)) : ''})
                  </option>
                );
              })}
          </Select>
          <ErrText>{errors.lesson}</ErrText>
        </FormDiv>
        <FormDiv $able={fieldStatuses.hasSpotLesson}>
          <label htmlFor="spotLesson">単発レッスン</label>
          <Select
            name="spotLesson"
            id="spotLesson"
            value={fields.spotLesson}
            className={errors.spotLesson ? 'err' : ''}
            onChange={handleChangeSpotLesson}
          >
            <option value="単発レッスンを選択してください ▼">
              単発レッスンを選択してください ▼
            </option>
            {(!isEmpty(spotLessons) ? spotLessons : [])
              .filter((lesson: SpotLesson) => {
                const lessonDate = format(new Date(lesson.startAt), 'yyyy/MM');
                const currentMonth = lessonDate === format(selectedDate, 'yyyy/MM');
                return currentMonth;
              })
              .map((lesson: SpotLesson, i: number) => {
                return (
                  <option value={lesson.id} key={i}>
                    ({formatDate(new Date(lesson.startAt))})
                  </option>
                );
              })}
          </Select>
          <ErrText>{errors.spotLesson}</ErrText>
        </FormDiv>

        <Col2>
          <Column $able={fieldStatuses.hasTime}>
            <label htmlFor="amount">時間（分）</label>
            <Input
              type="number"
              name="amount"
              id="amount"
              min="0"
              max="1440"
              value={fields.amount}
              onChange={handleChangeAmount}
              className={errors.amount ? 'err' : ''}
              disabled={fieldStatuses.timeReadOnly}
            ></Input>
            <ErrText>{errors.amount}</ErrText>
          </Column>

          <Column $able={fieldStatuses.hasHourlyPayment}>
            <label htmlFor="unit_price">
              単価（円/時）
              <Small>※税込</Small>
            </label>
            <Input
              type="text"
              pattern="\d*"
              name="unit_price"
              id="unit_price"
              maxLength={6}
              onChange={handleChangeUnitPrice}
              value={fields.unitPrice}
              className={errors.unitPrice ? 'err' : ''}
            ></Input>
            <ErrText>{errors.unitPrice}</ErrText>
          </Column>

          <Column $able={fieldStatuses.hasPerItem}>
            <label htmlFor="per_item_amount">件数</label>
            <Input
              type="number"
              name="per_item_amount"
              id="per_item_amount"
              min="0"
              max="10000"
              value={fields.perItemAmount}
              onChange={handleChangePerItemAmount}
              className={errors.perItemAmount ? 'err' : ''}
            ></Input>
            <ErrText>{errors.perItemAmount}</ErrText>
          </Column>

          <Column $able={fieldStatuses.hasPerItemPayment}>
            <label htmlFor="per_item_unit_price">
              単価（1件あたり）
              <Small>※税込</Small>
            </label>
            <Input
              type="text"
              pattern="\d*"
              name="per_item_unit_price"
              id="per_item_unit_price"
              maxLength={6}
              onChange={handleChangePerItemUnitPrice}
              value={fields.perItemUnitPrice}
              className={errors.perItemUnitPrice ? 'err' : ''}
            ></Input>
            <ErrText>{errors.perItemUnitPrice}</ErrText>
          </Column>

          <Column $able={fieldStatuses.hasAdditionalPayment}>
            <label htmlFor="additional_payment">
              対面費用（円）
              <Small>※対面レッスンの場合のみ計上ください</Small>
            </label>
            <Input
              type="text"
              pattern="\d*"
              name="additional_payment"
              id="additional_payment"
              maxLength={5}
              onChange={handleChangeAdditionalPayment}
              value={fields.additionalPayment}
              className={errors.additionalPayment ? 'err' : ''}
            ></Input>
            <ErrText>{errors.additionalPayment}</ErrText>
          </Column>

          <Column $able={fieldStatuses.hasTimelinePayment}>
            <label htmlFor="chara_num">文字数</label>
            <Input
              type="number"
              name="chara_num"
              id="chara_num"
              min="1"
              max="10000"
              value={fields.charaAmount}
              onChange={handleChangeCharaAmount}
              className={errors.charaAmount ? 'err' : ''}
            ></Input>
            <ErrText>{errors.charaAmount}</ErrText>
          </Column>

          <Column $able={fieldStatuses.hasTimelinePayment}>
            <label htmlFor="chara_unit_price">
              単価（1文字）
              <Small>※税込</Small>
            </label>
            <Input
              type="text"
              pattern="\d*"
              name="chara_unit_price"
              id="chara_unit_price"
              maxLength={6}
              onChange={handleChangeCharaUnitPrice}
              value={fields.charaUnitPrice}
              className={errors.charaUnitPrice ? 'err' : ''}
            ></Input>
            <ErrText>{errors.charaUnitPrice}</ErrText>
          </Column>
        </Col2>

        <FormDivNote width={width ? width : 100}>
          <label htmlFor="remark">
            備考
            <ErrText as="span">{fieldStatuses.remarkIsRequired ? '※必須' : ''}</ErrText>
          </label>
          <Textarea
            id="remark"
            name="remark"
            value={fields.remark}
            onChange={handleChangeRemark}
            placeholder={fieldStatuses.remarkPlaceholder}
            className={errors.remark ? 'err' : ''}
          ></Textarea>
          <ErrText>{errors.remark}</ErrText>
        </FormDivNote>
      </FormContainer>
    </Modal>
  );
};

// データ取得
const useFetcher = (yearMonth: Date) => {
  // カテゴリー取得
  const { data: claimCategoryData } = useGetClaimCategoriesForInstructorQuery();
  const categories = claimCategoryData?.claimCategoriesForInstructor ?? [];

  // スポットレッスン取得
  const { data: spotLessonData } = useGetSpotLessonsForInstructorQuery({
    variables: {
      input: {
        from: startOfMonth(yearMonth).toISOString(),
        to: startOfMonth(addMonths(yearMonth, 1)).toISOString(),
        phases: [SpotLessonPhase.Complete, SpotLessonPhase.NonAttendance, SpotLessonPhase.Cancel],
        withLastMinuteCanceled: true,
      },
    },
  });
  const spotLessons = spotLessonData?.spotLessonsForInstructor?.items ?? [];

  // コース取得
  const [getCourse, courseData] = useGetHistoricalCoursesForInstructorLazyQuery();
  const fetchCourses = useCallback(
    async (query: string) => {
      getCourse({
        variables: {
          userName: query,
        },
      });
    },
    [getCourse],
  );
  const courses = courseData?.data?.historicalCoursesForInstructor ?? [];

  return { categories, spotLessons, courses, fetchCourses };
};

// 入力値変更 / フォーム表示切り替え
const useFieldsHandler = (
  initialFields: Fields,
  initialSelectedDate: Date,
  initialFieldStatuses: FieldStatuses,
  spotLessons: SpotLesson[],
  courses: Course[],
) => {
  const [selectedDate, setSelectedDate] = useState<Date>(initialSelectedDate);
  const [fields, setFields] = useState<Fields>({ ...initialFields });
  const [fieldStatuses, setFieldStatuses] = useState<FieldStatuses>({
    ...initialFieldStatuses,
  });
  const [errors, setErrors] = useState<Errors>(defaultErrors);

  // フォームの値変更時のハンドラ
  const handleChangeCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const category = e.target.value;

    setFields({
      ...defaultFields,
      category: category,
      amount: parseInt(category) === ClaimCategoryConst.SPOT_LESSON ? '60' : '',
    });
    setErrors(defaultErrors);
    setFieldStatuses(changeFieldStatuses(category));
  };
  const handleChangeLesson: React.ChangeEventHandler<HTMLSelectElement> = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setFields((prev) => ({ ...prev, lesson: e.target.value }));
    if (!fields.course || !fields.category) return;

    const lesson = fields.course.lessons?.find((l) => l.id === parseInt(e.target.value, 10));
    if (!lesson || !lesson.startAt || !lesson.endAt) return;

    if (parseInt(fields.category) === ClaimCategoryConst.LESSON) {
      setFields((prev) => ({
        ...prev,
        amount: `${Math.floor(
          (new Date(lesson.endAt).getTime() - new Date(lesson.startAt).getTime()) / 1000 / 60,
        )}`,
      }));
      setSelectedDate(lesson ? new Date(lesson.startAt) : selectedDate);
      setFieldStatuses((prev) => ({ ...prev, dateReadOnly: true, timeReadOnly: true }));
    } else if (parseInt(fields.category) === ClaimCategoryConst.LESSON_CANCEL) {
      setFields((prev) => ({
        ...prev,
        amount: '60',
      }));
      setSelectedDate(lesson ? new Date(lesson.startAt) : selectedDate);
      setFieldStatuses((prev) => ({ ...prev, dateReadOnly: true, timeReadOnly: true }));
    }
  };
  const handleChangeSpotLesson: React.ChangeEventHandler<HTMLSelectElement> = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const spotLesson = spotLessons?.find(
      (spotLesson) => spotLesson.id === parseInt(e.target.value, 10),
    );
    if (!spotLesson || !spotLesson.startAt || !spotLesson.endAt) return;
    setFields((prev) => ({
      ...prev,
      spotLesson: e.target.value,
    }));
    setSelectedDate(spotLesson ? new Date(spotLesson.startAt) : selectedDate);

    setFieldStatuses((prev) => ({ ...prev, dateReadOnly: true }));
  };
  const handleChangeCourse = (id: number) => {
    const course = courses.find((c) => c.id === id);
    setFields((prev) => ({
      ...prev,
      course: course ? course : undefined,
      student: course?.id
        ? { id: course.id, name: course?.student?.user?.maskedPersonalInfo?.name ?? '' }
        : defaultFields.student,
    }));
    setFieldStatuses((prev) => ({ ...prev, dateReadOnly: false }));
  };
  const handleChangeAmount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFields((prev) => ({ ...prev, amount: numberFormat(e.target.value) }));
    },
    [setFields],
  );
  const handleChangeCharaAmount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFields((prev) => ({ ...prev, charaAmount: numberFormat(e.target.value) }));
    },
    [setFields],
  );
  const handleChangeUnitPrice = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFields((prev) => ({ ...prev, unitPrice: numberFormat(e.target.value) }));
    },
    [setFields],
  );
  const handleChangeCharaUnitPrice = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFields((prev) => ({ ...prev, charaUnitPrice: numberFormat(e.target.value) }));
    },
    [setFields],
  );
  const handleChangeAdditionalPayment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFields((prev) => ({ ...prev, additionalPayment: numberFormat(e.target.value) }));
    },
    [setFields],
  );
  const handleChangePerItemUnitPrice = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFields((prev) => ({ ...prev, perItemUnitPrice: numberFormat(e.target.value) }));
    },
    [setFields],
  );
  const handleChangePerItemAmount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFields((prev) => ({ ...prev, perItemAmount: numberFormat(e.target.value) }));
    },
    [setFields],
  );
  const handleChangeRemark = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setFields((prev) => ({ ...prev, remark: e.target.value }));
    },
    [setFields],
  );
  const handleChangeSelectedDate = useCallback(
    (d: Date) => {
      setSelectedDate(d);
    },
    [setSelectedDate],
  );

  // バリデーション
  const validateFields = useCallback(() => {
    const newErrors: Errors = { ...defaultErrors };

    const validate = (condition: boolean, fieldName: keyof Errors, message: string) => {
      if (condition) {
        newErrors[fieldName] = message;
      }
    };

    validate(
      fieldStatuses.hasTime && Number(fields.amount) < 1,
      'amount',
      '時間を1以上で入力してください',
    );
    validate(
      fieldStatuses.hasTime && Number(fields.amount) > 1440,
      'amount',
      '時間を1440以下で入力してください',
    );
    validate(
      parseInt(fields.category) === ClaimCategoryConst.CURRICULUM && Number(fields.amount) > 60,
      'amount',
      'カリキュラム作成は60分以内で入力してください',
    );
    validate(
      fieldStatuses.hasHourlyPayment && Number(removeCommaDelimiter(fields.unitPrice)) < 1,
      'unitPrice',
      '単価を1以上で入力してください',
    );
    validate(
      fieldStatuses.hasTimelinePayment && Number(removeCommaDelimiter(fields.charaAmount)) < 1,
      'charaAmount',
      '文字数を1以上で入力してください',
    );
    validate(
      fieldStatuses.hasTimelinePayment && Number(removeCommaDelimiter(fields.charaUnitPrice)) < 1,
      'charaUnitPrice',
      '単価（1文字）を1以上で入力してください',
    );
    validate(
      fields.additionalPayment !== '' && Number(removeCommaDelimiter(fields.additionalPayment)) < 0,
      'additionalPayment',
      '対面費用を0以上で入力してください',
    );
    validate(
      fieldStatuses.hasPerItemPayment && Number(removeCommaDelimiter(fields.perItemUnitPrice)) < 1,
      'perItemUnitPrice',
      '単価（1件あたり）を1以上で入力してください',
    );
    validate(
      fieldStatuses.hasPerItem && Number(removeCommaDelimiter(fields.perItemAmount)) < 1,
      'perItemAmount',
      '件数を1以上で入力してください',
    );
    validate(
      fields.category === defaultFields.category,
      'category',
      'カテゴリーを選択してください',
    );
    validate(
      fieldStatuses.hasLesson && fields.lesson === defaultFields.lesson,
      'lesson',
      '専属レッスンを選択してください',
    );
    validate(
      fieldStatuses.hasSpotLesson && fields.spotLesson === defaultFields.spotLesson,
      'spotLesson',
      '単発レッスンを選択してください',
    );
    validate(
      fieldStatuses.hasStudent && fields.student === defaultFields.student,
      'student',
      '受講生を選択してください',
    );
    validate(
      fields.remark === '' && fieldStatuses.remarkIsRequired,
      'remark',
      '備考を入力してください',
    );

    setErrors(() => newErrors);

    return !hasErrors(newErrors);
  }, [fields, fieldStatuses]);

  const resetFields = useCallback(() => {
    setFields(defaultFields);
    setFieldStatuses(defaultFieldStatuses);
    setErrors(defaultErrors);
  }, []);

  return {
    selectedDate,
    fields,
    fieldStatuses,
    errors,
    handleChangeCategory,
    handleChangeLesson,
    handleChangeSpotLesson,
    handleChangeCourse,
    handleChangeAmount,
    handleChangeCharaAmount,
    handleChangeUnitPrice,
    handleChangeCharaUnitPrice,
    handleChangeAdditionalPayment,
    handleChangePerItemUnitPrice,
    handleChangePerItemAmount,
    handleChangeRemark,
    handleChangeSelectedDate,
    resetFields,
    validateFields,
  };
};

// 送信関連処理
const useSubmit = (
  fields: Fields,
  selectedDate: Date,
  yearMonth: Date,
  validateFields: () => boolean,
  refetch: () => Promise<void>,
  onClose: () => void,
  resetFields: () => void,
  claimDetail?: ClaimDetail,
) => {
  const { showToast } = useToastsContext();
  const [addClaimDetail] = useAddClaimDetailForInstructorMutation();
  const [updateClaimDetail] = useUpdateClaimDetailForInstructorMutation();

  // 送信パラメーター設定
  const setClaimDetailInput = useCallback((): ClaimDetailInput => {
    return {
      claimCategoryID: fields.category !== defaultFields.category ? parseInt(fields.category) : 0,
      date: selectedDate.toISOString(),
      courseID: fields.student ? fields.student.id : null,
      lessonID: fields.lesson !== defaultFields.lesson ? parseInt(fields.lesson) : null,
      spotLessonID:
        fields.spotLesson !== defaultFields.spotLesson ? parseInt(fields.spotLesson) : null,
      unitPrice:
        parseInt(fields.category) === ClaimCategoryConst.POSTED_TIMELINE
          ? parseInt(removeCommaDelimiter(fields.charaUnitPrice))
          : parseInt(fields.category) === ClaimCategoryConst.UNIT_PRICE_SYSTEM_OF_PRACTICE_REVIEW
            ? parseInt(removeCommaDelimiter(fields.perItemUnitPrice))
            : parseInt(removeCommaDelimiter(fields.unitPrice)),
      amount:
        parseInt(fields.category) === ClaimCategoryConst.POSTED_TIMELINE
          ? parseInt(fields.charaAmount)
          : parseInt(fields.category) === ClaimCategoryConst.TEACHING_MATERIAL ||
              parseInt(fields.category) === ClaimCategoryConst.REFERRAL_INCENTIVE ||
              parseInt(fields.category) === ClaimCategoryConst.OTHER
            ? 1
            : parseInt(fields.category) === ClaimCategoryConst.UNIT_PRICE_SYSTEM_OF_PRACTICE_REVIEW
              ? parseInt(fields.perItemAmount)
              : parseInt(fields.amount),
      additionalPayment: parseInt(removeCommaDelimiter(fields.additionalPayment)),
      remark: fields.remark,
    };
  }, [fields, selectedDate]);

  // 送信処理
  const handleSubmit = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (!validateFields()) return;

      const input = setClaimDetailInput();
      try {
        if (claimDetail) {
          await updateClaimDetail({
            variables: {
              id: claimDetail.id,
              input: input,
            },
          });
        } else {
          await addClaimDetail({
            variables: {
              yearMonth: yearMonth.toISOString(),
              input: input,
            },
          });
        }
        await refetch();
      } catch (e) {
        showToast(1, getApiErrorMessage(e));
        return;
      }
      resetFields();
      onClose();
      showToast(0, '請求申請詳細が登録されました。');
    }, [
      validateFields,
      claimDetail,
      setClaimDetailInput,
      onClose,
      showToast,
      addClaimDetail,
      updateClaimDetail,
      refetch,
      yearMonth,
      resetFields,
    ]),
  );

  return { handleSubmit };
};

// 既存請求詳細から初期表示を設定
const createInitialFieldStates = (
  claimDetail: ClaimDetail | undefined,
  courses: Course[],
  initialDate: Date,
): { initialFields: Fields; initialSelectedDate: Date } => {
  if (!claimDetail) {
    // 追加時の請求月が今月なら今日の日付を選択する
    const now = new Date();
    const initialSelectedDate = initialDate.getMonth() === now.getMonth() ? now : initialDate;
    return {
      initialFields: defaultFields,
      initialSelectedDate: initialSelectedDate,
    };
  }

  const {
    date: claimDetailDate,
    claimCategory,
    course,
    lesson,
    spotLesson,
    amount: claimDetailAmount,
    unitPrice: claimDetailUnitPrice,
    additionalPayment: claimDetailAdditionalPayment,
    remark,
  } = claimDetail;

  const categoryId = claimCategory?.id;
  const courseId = course?.id;

  const student = `${course?.student?.user?.maskedPersonalInfo?.name}: ${course?.plan?.name}`;

  const isPostedTimeline = categoryId === ClaimCategoryConst.POSTED_TIMELINE;
  const isUnitPriceReview = categoryId === ClaimCategoryConst.UNIT_PRICE_SYSTEM_OF_PRACTICE_REVIEW;

  const amount = claimDetailAmount?.toString() ?? '';
  const unitPrice = insertCommaDelimiter(String(claimDetailUnitPrice));
  const additionalPayment = insertCommaDelimiter(String(claimDetailAdditionalPayment));

  return {
    initialFields: {
      category: categoryId?.toString() ?? defaultFields.category,
      course: courseId ? courses.find((c) => c.id === courseId) : defaultFields.course,
      student: courseId ? { id: courseId, name: student } : defaultFields.student,
      lesson: lesson?.id ? lesson?.id.toString() : defaultFields.lesson,
      spotLesson: spotLesson?.id ? spotLesson?.id.toString() : defaultFields.spotLesson,
      amount: !isPostedTimeline && !isUnitPriceReview ? amount : defaultFields.amount,
      unitPrice: !isPostedTimeline && !isUnitPriceReview ? unitPrice : defaultFields.unitPrice,
      charaUnitPrice: isPostedTimeline ? unitPrice : defaultFields.charaUnitPrice,
      charaAmount: isPostedTimeline && amount ? amount : defaultFields.charaAmount,
      perItemAmount: isUnitPriceReview ? amount : defaultFields.perItemAmount,
      perItemUnitPrice: isUnitPriceReview ? unitPrice : defaultFields.perItemUnitPrice,
      additionalPayment:
        !isPostedTimeline && !isUnitPriceReview
          ? additionalPayment
          : defaultFields.additionalPayment,
      remark: remark ?? defaultFields.remark,
    },
    initialSelectedDate: claimDetailDate ? new Date(claimDetailDate) : initialDate,
  };
};

// カテゴリーに応じてフィールドの表示状態を変更
const changeFieldStatuses = (category?: string): FieldStatuses => {
  if (!category) {
    return defaultFieldStatuses;
  }

  switch (parseInt(category)) {
    case ClaimCategoryConst.LESSON:
      return {
        ...defaultFieldStatuses,
        hasTime: true,
        hasStudent: true,
        hasLesson: true,
        hasAdditionalPayment: true,
        hasHourlyPayment: true,
        dateReadOnly: true,
        timeReadOnly: true,
      };
    case ClaimCategoryConst.LESSON_PREPARATION:
      return {
        ...defaultFieldStatuses,
        hasTime: true,
        hasStudent: true,
        hasLesson: true,
        hasHourlyPayment: true,
      };
    case ClaimCategoryConst.QUESTION:
      return {
        ...defaultFieldStatuses,
        hasTime: true,
        hasStudent: true,
        hasHourlyPayment: true,
        remarkIsRequired: true,
        remarkPlaceholder: '対応した質問の概要を記載ください。',
      };
    case ClaimCategoryConst.CURRICULUM:
      return {
        ...defaultFieldStatuses,
        hasTime: true,
        hasStudent: true,
        hasHourlyPayment: true,
      };
    case ClaimCategoryConst.LESSON_CANCEL:
      return {
        ...defaultFieldStatuses,
        hasTime: true,
        hasStudent: true,
        hasLesson: true,
        hasHourlyPayment: true,
        remarkIsRequired: true,
        remarkPlaceholder: '専属レッスンキャンセルの詳細を記載ください。',
        dateReadOnly: true,
        timeReadOnly: true,
      };
    case ClaimCategoryConst.CATCHUP:
      return {
        ...defaultFieldStatuses,
        hasTime: true,
        hasHourlyPayment: true,
        remarkIsRequired: true,
        remarkPlaceholder: 'キャッチアップした内容について記載ください。',
      };
    case ClaimCategoryConst.QUESTION_GENERAL:
      return {
        ...defaultFieldStatuses,
        hasTime: true,
        hasHourlyPayment: true,
        remarkIsRequired: true,
        remarkPlaceholder: '対応したURLの一覧を記載ください。',
      };
    case ClaimCategoryConst.TEACHING_MATERIAL:
      return {
        ...defaultFieldStatuses,
        hasStudent: true,
        hasHourlyPayment: true,
        remarkIsRequired: true,
        remarkPlaceholder: '購入した教材を記載ください。',
      };
    case ClaimCategoryConst.REFERRAL_INCENTIVE:
      return {
        ...defaultFieldStatuses,
        hasHourlyPayment: true,
        remarkIsRequired: true,
        remarkPlaceholder: '紹介いただいた方の名前を記載ください。',
      };
    case ClaimCategoryConst.POSTED_TIMELINE:
      return {
        ...defaultFieldStatuses,
        hasTimelinePayment: true,
        remarkIsRequired: true,
        remarkPlaceholder: 'タイムラインURLを貼り付けてください。',
        dateReadOnly: true,
      };
    case ClaimCategoryConst.SPOT_LESSON:
      return {
        ...defaultFieldStatuses,
        hasTime: true,
        hasSpotLesson: true,
        hasHourlyPayment: true,
        dateReadOnly: true,
      };
    case ClaimCategoryConst.TERAKOYA_PRACTICE_REVIEWER:
      return {
        ...defaultFieldStatuses,
        hasTime: true,
        hasHourlyPayment: true,
        remarkIsRequired: true,
        remarkPlaceholder: 'レビューした課題提出URLを記載ください。',
      };
    case ClaimCategoryConst.UNIT_PRICE_SYSTEM_OF_PRACTICE_REVIEW:
      return {
        ...defaultFieldStatuses,
        hasPerItemPayment: true,
        hasPerItem: true,
        remarkIsRequired: true,
        remarkPlaceholder: '対応した内容について記載ください。',
      };
    case ClaimCategoryConst.CONSULTING:
    case ClaimCategoryConst.MENTORING:
    case ClaimCategoryConst.REQUEST:
    case ClaimCategoryConst.TEACHING_MATERIAL_DEVELOPING:
    case ClaimCategoryConst.RESKILLING_COLLEGE:
    case ClaimCategoryConst.SHIGOTORU:
    case ClaimCategoryConst.REQUEST_BY_CORPORATE_DIVISION:
    case ClaimCategoryConst.DOJO_LESSON:
    case ClaimCategoryConst.DOJO_CONTENT_DEVELOPING:
    case ClaimCategoryConst.ON_SITE_LESSON:
    case ClaimCategoryConst.CAREER_CHANGE_COMMUNITY:
      return {
        ...defaultFieldStatuses,
        hasTime: true,
        hasHourlyPayment: true,
        remarkIsRequired: true,
        remarkPlaceholder: '対応した内容について記載ください。',
      };
    default:
      return {
        ...defaultFieldStatuses,
        hasHourlyPayment: true,
        remarkIsRequired: true,
        remarkPlaceholder: '対応した内容について記載ください。',
      };
  }
};

const zeroPadding = (num: number) => `0${num}`.substr(-2);

const formatDate = (date: Date) =>
  `${date.getFullYear()}/${zeroPadding(date.getMonth() + 1)}/${zeroPadding(
    date.getDate(),
  )} ${zeroPadding(date.getHours())}:${zeroPadding(date.getMinutes())}`;

const numberFormat = (s: string) => {
  return insertCommaDelimiter(s.replace(/[^0-9]+/g, ''));
};

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;

const FormContainer = styled.div`
  padding: 1.25rem;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
`;
const FormDiv = styled.div<{ $able: boolean }>`
  display: ${(props) => (props.$able ? 'block' : 'none')};
  margin-bottom: 1rem;

  .datepicker-date {
    border: 1px solid #ddd;
    padding: 0.6rem 1rem;
  }
`;
const Col2 = styled.div`
  margin-bottom: 1rem;
  display: flex;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
`;
const Column = styled.div<{ $able: boolean }>`
  display: ${(props) => (props.$able ? 'block' : 'none')};
  width: 50%;
`;

const FormDivNote = styled.div<{ width: number }>`
  margin-bottom: 1rem;
  ${(props) => {
    if (!props.width || props.width > 750) {
      return '';
    }
    return 'width: 250px;';
  }};
`;

const ErrText = styled.p`
  color: #e2001b;
  font-size: 0.8rem;
  margin-top: 0.4rem;
  white-space: pre-wrap;
`;
const Small = styled.span`
  font-size: 0.8rem;
  font-weight: normal;
  padding-left: 0.5rem;
`;
const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.6rem 1rem;
  font-size: 0.8rem;
  line-height: 1.8em;
  border-radius: 0px;
  border: solid 1px #ddd;
  background-repeat: no-repeat;
  background-color: #fff;

  &.err {
    border: solid 1px #e2001b;
  }
`;
const Textarea = styled.textarea`
  margin: 0;
  background: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 0.8rem;
  width: 95%;
  height: 8rem;
  padding: 0.6rem 1rem;
  border-radius: 0px;
  border: solid 1px #ddd;

  &.err {
    border: solid 1px #e2001b;
  }
`;
const Input = styled.input`
  margin: 0;
  background: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 0.8rem;
  line-height: 1.8em;
  border-radius: 0px;
  padding: 0.6rem 1rem;
  border: solid 1px #ddd;

  &.err {
    border: solid 1px #e2001b;
  }
`;
