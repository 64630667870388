import { useParams } from 'react-router-dom';

import { NotFoundPage } from '../public/NotFound';
import ForbiddenPage from '../public/Forbidden';
import { UserPageLayout } from '../../templates/UserPageLayout';
import { Loader } from '../../molecules/Loader';

import {
  PracticeSubmitSearchType,
  InstructorPracticeSubmitSearchType,
  PracticeSubmitSortKey,
  useGetPracticeSubmitsQuery,
  useGetPracticeSubmitsForInstructorQuery,
  useGetUserForUserPageLayoutQuery,
} from '../../../gen/graphql';
import { PageWrapper } from '../../atoms/PageWrapper';
import { PracticeSubmitsList } from '../../organisms/PracticeSubmitsList';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import { FIRST_PAGE, FETCH_LIMIT_LOW } from '../../../const/Page';
import { useURL } from '../../../common/customHooks/URL';
import { useUser } from '../../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { LOWER_META_TITLE } from '../../../const/Service';

export const UserPracticeSubmitsContainer = (): JSX.Element => {
  const paramID = useParams<{ id: string }>().id;
  const pathUserID = Number(paramID);
  const { user, permissionCheck } = useUser();
  if (Number.isNaN(pathUserID)) return NotFoundPage;
  if (
    pathUserID === user.lmsUser?.id &&
    permissionCheck(FunctionType.PracticeForInstructorAndCoach, PermissionType.Read)
  ) {
    return ForbiddenPage;
  }

  return <UserPracticeSubmits userID={pathUserID} />;
};

interface UserPracticeSubmitsProps {
  userID: number;
}

const UserPracticeSubmits = (props: UserPracticeSubmitsProps): JSX.Element => {
  const metaTitle = `提出物 | ${LOWER_META_TITLE}`;

  const { queries } = useURL();
  const page = queries?.page ? Number(queries.page) : FIRST_PAGE;

  const { data: userData, loading: userLoading } = useGetUserForUserPageLayoutQuery({
    variables: { id: props.userID },
  });

  const { user, permissionCheck } = useUser();
  const permissionPracticeForInstructorAndCoachRead = permissionCheck(
    FunctionType.PracticeForInstructorAndCoach,
    PermissionType.Read,
  );
  const myPracticeSubmitsQueryParam = {
    sortKey: PracticeSubmitSortKey.CreatedAt,
    page: page,
    limit: FETCH_LIMIT_LOW,
  };
  const otherPracticeSubmitsQueryParam = {
    userID: props.userID,
    ...myPracticeSubmitsQueryParam,
  };
  // インストラクター・コーチ以外
  const { data: practiceSubmitsData, loading: practiceSubmitsLoading } = useGetPracticeSubmitsQuery(
    {
      variables: {
        input:
          props.userID === user.lmsUser?.id
            ? {
                type: PracticeSubmitSearchType.Mine,
                ...myPracticeSubmitsQueryParam,
              }
            : {
                type: PracticeSubmitSearchType.All,
                ...otherPracticeSubmitsQueryParam,
              },
      },
      skip: permissionPracticeForInstructorAndCoachRead,
    },
  );
  // インストラクター・コーチ
  const { data: practiceSubmitsForInstructorData, loading: practiceSubmitsForInstructorLoading } =
    useGetPracticeSubmitsForInstructorQuery({
      variables: {
        input: {
          type: InstructorPracticeSubmitSearchType.All,
          ...otherPracticeSubmitsQueryParam,
        },
      },
      skip: !permissionPracticeForInstructorAndCoachRead,
    });
  const practiceSubmits =
    practiceSubmitsData?.getPracticeSubmits.items ??
    practiceSubmitsForInstructorData?.getPracticeSubmitsForInstructor.items ??
    [];
  const total =
    practiceSubmitsData?.getPracticeSubmits.total ??
    practiceSubmitsForInstructorData?.getPracticeSubmitsForInstructor.total ??
    0;

  // ユーザー情報取得前は画面レイアウトを表示できないためローディングだけを返す
  if (userLoading) {
    return <Loader display />;
  }

  if (!userData || !userData.user) {
    return NotFoundPage;
  }

  return (
    <UserPageLayout activeTab="practice_submits" user={userData.user} metaTitle={metaTitle}>
      <Loader display={practiceSubmitsLoading || practiceSubmitsForInstructorLoading} />
      <PageWrapper>
        {practiceSubmits.length !== 0 ? (
          <PracticeSubmitsList practiceSubmits={practiceSubmits} total={total} />
        ) : (
          <EmptyBlock title="提出された課題はありません" />
        )}
      </PageWrapper>
    </UserPageLayout>
  );
};
