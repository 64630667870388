import React, { ChangeEvent } from 'react';
import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query';

import { Modal } from '../molecules/Modal';
import { Input } from '../atoms/Input';
import { Button } from '../atoms/Button';

import { MAXIMUM_NUMBER_OF_USERS } from '../../const/team';
import { useGetTeamUpcomingInvoiceLazyQuery, PaymentMethodType } from '../../gen/graphql';
import { useToastsContext } from '../../context/ToastsProvider';

import { getApiErrorMessage } from '../../utils/graphqlError';
import { CANCELLATION_FAQ_LINK } from '../../const/Link';

interface Props {
  isOpen: boolean;
  teamID: string;
  currentPeopleNum: number;
  joinedTeamMemberNum: number;
  paymentMethodType?: PaymentMethodType;
  loading?: boolean;
  onClose: () => void;
  updateSubscription: (num: number) => void;
  cancelReserved: boolean;
}

export const TeamChangePeopleNumModal: React.FC<Props> = (props) => {
  const [peopleNum, setPeopleNum] = React.useState(props.currentPeopleNum);
  const [isError, setIsError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [clickable, setClickable] = React.useState(false);

  const [getTeamUpcomingInvoice, { data: invoiceData }] = useGetTeamUpcomingInvoiceLazyQuery();
  const priceSuffix = props.paymentMethodType === PaymentMethodType.CreditCard ? '/月' : '';

  const getPrice = useGetPrice(
    props.teamID,
    props.currentPeopleNum,
    props.joinedTeamMemberNum,
    props.paymentMethodType,
    props.cancelReserved,
    peopleNum,
    setIsError,
    setErrorText,
    setLoading,
    getTeamUpcomingInvoice,
    setClickable,
  );

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      loading={props.loading}
      width="694px"
      header={<Title>契約内容の変更</Title>}
      footer={
        <FooterWrapper>
          <Cancel onClick={props.onClose}>キャンセル</Cancel>
          <SubmitButton onClick={() => props.updateSubscription(peopleNum)} $clickable={clickable}>
            変更を確定する
          </SubmitButton>
        </FooterWrapper>
      }
    >
      <Container>
        <StepTitle>
          <span>STEP1.</span>
          <span>変更する人数を入力してください</span>
        </StepTitle>
        <Text>
          {MAXIMUM_NUMBER_OF_USERS}人を超える場合は
          <a href="https://lp.sejuku.net/terakoya/biz/#form_contact" target="_blank">
            こちら
          </a>
          からお問い合わせください。
        </Text>
        <InputRow>
          <InputWrapper>
            <StyledInput
              width="110px"
              type="number"
              name="people_number"
              max={MAXIMUM_NUMBER_OF_USERS}
              min={0}
              value={peopleNum}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setClickable(false);
                setPeopleNum(Number(e.target.value));
              }}
              error={isError}
            />
            <span>人</span>
          </InputWrapper>
          <StyledButton onClick={getPrice}>金額を確認</StyledButton>
          {isError && <ErrorText>{errorText}</ErrorText>}
        </InputRow>

        <StepTitle>
          <span>STEP2.</span>
          <span>変更後の次回の請求金額を確認する</span>
        </StepTitle>
        <Price>
          {loading && !invoiceData?.teamUpcomingInvoice ? (
            <span className="loading">
              <span>計</span>
              <span>算</span>
              <span>中</span>
              <span>...</span>
            </span>
          ) : invoiceData?.teamUpcomingInvoice?.isIncrementalUpdate ? (
            <>
              <PriceBlock>
                <PriceText>
                  <span>請求額: </span>¥
                  {invoiceData?.teamUpcomingInvoice?.defaultPrice.toLocaleString()}(税込)
                  {priceSuffix}
                  <br />
                  <span>
                    次回のみ: ¥
                    {invoiceData?.teamUpcomingInvoice?.nextInvoice?.total.toLocaleString()}(税込)
                  </span>
                </PriceText>
              </PriceBlock>
            </>
          ) : (
            <>
              ¥{invoiceData?.teamUpcomingInvoice?.nextInvoice?.total.toLocaleString()}
              <span>(税込){priceSuffix}</span>
            </>
          )}
        </Price>
        <Note>
          ※人数を増員する場合は、増員分について、本日0時を基点として秒割り計算分が次回請求に上乗せされています。
          <br />
          ※人数を減員する場合は、返金は行なっておりません。
          <br />
          ※「特定商取引法に基づく表記」は
          <a href="https://www.sejuku.net/corp/legal" target="_blank">
            こちら
          </a>
          をご覧ください。
          <br />※ 解約方法については
          <a href={CANCELLATION_FAQ_LINK} target="_blank">
            こちら
          </a>
          をご覧ください。
        </Note>
      </Container>
    </Modal>
  );
};

const useGetPrice = (
  teamID: string,
  currentPeopleNum: number,
  joinedTeamMemberNum: number,
  paymentMethodType: PaymentMethodType | undefined,
  cancelReserved: boolean,
  peopleNum: number,
  setIsError: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorText: React.Dispatch<React.SetStateAction<string>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  getTeamUpcomingInvoice: ReturnType<typeof useGetTeamUpcomingInvoiceLazyQuery>[0],
  setClickable: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { showToast } = useToastsContext();

  const fetchUpcomingInvoice = React.useCallback(
    async (num: number) => {
      try {
        setLoading(true);
        await getTeamUpcomingInvoice({
          variables: {
            id: teamID,
            input: {
              quantity: num,
            },
          },
        });
      } catch (e) {
        showToast(1, getApiErrorMessage(e));
        return;
      } finally {
        setLoading(false);
        setClickable(true);
      }
    },
    [setLoading, getTeamUpcomingInvoice, teamID, showToast, setClickable],
  );

  const getPrice = () => {
    if (cancelReserved) {
      setErrorText(
        '解約申請済みです。人数を変更するには、解約申請をキャンセルしてから再実行してください。',
      );
      setIsError(true);
      return false;
    }

    if (peopleNum > MAXIMUM_NUMBER_OF_USERS) {
      setErrorText(`${MAXIMUM_NUMBER_OF_USERS}人を超える場合はお問い合わせください。`);
      setIsError(true);
      return false;
    }
    if (peopleNum < joinedTeamMemberNum) {
      setErrorText(`チーム参加人数の${joinedTeamMemberNum}人以上指定してください。`);
      setIsError(true);
      return false;
    }
    if (PaymentMethodType.CreditCard !== paymentMethodType && peopleNum < currentPeopleNum) {
      setErrorText(`銀行振込の場合、利用人数を減らすことはできません。`);
      setIsError(true);
      return false;
    }

    setErrorText('');
    setIsError(false);
    fetchUpcomingInvoice(peopleNum);
  };

  return getPrice;
};

const Title = styled.h2`
  width: 100%;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 1rem;
  `}
`;
const Container = styled.div`
  max-width: 606px;
  margin: 0 auto;
  padding: 3rem 1.5rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 1.5rem 1rem 2rem;
  `}
`;
const StepTitle = styled.h3`
  display: flex;
  gap: 0.5em;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;

  span {
    font-size: 1em;
  }

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 0;
    font-size: 1rem;
    line-height: 1.5rem;
  `}
`;
const Text = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;

  a {
    color: #1a0dab;
    font-size: 1em;
    text-decoration: underline;
  }
`;

const InputRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0.75rem auto 2rem;

  ${media.lessThan('medium')`
    gap: .75rem;
    margin-top: 1rem;
  `}
`;
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;
const StyledInput = styled(Input)`
  height: 2.5rem;

  ${media.lessThan('medium')`
    width: 6.125rem;
  `}
`;
const StyledButton = styled(Button)`
  width: 6.125rem;
  padding: 0.5rem;
  background: #fff;
  border: 1px solid #eb0000;
  color: #eb0000;
  font-size: 0.875rem;
  line-height: 1.125rem;
  transition: all 0.2s;

  &:hover {
    background: #eb0000;
    color: #fff;
  }
`;
const ErrorText = styled.p`
  width: 100%;
  color: #eb0000;
  font-size: 0.875rem;
  font-weight: 700;
`;

const PriceBlock = styled.div`
  text-align: left;
`;

const PriceText = styled.p`
  display: inline-block;
  text-align: right;
  font-size: 1.2rem;
`;

const LoadingAnimation = () => {
  return keyframes`
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  `;
};
const Price = styled.p`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;

  span {
    font-size: 0.875rem;

    &.loading {
      span {
        display: inline-block;
        font-weight: 400;
        animation: ${LoadingAnimation} 1.6s infinite alternate;

        &:nth-of-type(2) {
          animation-delay: 0.1s;
        }
        &:nth-of-type(3) {
          animation-delay: 0.2s;
        }
        &:nth-of-type(4) {
          animation-delay: 0.3s;
        }
      }
    }
  }
`;
const Note = styled(Text)`
  margin-top: 2.5rem;

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;

  ${media.lessThan('medium')`
    gap: 1.5rem;
  `}
`;
const Cancel = styled.p`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  line-height: 1.25rem;
`;
const SubmitButton = styled(Button)<{ $clickable: boolean }>`
  padding: 0.5rem 2rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5rem;

  ${(props) =>
    !props.$clickable &&
    `
    background: #eee;
    pointer-events: none;
  `}

  ${media.lessThan('medium')`
    padding: .5rem 1.125rem;
    font-weight: 400;
  `}
`;
