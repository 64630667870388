import { ShouldForwardProp } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';

// styled-componentsでtransient propsにすべきものをそうしていないと、不要な属性がDOM要素に渡されてしまう。
// transient propsにしていない既存の実装が多いため、StyleSheetManagerで対応する。
// https://zenn.dev/sjgently/articles/ffbe8a303862ad#%E3%80%90%E8%A7%A3%E6%B1%BA%E7%AD%96%EF%BC%92%E3%80%91-stylesheetmanager%E3%81%AE%E8%A8%AD%E5%AE%9A%E3%81%A7warning%E5%9B%9E%E9%81%BF
export const shouldForwardProp: ShouldForwardProp<'web'> = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};
