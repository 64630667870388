import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface Props {
  id: number;
  name: string;
  image: string;
  active: boolean;
  switch: (id: number) => void;
}

export const LpIconLanguage: React.FC<Props> = (props) => {
  return (
    <Icon onClick={() => props.switch(props.id)}>
      <Thumb image={props.image} active={props.active} />
      <Language active={props.active}>{props.name}</Language>
    </Icon>
  );
};

const Thumb = styled.div<{ image: string; active: boolean }>`
  width: 4.1875rem;
  height: 4.1875rem;
  margin: 0 auto;
  background: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  border: ${(props) => (props.active ? '0.1875rem solid #e2001b' : '0 solid transparent')};
  border-radius: 50%;
  overflow: hidden;

  ${media.lessThan('large')`
    width: 3.5rem;
    height: 3.5rem;
  `}

  ${media.lessThan('medium')`
    width: 2.75rem;
    height: 2.75rem;
    box-sizing: border-box;
  `}
`;

const Language = styled.p<{ active: boolean }>`
  margin-top: 0.75rem;
  color: ${(props) => (props.active ? '#e2001b' : 'rgba(0,0,0,0.36)')};
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  text-align: center;
  white-space: pre-wrap;

  ${media.lessThan('large')`
    font-size: 0.75rem;
  `}
`;
const Icon = styled.div`
  border-radius: 50%;
  &:hover {
    ${Language} {
      color: #e2001b;
    }
  }
`;
