import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { InputWithSearch, Option } from '../molecules/InputWithSearch';
import { HorizontalInput } from '../molecules/HorizontalInput';
import { StudyReport } from '../../common/Const';
import {
  ProgramSearchType,
  ProgramType,
  useGetTagsLazyQuery,
  useSearchProgramsLazyQuery,
} from '../../gen/graphql';
import { FIRST_PAGE, FETCH_LIMIT_HIGH } from '../../const/Page';

export interface LearnedContentHandler {
  getStudyTime: () => string;
  getTag: () => Option | null;
  getProgram: () => Option | null;
  validate: () => boolean;
}

interface InputWithSearchHandler {
  getValue: () => Option | null;
  setValue: (option: Option) => void;
  clear: () => void;
}

interface LearnedContentProps {
  defaultValues?: {
    time: number;
    tag: Option;
    program: Option;
  };
  className?: string;
}

export const LearnedContent = forwardRef<LearnedContentHandler, LearnedContentProps>(
  (props, ref): JSX.Element => {
    const [studyTime, setStudyTime] = useState(
      props.defaultValues ? String(props.defaultValues?.time) : '',
    );
    const tagRef = useRef<InputWithSearchHandler>(null);
    const programRef = useRef<InputWithSearchHandler>(null);

    const [tagError, setTagError] = useState(false);
    const [studyTimeError, setStudyTimeError] = useState(false);

    const [getTags, tagData] = useGetTagsLazyQuery();
    const tagOptions =
      tagData.data?.getTags.items ?? (props.defaultValues ? [props.defaultValues.tag] : []);

    const [getPrograms, programData] = useSearchProgramsLazyQuery();
    const programOptions = programData.data?.programs.items
      ? programData.data.programs.items.map((program) => {
          return { name: program.title, id: program.id };
        })
      : props.defaultValues
        ? [props.defaultValues.program]
        : [];

    const handleInputStudyTime = (e: React.ChangeEvent<HTMLInputElement>): void => {
      setStudyTime(e.target.value);
    };

    const searchTags = (query: string) => {
      getTags({
        variables: {
          input: {
            name: query,
            page: 1,
            limit: 10,
          },
        },
      });
    };

    const searchPrograms = (query: string) => {
      getPrograms({
        variables: {
          input: {
            programSearchType: ProgramSearchType.All,
            type: ProgramType.Normal,
            word: query,
            page: FIRST_PAGE,
            limit: FETCH_LIMIT_HIGH,
          },
        },
      });
    };

    useImperativeHandle(ref, () => {
      return {
        getStudyTime: (): string => studyTime,
        getTag: (): Option | null => (tagRef.current ? tagRef.current.getValue() : null),
        getProgram: (): Option | null =>
          programRef.current ? programRef.current.getValue() : null,
        validate: (): boolean => {
          setStudyTimeError(!studyTime || parseInt(studyTime) <= 0);
          setTagError(!tagRef.current?.getValue());
          return Boolean(studyTime && parseInt(studyTime) > 0 && tagRef.current?.getValue());
        },
      };
    });

    return (
      <Container className={props.className}>
        <InputWithSearch
          label="言語・技術 ※"
          options={tagOptions}
          handleInput={searchTags}
          inputWidth="12rem"
          ref={tagRef}
          error={tagError}
          defaultValue={props.defaultValues?.tag}
        />
        <HorizontalInput
          label="学習時間 ※"
          unit="分"
          name="studyTime"
          inputWidth="8rem"
          value={studyTime}
          max={StudyReport.MAX_TIME}
          onChange={handleInputStudyTime}
          type="number"
          min={1}
          error={studyTimeError}
        />
        <InputWithSearch
          label="使用教材"
          options={programOptions}
          handleInput={searchPrograms}
          inputWidth="12rem"
          ref={programRef}
          defaultValue={props.defaultValues?.program}
          deletable
        />
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  > * {
    width: calc(50% - 0.5rem);
  }

  label {
    width: 6rem;
    min-width: 0;
    white-space: nowrap;
  }

  ${media.lessThan('large')`
    > * {
      width: 100%;
    }
  `}

  ${media.lessThan('medium')`
    label {
      width: 5rem;
      color: rgba(0,0,0,.6);
      font-size: .875rem;
    }
  `}
`;
