import { useEffect } from 'react';
import { GOOGLEAPIS_STATIC_URL, GOOGLEAPIS_URL, GOOGLE_FONTS_URL } from '../../const/GoogleFonts';

// NOTE: いずれのフォントもLPでのみ使用しているが、他でも必要な場合は引数でフォントを指定できるようにする
export const useGoogleFonts = () => {
  useEffect(() => {
    const googleApiLink = document.createElement('link');
    googleApiLink.href = GOOGLEAPIS_URL;
    googleApiLink.rel = 'preconnect';
    document.head.appendChild(googleApiLink);

    const googleStaticLink = document.createElement('link');
    googleStaticLink.href = GOOGLEAPIS_STATIC_URL;
    googleStaticLink.rel = 'preconnect';
    document.head.appendChild(googleStaticLink);

    const fontFamilyLink = document.createElement('link');
    fontFamilyLink.href = GOOGLE_FONTS_URL;
    fontFamilyLink.rel = 'stylesheet';
    document.head.appendChild(fontFamilyLink);

    return () => {
      document.head.removeChild(googleApiLink);
      document.head.removeChild(googleStaticLink);
      document.head.removeChild(fontFamilyLink);
    };
  }, []);
};
