import { none, Option, some } from 'fp-ts/Option';
import { isEmpty } from 'fp-ts/lib/Array';
import PassWordValidator from 'password-validator';
import validator from 'validator';
import { UserTagInput } from '../gen/graphql';
import tldList from 'tld-list';

export class NameError extends Error {}
export class NickNameError extends Error {}
export class EmailError extends Error {}
export class PasswordError extends Error {}
export class LessonError extends Error {}
export class QuestionAnswerContentError extends Error {}
export class QuestionCommentContentError extends Error {}
export class AccountImageError extends Error {}
export class AccountNickNameError extends Error {}
export class AccountUserTagsError extends Error {}
export class AccountTagsError extends Error {}
export class AccountProfileError extends Error {}
export class AccountObjectiveError extends Error {}

export const LessonMinutesValidation = (
  lessonStartAt: Date,
  lessonEndAt: Date,
  lessonMinutes: number,
): Option<LessonError> => {
  if (lessonEndAt.getTime() - lessonStartAt.getTime() > lessonMinutes * 60 * 1000) {
    return some(new LessonError(`該当コースのレッスンは${lessonMinutes / 60}時間以内です`));
  } else {
    return none;
  }
};

export const LessonDateTimeValidation = (
  lessonStartAt: Date,
  lessonEndAt: Date,
): Option<LessonError> => {
  if (lessonStartAt.getTime() >= lessonEndAt.getTime()) {
    return some(new LessonError('レッスン終了日時は、開始日時より後を指定してください'));
  } else {
    return none;
  }
};

export const UserNameValidation = (name: string | null): Option<NameError> => {
  // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
  // eslint-disable-next-line eqeqeq
  if (name == null || name.trim().length == 0) {
    return some(new NameError('必須入力項目です'));
  } else if (name.length > 100) {
    return some(new NameError('名前は100文字以下で入力してください'));
  } else {
    return none;
  }
};

export const UserNickNameValidation = (nickName: string | null): Option<NickNameError> => {
  // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
  // eslint-disable-next-line eqeqeq
  if (nickName == null || nickName.trim().length == 0) {
    return some(new NickNameError('必須入力項目です'));
  } else if (nickName.length > 100) {
    return some(new NickNameError('ニックネームは100文字以下で入力してください'));
  } else {
    return none;
  }
};

export const UserEmailValidation = (email: string): Option<EmailError> => {
  if (!email.length) {
    return some(new EmailError('必須入力項目です'));
  } else if (email.length > 256) {
    return some(new EmailError('メールアドレスは256文字以下で入力してください'));
  } else if (
    !validator.isEmail(email) ||
    !email.match(/^[\x20-\x7E]+$/) ||
    // TDLのチェックバリデーション
    // see: https://stackoverflow.com/questions/52701142/email-validation-with-validator-js-doesnt-work-properly
    !tldList.includes(email.split('.').pop())
  ) {
    return some(new EmailError('メールアドレスは正しい形式で入力してください'));
  } else {
    return none;
  }
};

export const UserPasswordValidation = (password: string): Option<PasswordError> => {
  const passwordSchema = new PassWordValidator();

  passwordSchema
    .is()
    .min(8)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits(1)
    .has()
    .not()
    .spaces();

  const validateResult = passwordSchema.validate(password, { details: true });
  const validateJudge = Array.isArray(validateResult) && validateResult.length;

  if (password.length === 0) {
    return some(new PasswordError('パスワードが入力されていません'));
  } else if (
    validateJudge &&
    !isEmpty(validateResult.filter((validate) => validate.validation === 'spaces'))
  ) {
    return some(new PasswordError('パスワードに空白が含まれています'));
  } else if (validateJudge) {
    return some(
      new PasswordError('パスワードは大文字,小文字,数字を組み合わせた8文字以上にしてください'),
    );
  } else {
    return none;
  }
};

export const SetRegisterUserValidationMessage = (
  e: Error | NameError | EmailError | PasswordError,
  setNameError: (value: ((prevState: string) => string) | string) => void,
  setEmailError: (value: ((prevState: string) => string) | string) => void,
  setPasswordError: (value: ((prevState: string) => string) | string) => void,
): void => {
  if (e instanceof NameError) {
    setNameError(e.message);
    return;
  } else if (e instanceof EmailError) {
    setEmailError(e.message);
    return;
  } else if (e instanceof PasswordError) {
    setPasswordError(e.message);
    return;
  } else {
    throw new Error('網羅外のエラー');
  }
};

const QUESTION_ANSWER_CONTENT_MAX_LENGTH = 15000;
export const validateQuestionAnswerContent = (
  content: string,
): QuestionAnswerContentError | null => {
  if (!content) {
    return new QuestionAnswerContentError('回答を入力してください');
  }
  if (content.length > QUESTION_ANSWER_CONTENT_MAX_LENGTH) {
    return new QuestionAnswerContentError(
      `回答は${QUESTION_ANSWER_CONTENT_MAX_LENGTH}文字以内で入力してください`,
    );
  }
  return null;
};

const QUESTION_COMMENT_CONTENT_MAX_LENGTH = 15000;
export const validateQuestionCommentContent = (
  content: string,
): QuestionCommentContentError | null => {
  if (!content) {
    return new QuestionCommentContentError('返信を入力してください');
  }
  if (content.length > QUESTION_COMMENT_CONTENT_MAX_LENGTH) {
    return new QuestionCommentContentError(
      `返信は${QUESTION_COMMENT_CONTENT_MAX_LENGTH}文字以内で入力してください`,
    );
  }
  return null;
};

export const validateAccountNickName = (
  nickName: string | undefined,
): Option<AccountNickNameError> => {
  // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
  // eslint-disable-next-line eqeqeq
  if (nickName == undefined || nickName.length === 0) {
    return some(new AccountNickNameError('必須入力項目です'));
  }

  if (nickName.length > 20) {
    return some(new AccountNickNameError('ニックネームは20文字以下で入力してください'));
  }

  return none;
};

export const validateAccountUserTags = (userTags: UserTagInput[]): Option<AccountUserTagsError> => {
  const errors = userTags.filter((t) => isNaN(t.tagID) || t.tagID <= 0 || t.term < 0); // t.tagIDがNaNだった場合に正しくチェックが行われないため
  if (errors.length >= 1) return some(new AccountUserTagsError('正しく入力してください'));
  return none;
};

export const validateAccountTags = (tags: number[]): Option<AccountTagsError> => {
  const errors = tags.filter((t) => t <= 0);
  if (errors.length >= 1) return some(new AccountTagsError('正しく入力してください'));
  return none;
};

export const validateAccountProfile = (
  profile: string | undefined,
): Option<AccountProfileError> => {
  if (!profile) return none;
  if (profile.length > 3000)
    return some(new AccountProfileError('自己紹介は3000文字以下で入力してください'));
  return none;
};

export const validateAccountObjective = (
  objective: string | undefined,
): Option<AccountObjectiveError> => {
  if (!objective) return none;
  if (objective.length > 3000)
    return some(new AccountObjectiveError('自己紹介は3000文字以下で入力してください'));
  return none;
};
