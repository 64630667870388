import React, { useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { TabNavigationLayout } from '../../templates/TabNavigationLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import { Loader } from '../../molecules/Loader';
import { ProgramSampleCard } from '../../organisms/ProgramSampleCard';
import { Breadcrumb } from '../../organisms/Breadcrumb';

import { useUser } from '../../../redux/user/useUser';
import { useGetProgramSamplesQuery } from '../../../gen/graphql';
import { FETCH_LIMIT_HIGH, FIRST_PAGE } from '../../../const/Page';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';
import { PROGRAM_COUNT } from '../../../const/Program';

export const ProgramSample: React.FC = () => {
  const metaTitle = `成果物サンプル一覧 | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}のプログラミング学習教材ページです。ITリテラシーからフロントエンド、バックエンド、インフラまで全${PROGRAM_COUNT}種類以上の教材をご用意。入門者や初心者にも優しい基礎教材はもちろん実務レベルのエンジニアになるための中級者向け教材も。`;

  const { permissionCheck } = useUser();
  const query = queryString.parse(useLocation().search);

  const tabs = useMemo(() => {
    const tabs = [];

    permissionCheck(FunctionType.ProgramForInstructorAndCoach, PermissionType.Read)
      ? tabs.push(
          { label: '学習用教材', to: '/programs?type=1', active: query.type === '1' },
          {
            label: 'インストラクター向けマニュアル',
            to: '/programs?type=2',
            active: query.type === '2',
          },
        )
      : tabs.push(
          { label: '教材TOP', to: '/programs', active: location.pathname === '/programs' },
          {
            label: '学習中の教材',
            to: '/programs_while_studying',
            active: location.pathname === '/programs_while_studying',
          },
          {
            label: '完了した教材',
            to: '/programs_completed_study',
            active: location.pathname === '/programs_completed_study',
          },
        );

    return tabs;
  }, [permissionCheck, query.type]);

  const breadcrumbs = [
    {
      label: 'ホーム',
      to: '/home',
    },
    {
      label: 'サンプル一覧',
    },
  ];

  const { data, error, loading } = useGetProgramSamplesQuery({
    variables: {
      input: {
        limit: FETCH_LIMIT_HIGH,
        page: FIRST_PAGE,
      },
    },
  });
  const samples = data?.getProgramSamples?.items ?? [];

  const showLoader = loading;

  return (
    <>
      <Loader display={showLoader} />
      <TabNavigationLayout
        pageTitle="教材"
        tabs={tabs}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
      >
        <PageWrapper>
          <Breadcrumb links={breadcrumbs} />
          <PageTitle>サンプル一覧</PageTitle>
          <PageDescription>
            侍テラコヤの教材を通して制作・開発できるサンプルサイトやサンプルアプリをまとめたページです。それぞれの教材の概要にも飛べます。
          </PageDescription>
          {error ? (
            <EmptyBlock title="サンプル一覧の読み込みが行われませんでした">
              回線状況を確認して再読み込みを行ってください
            </EmptyBlock>
          ) : !samples ? (
            <EmptyBlock title="現在サンプル一覧に表示させる情報がありません" />
          ) : (
            <SampleList>
              {samples.map((item) => (
                <ProgramSampleCard sample={item} />
              ))}
            </SampleList>
          )}
        </PageWrapper>
      </TabNavigationLayout>
    </>
  );
};

const PageTitle = styled.h2`
  margin-top: 2rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
`;
const PageDescription = styled.p`
  margin: 0.5rem auto 1.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;

  ${media.lessThan('medium')`
    margin-top: .75rem;
  `}
`;
const SampleList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 1.25rem;

  ${media.lessThan('medium')`
    gap: 2rem 1rem;
  `}
`;
