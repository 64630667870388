import { useEffect, useCallback } from 'react';

import { useGetTeamInvoicesLazyQuery } from '../../gen/graphql';

import { InvoiceContainer } from './InvoiceContainer';
import { useUser } from '../../redux/user/useUser';
import { FETCH_LIMIT_LOW } from '../../const/Page';

export const InvoiceWrapperTeam: React.FC = () => {
  const { loading, invoices, hasPrevious, hasNext, fetchNextInvoices, fetchPreviousInvoices } =
    useInvoices();

  return (
    <InvoiceContainer
      invoices={invoices}
      loading={loading}
      hasNext={hasNext}
      hasPrevious={hasPrevious}
      fetchNextInvoices={fetchNextInvoices}
      fetchPreviousInvoices={fetchPreviousInvoices}
    />
  );
};

const useInvoices = () => {
  const { user } = useUser();

  const [getTeamInvoices, { data, loading, refetch }] = useGetTeamInvoicesLazyQuery();

  const teamInvoices = data?.teamInvoices;

  useEffect(() => {
    if (!user?.teamID) {
      return;
    }
    getTeamInvoices({
      variables: {
        input: {
          teamID: user.teamID,
          limit: FETCH_LIMIT_LOW,
        },
      },
    });
  }, [getTeamInvoices, user.teamID]);

  const nextCursor = teamInvoices?.nextCursor ?? '';
  const previousCursor = teamInvoices?.previousCursor ?? '';

  const fetchNextInvoices = useCallback(async () => {
    refetch({
      input: {
        teamID: user?.teamID ?? '',
        nextCursor,
        limit: FETCH_LIMIT_LOW,
      },
    });
  }, [refetch, user.teamID, nextCursor]);

  const fetchPreviousInvoices = useCallback(async () => {
    refetch({
      input: {
        teamID: user?.teamID ?? '',
        previousCursor,
        limit: FETCH_LIMIT_LOW,
      },
    });
  }, [refetch, user.teamID, previousCursor]);

  return {
    loading: loading,
    invoices: teamInvoices?.items ?? [],
    hasPrevious: teamInvoices?.hasPrevious ?? false,
    hasNext: teamInvoices?.hasNext ?? false,
    fetchNextInvoices,
    fetchPreviousInvoices,
  };
};
