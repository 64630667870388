import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Box } from '../atoms/Box';
import { StudyLevelLabel } from '../atoms/StudyLevelLabel';

import TimeIcon from '../../static/image/icon_time.svg';

import { ExamScoreFragment, StudyLogUnderstandingLevel } from '../../gen/graphql';
import { getMedalImage } from '../../utils/ProgramExam';

interface Props {
  title: string;
  description?: string;
  requireTime?: number;
  examScore?: ExamScoreFragment;
  studyLog?: StudyLogUnderstandingLevel;
  className?: string;
}

export const ChapterTitle: React.FC<Props> = (props) => {
  return (
    <Box padding={[12, 8]} spPadding={[8, 4, 6]} className={props.className}>
      {Boolean(props.title) && <Title>{props.title}</Title>}
      {Boolean(props.description) && <Description>{props.description}</Description>}
      <Info>
        {Boolean(props.requireTime) && (
          <Time>
            <img src={TimeIcon} />
            <p>
              {props.requireTime}
              <span>分</span>
            </p>
          </Time>
        )}
        <Score>
          <img src={getMedalImage(props.examScore?.medalType)} alt="メダル" />
          <p className="score">{props.examScore ? `${props.examScore.score}点` : '-'}</p>
        </Score>
        <StudyLevelLabel studyLevel={props.studyLog} />
      </Info>
    </Box>
  );
};

const Title = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.4;

  ${media.lessThan('medium')`
    font-size: 1.5rem;
    line-height: 2.125rem;
  `}
`;
const Description = styled.p`
  margin-top: 0.5rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  line-height: 1.5em;
`;
const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.75rem;
`;
const InfoInner = styled.div`
  display: flex;
  align-items: center;
  gap: 0.125rem;
`;
const Time = styled(InfoInner)`
  img {
    display: block;
    width: 1rem;
    height: auto;
  }

  p {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1em;

    span {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1em;
    }
  }

  ${media.lessThan('medium')`
    img {
      width: .875rem;
    }

    p {
      font-size: .875rem;
    }
  `}
`;
const Score = styled(InfoInner)`
  img {
    display: block;
    width: 1.25rem;
    height: auto;
  }

  p {
    min-width: 1rem;
    font-size: 1rem;
    line-height: 1.125rem;
    text-align: center;
  }

  ${media.lessThan('medium')`
    img {
      width: auto;
      height: 1.125rem;
    }

    p {
      min-width: .875rem;
      font-size: .875rem;
    }
  `}
`;
