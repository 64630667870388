import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ContextProvider } from './context/provider';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducer from './redux/reducer';
import { Router } from './Router';
import routerWindow from './routerWindow';
import styled from 'styled-components';
import Logo from './static/image/logo.svg';
import media from 'styled-media-query';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { fromNullable, isSome } from 'fp-ts/Option';
import ResetCSS from './components/atoms/ResetCSS';
import { Loader } from './components/molecules/Loader';
import { Maintenance } from './components/pages/public/Maintenance';
import { JSONLD } from './common/JSONLD';
import { ABTestRedirector } from './ABTestRedirector';

import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from './common/shouldForwardProp';

import { RestApolloClientProvider } from './context/RestApolloClientProvider';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

TagManager.initialize({
  gtmId: 'GTM-TF29MQ3',
  dataLayerName: 'LMSDataLayer',
});

Auth.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  },
  oauth: {
    domain: process.env.REACT_APP_COGNITO_CLIENT_DOMAIN,
    redirectSignIn: process.env.REACT_APP_DOMAIN + '/login/oauth/callback',
    redirectSignOut: process.env.REACT_APP_DOMAIN + '/login',
    responseType: 'code',
  },
});

interface ErrorBoundaryState {
  hasError: boolean;
}

const MaintenanceComponent = (props: {
  hasMaintenance: boolean;
  children:
    | React.ReactNode
    | React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>>
    | string
    | number
    | Record<string, unknown>
    | React.ReactNodeArray
    | React.ReactPortal
    | null
    | undefined
    | boolean;
}) => <>{props.hasMaintenance ? <Maintenance /> : props.children}</>;

export const MaintenanceBoundary: React.FC = (props): JSX.Element => {
  const [hasMaintenance, setHasMaintenance] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  // TODO: AB#15046 本番障害のため、一時的にコメントアウト
  // const fetchRemoteConfig = async () => {
  //   setLoading(true);

  //   const remoteConfig = getRemoteConfig();

  //   //https://firebase.google.com/docs/remote-config/use-config-web?hl=ja#throttling
  //   //https://qiita.com/iwsksky/items/9590558559648d06e964
  //   //TODO 1ユーザあたり1時間にリモート取得できる最大回数5回(つまり12分に1回)が旧SDKで、新SDKは明示はされていない。
  //   // On/Offがすぐさまできれば良いので、一旦0に設定。大量のユーザで1ユーザあたり3-4回以上短時間に繰り返すと機能しなくなるので注意
  //   // 以前のSKDでは開発用は0、本番は12分という設定をすることが多かった。
  //   // 上記の制限に引っかかった場合は、前回取得した時の値を使うという動作をする。
  //   remoteConfig.settings.minimumFetchIntervalMillis = 0;
  //   try {
  //     await fetchAndActivate(remoteConfig);
  //     const maintenanceFlag = getValue(remoteConfig, 'maintenance').asBoolean();
  //     setHasMaintenance(maintenanceFlag);
  //   } catch (e) {
  //     // Sentryには送らない？
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect((): void => {
    // TODO: AB#15046 本番障害のため、一時的にコメントアウト
    // if (checkEnableFirebaseService()) {
    //   fetchRemoteConfig();
    // } else {
    //   //firebaseを使わない場合はメンテモードのために出していたローディングを明示的にOFFする必要がある。
    //   setLoading(false);
    // }
    setLoading(false);
    setHasMaintenance(false);
  }, []);

  return (
    <React.Fragment>
      <Loader display={loading} />
      {/*メンテモードの結果によって、APIアクセスさせたくないため*/}
      {!loading && (
        <MaintenanceComponent hasMaintenance={hasMaintenance} children={props.children} />
      )}
    </React.Fragment>
  );
};

class ErrorBoundary extends React.Component<{ children: React.ReactNode }, ErrorBoundaryState> {
  public constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  public render() {
    if (this.state.hasError) {
      return (
        <React.Fragment>
          <Header>
            <img src={Logo} alt="Samurai" />
          </Header>

          <Main>
            <Content>
              <Text>Something went wrong.</Text>

              <p>
                何らかのエラーが発生いたしました。
                <br />
                時間を空けて再度お試しください。
              </p>
              <p>
                <A href="/">TOPへ移動する</A>
              </p>
            </Content>
          </Main>
        </React.Fragment>
      );
    }
    return this.props.children;
  }
}

type Revision = {
  revision: {
    value: string;
  };
};

// リビジョンアップ用のコンポーネント
const Reloader: React.FC = (): JSX.Element => {
  // /revision.jsonへのクエリを生成
  const query = gql`
    query findRevision {
      revision @rest(type: "Revision", path: "/revision.json") {
        value
      }
    }
  `;

  const { data, previousData } = useQuery<Revision>(query, {
    pollInterval: 60000, // 1分ごとにポーリング
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!data || !previousData) {
      return;
    }

    // 現在のバージョンを確認し、バージョンが異なった場合はリロード
    if (data.revision.value !== previousData.revision.value) {
      window.location.reload();
    }
  }, [data, previousData]);

  return <></>;
};

const App: React.FC = () => {
  const store = createStore(reducer);

  const createAxiosInterceptor = React.useCallback(() => {
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const err = fromNullable(error.response);

        // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
        // eslint-disable-next-line eqeqeq
        if ((isSome(err) && err.value.status != 401) || error.config.isRetry) {
          throw error;
        }

        const currentSession = await Auth.currentSession();

        const jwtToken = currentSession.getIdToken().getJwtToken();
        error.config.headers.Authorization = `Bearer ${jwtToken}`;
        axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;

        error.config.isRetry = true;
        const response = await axios.request(error.config).catch((retryErr) => {
          throw new Error(retryErr.message);
        });

        return response;
      },
    );
  }, []);

  useEffect(() => {
    createAxiosInterceptor();
  }, [createAxiosInterceptor]);

  return (
    <MaintenanceBoundary>
      <ErrorBoundary>
        <Provider store={store}>
          <CookiesProvider>
            <BrowserRouter basename="" window={routerWindow}>
              <ContextProvider>
                <ResetCSS />
                <JSONLD />
                <RestApolloClientProvider>
                  <Reloader />
                </RestApolloClientProvider>
                <ABTestRedirector />
                <StyleSheetManager enableVendorPrefixes shouldForwardProp={shouldForwardProp}>
                  <Router></Router>
                </StyleSheetManager>
              </ContextProvider>
            </BrowserRouter>
          </CookiesProvider>
        </Provider>
      </ErrorBoundary>
    </MaintenanceBoundary>
  );
};

export default App;

const Header = styled.header`
  display: flex;
  width: 100%;
  height: 67px;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 24px;
  box-sizing: border-box;
`;

const Main = styled.main`
  box-sizing: border-box;
  padding: 32px 180px;

  ${media.lessThan('medium')`
    padding: 32px;
  `}
  h2 {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
  }
`;

const Content = styled.section`
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;

  p {
    margin-top: 1rem;
    line-height: 1.3;
  }
`;

const Text = styled.h3`
  display: block;
  font-size: 8rem;
  font-weight: bold;
  color: #e2001b;
  text-align: center;
`;

const A = styled.a`
  color: #e2001b;
`;
