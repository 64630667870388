import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Ttl } from '../../atoms/LpTtl';
import { LpSlider } from '../../molecules/LpSlider';
import { ProgramSlide } from '../../molecules/LpProgramSlide';

import SliderImg001 from '../../../static/image/lp/program_slide001.png';
import SliderImg002 from '../../../static/image/lp/program_slide002.png';
import SliderImg003 from '../../../static/image/lp/program_slide003.png';
import SliderImg004 from '../../../static/image/lp/program_slide004.png';
import SliderImg005 from '../../../static/image/lp/program_slide005.png';

import { PROGRAM_COUNT } from '../../../const/Program';

export const LpProgram = (): JSX.Element => {
  return (
    <Container data-gtm="program">
      <StyledTtl>
        <h2>作りながら学べる教材がなんと{PROGRAM_COUNT}種類以上</h2>
      </StyledTtl>
      <Description>
        プログラミングやWebサイト制作は習うより慣れることが重要。
        <br />
        実際に手を動かしながら学ぶことで最短最速でエンジニアを目指せます。
      </Description>
      <LpSlider>
        <ProgramSlide
          image={SliderImg001}
          title={'Amazon風のECサイト制作'}
          comment={
            'Amazonを模倣したECサイトをフルスクラッチで作成します。ECサイトには、ウェブアプリ開発の基礎から応用まで学べる機能が詰まっているのでスキルを伸ばすには非常に優れた題材です。'
          }
          tags={['PHP', 'Laravel']}
        />
        <ProgramSlide
          image={SliderImg002}
          title={'FigmaでWebデザイン制作'}
          comment={
            '化粧品のLP制作を通じて、Figmaの基本操作から応用まで実践的なスキルを学ぶことが出来ます。初心者でも安心して段階的に習得でき、プロ級の仕上がりを目指します。'
          }
          tags={['Figma']}
        />
        <ProgramSlide
          image={SliderImg003}
          title={'美容室のホームページ作成'}
          comment={
            'WordPressは世界で最も利用されているブログやHPを作成できるCMSです。副業の獲得にも繋がりやすいスキルのため、ライトにプログラミングを学びたい方におすすめです。'
          }
          tags={['WordPress']}
        />
        <ProgramSlide
          image={SliderImg004}
          title={'民宿アプリ制作'}
          comment={
            'Webフレームワークを活用して、実際の民宿予約アプリを開発しながら、Webアプリ開発の基礎から応用まで実践的に学べる教材です。'
          }
          tags={['Java', 'SpringBoot']}
        />
        <ProgramSlide
          image={SliderImg005}
          title={'AI技術で作る画像判定アプリ'}
          comment={
            'アップロードされた画像を自動で判別するAI技術を活用したWebアプリを制作します。教材を通じて、AIの基本原理から実践的な応用まで体系的に学ぶことができます。'
          }
          tags={['AI', 'Python']}
        />
      </LpSlider>
    </Container>
  );
};

const Container = styled.section`
  margin: 8.125rem auto 4.875rem;

  ${media.lessThan('medium')`
    margin: 4rem auto;
  `}
`;
const StyledTtl = styled(Ttl)`
  margin-bottom: 3rem;

  ${media.lessThan('medium')`
    padding: 0 1rem;
    margin-bottom: 1.5rem;

    h2 {
      line-height: 1.5;
    }
  `}
`;
const Description = styled.p`
  margin-bottom: 3rem;
  font-size: 1.25rem;
  line-height: 1.8;
  text-align: center;

  ${media.lessThan('medium')`
    padding: 0 1rem;
    font-size: 1rem;
  `}
`;
