import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Inner } from '../../atoms/LpInner';
import { Ttl } from '../../atoms/LpTtl';

import Feature01 from '../../../static/image/lp/feature_01.svg';
import Feature02 from '../../../static/image/lp/feature_02.svg';
import Feature03 from '../../../static/image/lp/feature_03.svg';
import Feature04 from '../../../static/image/lp/feature_04.svg';

import { PROGRAM_COUNT } from '../../../const/Program';

// TODO: AB#15326 SubscriptionFeatureと内容がほぼ同一なので統合
export const LpFeature: React.FC = () => {
  return (
    <Feature id="feature" data-e2e="feature">
      <Inner>
        <Ttl>
          <h2>サービスの特徴</h2>
          <p>Features</p>
        </Ttl>
        <TitleDescription>
          プログラミングのスキルの習得には、
          <br />
          <span>「挫折しない環境」</span>が必要です
        </TitleDescription>
        <List>
          <Item>
            <Card>
              <CardText>
                <Num>01</Num>
                <CardTitle>
                  現役エンジニアとのオンラインレッスン月1回以上可能<span>※</span>
                </CardTitle>
                <CardSup>※ レッスン回数はプランにより異なります</CardSup>
              </CardText>
              <img src={Feature01} alt="月1回、現役エンジニアとのオンラインレッスン" />
            </Card>
            <Description>
              現役エンジニアとのマンツーマンレッスンであなたの疑問やつまづきを解消できます。言語化できない悩みもご相談ください。
            </Description>
          </Item>
          <Item>
            <Card>
              <CardText>
                <Num>02</Num>
                <CardTitle>
                  回答率100％のQ&A掲示板に質問し放題<span>※</span>
                </CardTitle>
                <CardSup>※ 当社対応言語に限る</CardSup>
              </CardText>
              <img src={Feature02} alt="回答率100%のQ&A掲示板に質問し放題" />
            </Card>
            <Description>
              技術的な悩みやプログラミング学習の悩み、キャリアや転職に関する悩みも相談可能。現役エンジニアがあなたをサポートします。
            </Description>
          </Item>
          <Item>
            <Card>
              <CardText>
                <Num>03</Num>
                <CardTitle>{PROGRAM_COUNT}種類以上の教材で学習し放題</CardTitle>
              </CardText>
              <img src={Feature03} alt={`${PROGRAM_COUNT}種類以上の教材で学習し放題`} />
            </Card>
            <Description>
              プログラミング学習の前提知識からWebサイト制作・Webアプリ開発まで実践的な教材で着実にスキルアップできます。
            </Description>
          </Item>
          <Item>
            <Card>
              <CardText>
                <Num>04</Num>
                <CardTitle>
                  実践的なスキルを身につける課題機能<span>※</span>
                </CardTitle>
                <CardSup>※プランによって制限あり</CardSup>
              </CardText>
              <img src={Feature04} alt="実践的なスキルを身につける課題機能" />
            </Card>
            <Description>
              教材で身につけたスキルを「わかる」から「できる」に変えるための発展教材です。レビューをもらうことでスキルをブラッシュアップできます。
            </Description>
          </Item>
        </List>
      </Inner>
    </Feature>
  );
};

const Feature = styled.section`
  padding: 4rem 0 6.25rem;
  scroll-margin-top: 49px;

  ${media.lessThan('medium')`
    padding: 4rem 0;
    scroll-margin-top: 57px;
  `}
`;
const TitleDescription = styled.p`
  margin: 3rem auto 3rem;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;

  span {
    color: #e2001b;
    font-size: 1.5rem;
    font-weight: 700;
  }

  br {
    display: none;
  }

  ${media.lessThan('medium')`
    margin: 2rem auto 3rem;
    font-size: 1rem;

    span {
      font-size: 1rem;
    }

    br {
      display: block;
    }
  `}
`;

const List = styled.div`
  display: flex;
  gap: 3rem 2rem;
  flex-wrap: wrap;
  margin-top: 4rem;

  ${media.lessThan('medium')`
    margin-top: 3rem;
    gap: 2rem 0;
  `}
`;
const Card = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2.5rem 3rem 3rem;
  border-radius: 1.25rem;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);

  img {
    display: block;
    width: 100%;
    margin: 2.25rem auto 0;
  }

  ${media.lessThan('medium')`
    padding: 2rem 2rem 2.5rem 2.625rem;
    border-radius: 1rem;

    img {
      margin-top: 1.25rem;
    }
  `}
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 1rem);

  &:nth-of-type(1) ${Card} {
    background: #f5f0ed;

    img {
      max-width: 263px;
    }
  }

  &:nth-of-type(2) ${Card} {
    background: #eaf2f4;

    img {
      max-width: 240px;
    }
  }

  &:nth-of-type(3) ${Card} {
    background: #edf3fd;

    img {
      max-width: 220px;
    }
  }

  &:nth-of-type(4) ${Card} {
    background: #faf3eb;

    img {
      max-width: 260px;
    }
  }

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
  `}
`;
const CardText = styled.div`
  flex: 1;
`;
const Num = styled.p`
  color: #e2001b;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 2rem;
    height: 0.125rem;
    margin: auto;
    background: #eb0000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -3rem;
  }

  ${media.lessThan('medium')`
    font-size: 2rem;

    &:before {
      width: 1.75rem;
      left: -2.625rem;
    }
  `}
`;
const CardTitle = styled.h3`
  margin-top: 1.125rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 2.625rem;

  span {
    margin-left: 0.25rem;
    color: #7c7c7c;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1;
    vertical-align: text-top;
  }

  ${media.lessThan('medium')`
    margin-top: 1rem;
    font-size: 1.375rem;
    line-height: 2.25rem;
  `}
`;
const CardSup = styled.p`
  color: #7c7c7c;
  font-size: 0.75rem;
  line-height: 1.125rem;
`;

const Description = styled.p`
  max-width: 386px;
  margin: 2rem auto 0;
  font-size: 1rem;
  line-height: 1.625rem;

  ${media.lessThan('medium')`
    margin-top: 1.5rem;
    padding: 0 .875rem;
    font-size: .875rem;
  `}
`;
