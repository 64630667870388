import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { PageWrapper } from '../../atoms/PageWrapper';
import { Button } from '../../atoms/Button';
import { TeamExcludeModal } from '../../organisms/TeamExcludeModal';
import { TeamInviteModal } from '../../organisms/TeamInviteModal';
import {
  TeamMemberRole,
  useChangeTeamMemberRoleMutation,
  useCreateTeamInvitationMutation,
  useGetTeamMembersByTeamIdQuery,
  useRemoveTeamMemberMutation,
} from '../../../gen/graphql';
import { getApiErrorMessage } from '../../../utils/graphqlError';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { useToastsContext } from '../../../context/ToastsProvider';
import { useUser } from '../../../redux/user/useUser';
import { TeamMemberAuthList } from '../../organisms/TeamMemberAuthList';
import { FIRST_PAGE, FETCH_LIMIT_LOW } from '../../../const/Page';
import { TOAST_TYPE_ERROR } from '../../../context/ToastsProvider';
import { Loader } from '../../molecules/Loader';
import { MAXIMUM_NUMBER_OF_USERS } from '../../../const/team';
import { TeamTabLayout } from '../../templates/TeamTabLayout';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { LOWER_META_TITLE } from '../../../const/Service';
import { useURL } from '../../../common/customHooks/URL';

export const TeamAuth: React.FC = () => {
  const metaTitle = `招待・権限 | ${LOWER_META_TITLE}`;

  const { user, permissionCheck } = useUser();
  const { setParams, queries } = useURL();

  const page = useMemo(() => (queries?.page ? Number(queries.page) : FIRST_PAGE), [queries?.page]);

  const setPage = useCallback(
    (value: number) => {
      setParams([{ name: 'page', value: value.toString() }]);
    },
    [setParams],
  );

  const [isOpenInviteModal, setIsOpenInviteModal] = React.useState(false);
  const [isOpenExcludeModal, setIsOpenExcludeModal] = React.useState(false);
  const [excludeTeamMemberID, setExcludeTeamMemberID] = React.useState('');

  const { showToast } = useToastsContext();

  const { data, loading, refetch } = useGetTeamMembersByTeamIdQuery({
    variables: {
      input: {
        teamID: user.teamID ?? '',
        page: page,
        limit: FETCH_LIMIT_LOW,
      },
    },
    skip: !user.teamID,
    notifyOnNetworkStatusChange: true,
  });
  const teamMembers = data?.getTeamMembersByTeamID.items ?? [];
  const total = data?.getTeamMembersByTeamID.total ?? 0;
  const fetchTeamMembers = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const [createTeamInvitationMutation] = useCreateTeamInvitationMutation();
  const createTeamInvitation = useSafeAsyncCallback(
    useCallback(
      async (emails: string[]): Promise<void> => {
        if (!user.teamID) return;

        try {
          await createTeamInvitationMutation({
            variables: {
              input: {
                teamID: user.teamID,
                emails,
              },
            },
          });
        } catch (e) {
          showToast(TOAST_TYPE_ERROR, getApiErrorMessage(e));
          return;
        }
        showToast(0, '招待メールを送信しました。');
      },
      [user.teamID, createTeamInvitationMutation, showToast],
    ),
  );

  const [changeTeamMemberRoleMutation] = useChangeTeamMemberRoleMutation();
  const changeTeamMemberRole = useSafeAsyncCallback(
    useCallback(
      async (teamMemberID: string, role: TeamMemberRole): Promise<void> => {
        try {
          await changeTeamMemberRoleMutation({
            variables: {
              teamMemberID,
              role,
            },
          });
          await fetchTeamMembers();
        } catch (e) {
          showToast(TOAST_TYPE_ERROR, '権限の変更に失敗しました。');
          return;
        }
        showToast(0, '権限を変更しました。');
      },
      [changeTeamMemberRoleMutation, fetchTeamMembers, showToast],
    ),
  );

  const [removeTeamMemberMutation] = useRemoveTeamMemberMutation();
  const removeTeamMember = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      try {
        await removeTeamMemberMutation({
          variables: {
            id: excludeTeamMemberID,
          },
        });
        await fetchTeamMembers();
      } catch (e) {
        showToast(TOAST_TYPE_ERROR, 'メンバーを外せませんでした。');
        return;
      }
      showToast(0, 'メンバーを外しました。');
    }, [removeTeamMemberMutation, excludeTeamMemberID, fetchTeamMembers, showToast]),
  );

  const isLimit = MAXIMUM_NUMBER_OF_USERS <= total;

  const openInviteModal = () => {
    setIsOpenInviteModal(true);
  };

  // 新規メンバー追加モーダル作成時にこちらを使用
  const closeInviteModal = () => {
    setIsOpenInviteModal(false);
  };

  const openExcludeModal = (teamMemberID: string) => {
    // excludeTeamMemberID === team_member.idのユーザーをメンバーから外す
    setExcludeTeamMemberID(teamMemberID);
    setIsOpenExcludeModal(true);
  };

  const closeExcludeModal = () => {
    setIsOpenExcludeModal(false);
  };

  const changeRole = (teamMemberID: string, role: TeamMemberRole) => {
    changeTeamMemberRole(teamMemberID, role);
  };

  // メンバー一覧のメンバーを外すボタンでセットされたチームメンバーID（excludeTeamMemberID）を使用して
  // モーダルでメンバーを外すをクリックした際に動く除外処理
  const excludeTeamMember = () => {
    // メンバー除外処理
    removeTeamMember();

    setIsOpenExcludeModal(false);
  };

  return (
    <>
      <Loader display={loading} />
      <TeamTabLayout
        activeTab="auth"
        isAdmin={permissionCheck(FunctionType.TeamProfile, PermissionType.Read)}
        metaTitle={metaTitle}
      >
        <PageWrapper>
          <Container>
            <InviteArea>
              <p>
                現在の利用人数は{total}人です。{isLimit && '人数上限に達しています。'}
              </p>
              {!isLimit && permissionCheck(FunctionType.TeamInvitation, PermissionType.Create) && (
                <InviteButton onClick={openInviteModal}>新規メンバーを招待</InviteButton>
              )}
            </InviteArea>
            {user.lmsUser && (
              <TeamMemberAuthList
                user={user.lmsUser}
                teamMembers={teamMembers}
                total={total}
                page={page}
                setPage={setPage}
                permissionCheck={permissionCheck}
                changeRole={changeRole}
                openExcludeModal={openExcludeModal}
              />
            )}
          </Container>
        </PageWrapper>
      </TeamTabLayout>
      <TeamInviteModal
        isOpen={isOpenInviteModal}
        onClose={closeInviteModal}
        onSubmit={createTeamInvitation}
      />
      <TeamExcludeModal
        isOpen={isOpenExcludeModal}
        onClose={closeExcludeModal}
        excludeTeamMember={excludeTeamMember}
      />
    </>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 1006px;
  margin: 0 auto;
  padding: 2rem 2.5rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 0;
    border: none;
  `}
`;
const InviteArea = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;

  p {
    flex: 1;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    line-height: 1.375rem;
  }

  ${media.lessThan('medium')`
    margin-bottom: 1.5rem;
  `}

  ${media.lessThan('small')`
    flex-direction: column;
    gap: .75rem;
  `}
`;
const InviteButton = styled(Button)`
  display: block;
  width: 10rem;
  padding: 0.5rem;
  background: #fff;
  border: 1px solid #eb0000;
  color: #eb0000;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    background: #eb0000;
    color: #fff;
  }

  ${media.lessThan('small')`
    width: 100%;
    padding: .75rem;
  `}
`;
