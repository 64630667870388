import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { UserPageLayout } from '../../templates/UserPageLayout';
import { NotFoundPage } from '../public/NotFound';
import {
  QuestionSearchType,
  QuestionSortKey,
  useGetQuestionsQuery,
  useGetUserForUserPageLayoutQuery,
} from '../../../gen/graphql';
import { PageWrapper } from '../../atoms/PageWrapper';
import QuestionCard from '../../organisms/QuestionCard';
import Pagination from '../../organisms/Pagination';
import { Select } from '../../atoms/Select';
import { Loader } from '../../molecules/Loader';
import { SortKeyOptions } from '../../../const/Question';
import { FIRST_PAGE, FETCH_LIMIT_MIDDLE } from '../../../const/Page';
import { useURL } from '../../../common/customHooks/URL';
import { LOWER_META_TITLE } from '../../../const/Service';

export const UserQuestionsContainer = (): JSX.Element => {
  const paramID = useParams<{ id: string }>().id;
  const pathUserID = Number(paramID);
  if (Number.isNaN(pathUserID)) return NotFoundPage;

  return <UserQuestions userID={pathUserID} />;
};

interface UserQuestionsProps {
  userID: number;
}

const UserQuestions = (props: UserQuestionsProps): JSX.Element => {
  const metaTitle = `Q&A | ${LOWER_META_TITLE}`;

  const { setParams, queries } = useURL();

  const page = useMemo(() => (queries?.page ? Number(queries.page) : FIRST_PAGE), [queries?.page]);
  const sortKey = useMemo(
    () => queries?.sort_key ?? QuestionSortKey.CreatedAt,
    [queries?.sort_key],
  );

  const setPage = useCallback(
    (value: number) => {
      setParams([{ name: 'page', value: value.toString() }]);
    },
    [setParams],
  );
  const setSortKey = useCallback(
    (value: string) => {
      // 検索条件変更時はpageをリセット
      setParams([{ name: 'sort_key', value: value }, { name: 'page' }]);
    },
    [setParams],
  );

  const { data: userData, loading: userLoading } = useGetUserForUserPageLayoutQuery({
    variables: { id: props.userID },
  });

  const { data, loading, error, refetch } = useGetQuestionsQuery({
    variables: {
      input: {
        type: QuestionSearchType.All,
        targetUserID: props.userID,
        sortKey:
          Object.values(QuestionSortKey).find((value) => value === sortKey) ??
          QuestionSortKey.CreatedAt,
        limit: FETCH_LIMIT_MIDDLE,
        page: page,
      },
    },
  });

  const [refetching, setRefetching] = useState(false);

  const refetchQuestions = useCallback(async (): Promise<void> => {
    setRefetching(true);
    try {
      await refetch();
    } catch (e) {
      // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているのでここでは握りつぶす
    } finally {
      setRefetching(false);
    }
  }, [refetch]);

  // ユーザー情報取得前は画面レイアウトを表示できないためローディングだけを返す
  if (userLoading) {
    return <Loader display />;
  }

  // インストラクターはQ&Aページなし
  if (!userData || !userData.user || userData.user.isInstructor) {
    return NotFoundPage;
  }

  return (
    <UserPageLayout activeTab="questions" user={userData.user} metaTitle={metaTitle}>
      <Loader display={loading || refetching} />
      <PageWrapper>
        <SortFormContainer>
          <span className="label">並び替え：</span>
          <Select
            name="sortKey"
            value={sortKey}
            options={[...SortKeyOptions]}
            onChange={({ target: { value } }) => setSortKey(value)}
          />
        </SortFormContainer>
        {error ? (
          <Err>
            <h4>Q&Aの読み込みが行われませんでした</h4>
            <p>回線状況を確認して再読み込みを行ってください</p>
            <p className="reload" onClick={refetchQuestions}>
              再読み込み
            </p>
          </Err>
        ) : (
          <>
            {data?.getQuestions.items.length === 0 && (
              <Err>
                <h4>質問がまだありません</h4>
              </Err>
            )}
            {data?.getQuestions.items.map((question) => (
              <QuestionCard key={question.id} question={question} />
            ))}
            <Pagination
              total={data?.getQuestions.total ?? 0}
              perPage={FETCH_LIMIT_MIDDLE}
              page={page}
              setPage={setPage}
            />
          </>
        )}
      </PageWrapper>
    </UserPageLayout>
  );
};

const SortFormContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.75rem;

  .label {
    margin-right: 0.625rem;
    font-size: 0.75rem;
    line-height: 1.33;
  }

  select {
    font-size: 0.8125rem;
  }

  ${media.lessThan('medium')`
    margin-bottom: 0.625rem;
    padding: 0 1rem;
  `}
`;

const Err = styled.div`
  padding: 3rem 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  h4 {
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
  }

  p {
    margin-top: 6px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
  }

  .reload {
    background-color: #eb0000;
    display: flex;
    width: 160px;
    height: 43px;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    cursor: pointer;
    color: #ffffff;
    font-size: 0.9rem;
    font-weight: bold;
    margin: 0 auto;
    margin-top: 21px;
  }

  ${media.lessThan('medium')`
    width: calc(100% - 2rem);
    margin: 0 auto 2rem;
  `}
`;
