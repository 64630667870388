import React, { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';

import { isNonEmpty } from 'fp-ts/es6/Array';
import { format } from 'date-fns';

import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';

import { Button } from '../atoms/Button';
import { Radio } from '../atoms/Radio';
import { CheckBox } from '../atoms/CheckBox';
import { TextArea, TextAreaHandler } from '../atoms/TextArea';

import { Modal } from '../molecules/Modal';

import { useToastsContext } from '../../context/ToastsProvider';

import { strToEnum } from '../../utils/common';

// セッション受講履歴関連の定数
import {
  DefaultContent,
  HasAnnouncementDetailfaultContent,
  SessionPahse,
  Preparations,
  Schedules,
  HasAnnouncements,
  ResponseDeadline,
  Announcements,
} from '../../const/Session';
import { defaultErrorMessage } from '../../const/ErrorMessage';

import {
  SessionPhase as Phase,
  IsOnScheduleType,
  ResponseDeadlineType,
  SessionReportPreparationType,
  SessionReportAnnouncementType,
  HasAnnouncementsType,
  useGetSessionAndSessionReportForInstructorQuery,
  useCreateSessionReportMutation,
  useUpdateSessionReportMutation,
  SessionReportInput,
  SessionReportForInstructorFragment as SessionReport,
} from '../../gen/graphql';

interface Props {
  isOpen: boolean;
  sessionID: string;
  loading?: boolean;
  onRefetchSession: () => Promise<void>;
  onClose: () => void;
}

interface Errors {
  phase?: string;
  content?: string;
  preparations?: string;
  preparationOthers?: string;
  schedule?: string;
  delayReason?: string;
  hasAnnouncements?: string;
  announcements?: string;
  hasAnnouncementDetail?: string;
  responseDeadline?: string;
}

// セッション前準備項目のデータ生成用の型
interface StoredSessionReportPreparationType {
  type: SessionReportPreparationType;
}

// 運営への連携項目のデータ生成用の型
interface StoredSessionReportAnnouncementType {
  type: SessionReportAnnouncementType;
}

export const SessionHistoryModal: React.FC<Props> = ({
  isOpen,
  sessionID,
  onRefetchSession,
  onClose,
  ...props
}): JSX.Element => {
  // セッション&セッション受講履歴の取得
  const { data: currentSession } = useGetSessionAndSessionReportForInstructorQuery({
    variables: {
      sessionID: sessionID,
    },
    skip: !sessionID, // モーダルを開いていない場合は空文字が渡ってくるのでその場合は実行しない
    onCompleted: (currentSession) => {
      // 基本的にはありえないが、optionalになっているのでチェック
      if (!currentSession) {
        return;
      }

      // セッション受講履歴がある場合はデフォルト値にセット
      if (currentSession.session && currentSession?.sessionReportBySessionIDForInstructor) {
        setSessionReportValues(
          currentSession.session.phase,
          currentSession.sessionReportBySessionIDForInstructor,
        );
      } else {
        // ない場合はデフォルト値を使う
        clearDefaultValues();
      }
    },
  });

  const [addSessionReport] = useCreateSessionReportMutation();
  const [updateSessionReport] = useUpdateSessionReportMutation();

  // セッション受講履歴のバリデーションエラー
  const [errors, setErrors] = useState<Errors>({});

  // 各フォームの入力内容(ラジオ)
  const [phase, setPhase] = useState<string>(Phase.Completed);
  const [preparations, setPreparations] = useState<string[]>([]);
  const [schedule, setSchedule] = useState<string>(IsOnScheduleType.OnSchedule);
  const [hasAnnouncements, setHasAnnouncements] = useState<string>(HasAnnouncementsType.No);
  const [announcements, setAnnouncements] = useState<string[]>([]);
  const [responseDeadline, setResponseDeadline] = useState<string>('');

  // 各フォームの入力内容(自由記入)
  const sessionContentRef = useRef<TextAreaHandler>(null);
  const sessionPrepareRef = useRef<TextAreaHandler>(null);
  const sessionDelayReasonRef = useRef<TextAreaHandler>(null);
  const sessionAnnouncementRef = useRef<TextAreaHandler>(null);
  const sessionMemoRef = useRef<TextAreaHandler>(null);

  // 各フォームの非表示(キャンセル時)
  const isCanceled = phase === Phase.CanceledByStudent;

  // トースト
  const { showToast } = useToastsContext();

  // テキスト入力系のデフォルト値のセット
  const setDefaultContentValues = useCallback(() => {
    sessionContentRef.current?.setValue(DefaultContent);
    sessionPrepareRef.current?.setValue('');
    sessionDelayReasonRef.current?.setValue('');
    sessionAnnouncementRef.current?.setValue(HasAnnouncementDetailfaultContent);
    sessionMemoRef.current?.setValue('');
  }, []);

  // デフォルト値のクリア
  const clearDefaultValues = useCallback(() => {
    // テキスト入力系の項目をクリア
    setDefaultContentValues();

    // チェックボックス系の項目をクリア
    setPhase(Phase.Completed);
    setPreparations([]);
    setSchedule(IsOnScheduleType.OnSchedule);
    setHasAnnouncements(HasAnnouncementsType.No);
    setAnnouncements([]);
    setResponseDeadline('');
  }, [setDefaultContentValues]);

  // セッション受講履歴が登録済みのデフォルト値のセット
  const setSessionReportValues = useCallback(
    (sessionPhase: Phase, sessionReport: SessionReport) => {
      // テキスト入力系の値セット
      sessionContentRef.current?.setValue(sessionReport.content);
      sessionPrepareRef.current?.setValue(sessionReport.preparationOthers);
      sessionDelayReasonRef.current?.setValue(sessionReport.delayReason);
      sessionAnnouncementRef.current?.setValue(sessionReport.hasAnnouncementsDetail);
      sessionMemoRef.current?.setValue(sessionReport.coachingMemo);

      // 選択入力系の値セット
      setPhase(sessionPhase);
      setSchedule(sessionReport.isOnSchedule);
      setResponseDeadline(sessionReport.responseDeadline);

      // 事前準備や運営への連携事項など
      const sessionReportPreparations = sessionReport?.preparations ?? [];
      const defaultPreparations = sessionReportPreparations
        .map((preparation) => {
          return String(preparation?.type);
        })
        .filter((v) => v);
      setPreparations(defaultPreparations);

      const announcements = sessionReport?.announcements ?? [];
      const defaultAnnouncements = announcements
        .map((announcement) => {
          return String(announcement?.type);
        })
        .filter((v) => v);
      setAnnouncements(defaultAnnouncements);

      // 運営への連携事項がある場合はチェックが入る
      if (defaultAnnouncements.length > 0) {
        setHasAnnouncements(HasAnnouncementsType.Yes);
      }
    },
    [],
  );

  // セッション前準備や運営への連携項目のデータをセット
  const setCheckboxDataWithPreparations = (values: string[]) => {
    return values
      .map((v) => {
        return { type: strToEnum(v, SessionReportPreparationType) };
      })
      .filter((v): v is StoredSessionReportPreparationType => Boolean(v.type));
  };

  // セッション前準備項目にその他がある場合にデータをセット
  const setPreparationOther = (preparationOthers: string, values: string[]) => {
    if (values.includes(SessionReportPreparationType.OtherTask)) {
      return preparationOthers;
    }
    return '';
  };

  // 学習スケジュールが「遅れている」or「少し遅れている」場合にデータをセット
  const setDelayReason = (delayReason: string, schedule: string) => {
    if (schedule === IsOnScheduleType.TooLate || schedule === IsOnScheduleType.BitLate) {
      return delayReason;
    }
    return '';
  };

  // 運営への連携項目のデータをセット
  const setCheckboxDataWithAnnouncements = (values: string[]) => {
    return values
      .map((v) => {
        return { type: strToEnum(v, SessionReportAnnouncementType) };
      })
      .filter((v): v is StoredSessionReportAnnouncementType => Boolean(v.type));
  };

  // 運営への連携項目の詳細をセット
  const setHasAnnouncementsDetail = (value: string, announcementCount: number) => {
    if (announcementCount > 0) {
      return value;
    }
    return '';
  };

  // セッション受講履歴用のデータをセット
  const setSessionReportInput = useCallback(
    (sessionID: string) => {
      // 各テキスト入力項目をセット
      const storedContent = sessionContentRef.current?.getValue() ?? '';
      const storedPreparationsOther = setPreparationOther(
        sessionPrepareRef.current?.getValue() ?? '',
        preparations,
      );
      const storedDelayReason = setDelayReason(
        sessionDelayReasonRef.current?.getValue() ?? '',
        schedule,
      );
      const storedHasAnnouncementsDetail = setHasAnnouncementsDetail(
        sessionAnnouncementRef.current?.getValue() ?? '',
        announcements.length,
      );
      const storedCoachingMemo = sessionMemoRef.current?.getValue() ?? '';

      // 学習スケジュールの進捗をセット
      const storedSchedule = strToEnum(schedule, IsOnScheduleType);

      // セッションのフェーズをセット
      const storedSessionPhase = strToEnum(phase, Phase);

      const input: SessionReportInput = {
        sessionID: sessionID,
        phase: storedSessionPhase ?? Phase.NotCompleted,
        content: storedContent,
        preparations: setCheckboxDataWithPreparations(preparations),
        preparationOthers: storedPreparationsOther,
        isOnSchedule: storedSchedule ?? IsOnScheduleType.OnSchedule,
        delayReason: storedDelayReason,
        announcements: setCheckboxDataWithAnnouncements(announcements),
        hasAnnouncementsDetail: storedHasAnnouncementsDetail,
        coachingMemo: storedCoachingMemo,
      };

      // 受講生都合のキャンセルの時は空文字を設定
      if (phase === Phase.CanceledByStudent) {
        input.content = '';
        input.preparations = [];
        input.preparationOthers = '';
        input.isOnSchedule = IsOnScheduleType.OnSchedule;
        input.delayReason = '';
        input.announcements = [];
        input.hasAnnouncementsDetail = '';
      }

      // 運営への連携事項の対応期限をセット
      if (responseDeadline !== '') {
        input.responseDeadline = strToEnum(responseDeadline, ResponseDeadlineType);
      }
      return input;
    },
    [phase, preparations, schedule, announcements, responseDeadline],
  );

  // セッション受講履歴登録の登録処理
  const submitCreateSessionReport = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      // 基本的にはありえないが、optionalになっているのでチェック
      if (!currentSession || !currentSession.session) {
        return;
      }

      const sessionID = currentSession.session.id;

      const input = setSessionReportInput(sessionID);

      try {
        await addSessionReport({
          variables: {
            id: sessionID,
            input: input,
          },
        });
        await onRefetchSession();
      } catch {
        showToast(1, defaultErrorMessage);
        return;
      }
      showToast(0, 'セッションの受講履歴が登録されました。');
      onClose();
    }, [
      currentSession,
      addSessionReport,
      setSessionReportInput,
      showToast,
      onRefetchSession,
      onClose,
    ]),
  );

  // セッション受講履歴登録の更新処理
  const submitUpdateSessionReport = useSafeAsyncCallback(
    useCallback(
      async (sessionReportID: string): Promise<void> => {
        // 基本的にはありえないが、optionalになっているのでチェック
        if (!currentSession || !currentSession.session) {
          return;
        }

        const sessionID = currentSession.session.id;

        const input = setSessionReportInput(sessionID);

        try {
          await updateSessionReport({
            variables: {
              id: sessionReportID,
              input: input,
            },
          });
          await onRefetchSession();
        } catch {
          showToast(1, defaultErrorMessage);
          return;
        }
        showToast(0, 'セッションの受講履歴が更新されました。');
        onClose();
      },
      [
        currentSession,
        updateSessionReport,
        setSessionReportInput,
        showToast,
        onRefetchSession,
        onClose,
      ],
    ),
  );

  // セッションのフェーズのバリデーション
  const validatePhase = (value: string): string | undefined => {
    if (value === Phase.Completed || value === Phase.CanceledByStudent) {
      return undefined;
    }
    // 基本的に出てこないエラーだが念のため追加
    return 'このチェック項目は必須項目です';
  };

  // セッション内容や運営への連携事項詳細のバリデーション
  const validateContent = useCallback(
    (value: string, errMsg: string, defaultContent: string): string | undefined => {
      if (phase === Phase.CanceledByStudent) {
        return undefined;
      } else if (value === '') {
        return errMsg;
      } else if (value === defaultContent) {
        return '内容がデフォルトのまま変更されていません';
      }
      return undefined;
    },
    [phase],
  );

  // 前準備や運営への連携事項項目のバリデーション
  const validateCheckboxData = useCallback(
    (values: string[], errMsg: string): string | undefined => {
      if (phase === Phase.CanceledByStudent) {
        return undefined;
      } else if (values.length === 0) {
        return errMsg;
      }
      return undefined;
    },
    [phase],
  );

  // 学習スケジュールのバリデーション
  const validateSchedule = (value: IsOnScheduleType): string | undefined => {
    const scheduleTypes = Schedules.map((v) => {
      return v.value;
    });

    if (scheduleTypes.includes(value)) {
      return undefined;
    }
    // 基本的に出てこないエラーだが念のため追加
    return 'このチェック項目は必須項目です';
  };

  // 運営への連携事項のチェックのバリデーション
  const validateHasAnnouncements = (value: string): string | undefined => {
    if (value === HasAnnouncementsType.Yes || value === HasAnnouncementsType.No) {
      return undefined;
    }
    // 基本的に出てこないエラーだが念のため追加
    return 'このチェック項目は必須項目です';
  };

  // 運営への連携の対応期限のバリデーション
  const validateResponseDeadline = (value: string): string | undefined => {
    if (value === '') {
      return '対応期限は必須項目です';
    }
    return undefined;
  };

  // 全項目バリデーション
  const validateAll = useCallback((): Errors => {
    const errors: Errors = {
      phase: validatePhase(phase),
      content: validateContent(
        sessionContentRef.current?.getValue() ?? '',
        'セッション内容は必須です',
        DefaultContent,
      ),
      preparations: validateCheckboxData(preparations, 'セッション前準備は必須です'),
      schedule: validateSchedule(
        strToEnum(schedule, IsOnScheduleType) ?? IsOnScheduleType.OnSchedule,
      ),
      hasAnnouncements: validateHasAnnouncements(hasAnnouncements),
    };
    if (preparations.includes(SessionReportPreparationType.OtherTask)) {
      errors.preparationOthers = validateContent(
        sessionPrepareRef.current?.getValue() ?? '',
        'その他がチェックされている場合は詳細は必須項目です',
        '',
      );
    }
    if (schedule === IsOnScheduleType.TooLate || schedule === IsOnScheduleType.BitLate) {
      errors.delayReason = validateContent(
        sessionDelayReasonRef.current?.getValue() ?? '',
        '学習スケジュールが遅れている場合は詳細は必須項目です',
        '',
      );
    }
    if (hasAnnouncements === HasAnnouncementsType.Yes) {
      errors.announcements = validateCheckboxData(announcements, '連携事項は必須項目です');
      errors.hasAnnouncementDetail = validateContent(
        sessionAnnouncementRef.current?.getValue() ?? '',
        '連携事項の詳細は必須項目です',
        HasAnnouncementDetailfaultContent,
      );
      errors.responseDeadline = validateResponseDeadline(responseDeadline);
    }
    return errors;
  }, [
    phase,
    sessionContentRef,
    preparations,
    schedule,
    hasAnnouncements,
    announcements,
    sessionAnnouncementRef,
    responseDeadline,
    validateCheckboxData,
    validateContent,
  ]);

  // セッション受講履歴登録(または更新)
  const submit = () => {
    // 基本的にはありえないが、optionalになっているのでチェック
    if (!currentSession || !currentSession.session) {
      return;
    }

    // バリデーションチェック
    const errors = validateAll();
    setErrors(errors);
    if (isNonEmpty(Object.values(errors).filter((error) => Boolean(error)))) {
      return;
    }

    // 既に既存の受講履歴がない場合は登録し、ある場合は更新する
    if (currentSession.session && currentSession.sessionReportBySessionIDForInstructor) {
      submitUpdateSessionReport(currentSession.sessionReportBySessionIDForInstructor.id);
    } else {
      submitCreateSessionReport();
    }
  };

  // セッション受講履歴の開始時間のフォーマット
  const formatSessionStartAt = () => {
    // 基本的にはありえないが、optionalになっているのでチェック
    if (!currentSession || !currentSession.session) {
      return '';
    }

    const startAt = new Date(currentSession.session.startAt);
    const endAt = new Date(currentSession.session.endAt);

    return `${format(startAt, 'yyyy/MM/dd HH:mm')} ~ ${format(endAt, 'yyyy/MM/dd HH:mm')}`;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      loading={props.loading}
      width={'80vw'}
      header={`セッション受講履歴${
        currentSession?.session?.phase === Phase.NotCompleted ? '登録' : '編集'
      }`}
      footer={
        <Buttons>
          <SubmitButton onClick={submit}>
            {currentSession?.session?.phase === Phase.NotCompleted ? '登録' : '更新'}
          </SubmitButton>
        </Buttons>
      }
    >
      <ModalContainer>
        <ModalContent>
          <Form>
            <FormContent>
              <LessonInfo>
                <LessonInfoLabel>受講生名:</LessonInfoLabel>
                <p>
                  {currentSession?.session?.coaching.course.student.user.maskedPersonalInfo?.name}
                </p>
                <LessonInfoLabel>セッションタイトル:</LessonInfoLabel>
                <p>{currentSession?.session?.title}</p>
                <LessonInfoLabel>セッション時間:</LessonInfoLabel>
                <p>{formatSessionStartAt()}</p>
              </LessonInfo>
              <FormSection>
                <label className="headline">1.セッションを行いましたか？</label>
                <span>※必須</span>
                <Radio
                  name="phase"
                  options={SessionPahse}
                  currentValue={phase}
                  onChange={(e) => {
                    if (e.target.value === Phase.Completed) {
                      setPhase(e.target.value);
                    } else {
                      clearDefaultValues();
                      setPhase(e.target.value);
                    }
                  }}
                />
                {errors.phase && <ErrorText>{errors.phase}</ErrorText>}
              </FormSection>

              <FormSection>
                <label className={isCanceled ? 'disabled headline' : 'headline'}>
                  2.今回のセッションの内容は？
                </label>
                <span>※必須</span>
                {currentSession?.session?.phase === Phase.NotCompleted ? (
                  <p>
                    ※記入例を記載していますので、内容を上書きしてください。デフォルトのままだとセッションの登録ができません。
                  </p>
                ) : (
                  ''
                )}
                <TextArea
                  name="content"
                  className={isCanceled ? 'disabled' : ''}
                  ref={sessionContentRef}
                  disabled={isCanceled}
                  placeholder={DefaultContent}
                />
                {errors.content && <ErrorText>{errors.content}</ErrorText>}
              </FormSection>

              <FormSection>
                <label className={isCanceled ? 'disabled headline' : 'headline'}>
                  3.前後の準備は何をしましたか？
                </label>
                <span>※必須</span>
                <CheckBox
                  name="preparations"
                  checkedValues={preparations}
                  options={Preparations}
                  disabled={isCanceled}
                  onChange={(e) => {
                    setPreparations((prev) => {
                      // チェックが外された場合
                      if (prev.includes(e.target.value)) {
                        return prev.filter((a) => a !== e.target.value);
                      }
                      return [...preparations, e.target.value];
                    });
                  }}
                />
                {errors.preparations && <ErrorText>{errors.preparations}</ErrorText>}
              </FormSection>

              <FormSection
                className={`${
                  preparations.includes(SessionReportPreparationType.OtherTask) ? '' : 'disable'
                }`}
              >
                <label className={isCanceled ? 'disabled headline' : 'headline'}>
                  「その他」を選択した方：詳細を教えてください
                </label>
                <TextArea
                  name="hasAnnouncementDetail"
                  className={isCanceled ? 'disabled' : ''}
                  ref={sessionPrepareRef}
                  disabled={isCanceled}
                />
                {errors.preparationOthers && <ErrorText>{errors.preparationOthers}</ErrorText>}
              </FormSection>

              <FormSection>
                <label className={isCanceled ? 'disabled headline' : 'headline'}>
                  4.学習スケジュールは進捗通りですか？
                </label>
                <span>※必須</span>
                <Radio
                  name="schedule"
                  options={Schedules}
                  currentValue={schedule}
                  onChange={(e) => {
                    setSchedule(e.target.value);
                  }}
                  disabled={isCanceled}
                />
                {errors.schedule && <ErrorText>{errors.schedule}</ErrorText>}
              </FormSection>

              <FormSection
                className={`${
                  schedule === IsOnScheduleType.TooLate || schedule === IsOnScheduleType.BitLate
                    ? ''
                    : 'disable'
                }`}
              >
                <label className={isCanceled ? 'disabled headline' : 'headline'}>
                  「遅れている」「少し遅れている」を選択した方：受講生へのフォロー内容を教えてください。運営へ連携がある場合は次の項目に記載してください。
                </label>
                <TextArea
                  name="delayReason"
                  className={isCanceled ? 'disabled' : ''}
                  ref={sessionDelayReasonRef}
                  disabled={isCanceled}
                />
                {errors.delayReason && <ErrorText>{errors.delayReason}</ErrorText>}
              </FormSection>

              <FormSection>
                <label className={isCanceled ? 'disabled headline' : 'headline'}>
                  5.運営への連携事項はありますか？
                </label>
                <span>※必須</span>
                <Radio
                  name="hasAnnouncements"
                  options={HasAnnouncements}
                  currentValue={hasAnnouncements}
                  onChange={(e) => {
                    if (e.target.value === HasAnnouncementsType.No) {
                      setAnnouncements([]);
                      sessionAnnouncementRef.current?.setValue('');
                      setResponseDeadline('');
                    }
                    setHasAnnouncements(e.target.value);
                  }}
                  disabled={isCanceled}
                />
                {errors.hasAnnouncements && <ErrorText>{errors.hasAnnouncements}</ErrorText>}
              </FormSection>

              <FormSection
                className={`${hasAnnouncements === HasAnnouncementsType.Yes ? '' : 'disable'}`}
              >
                <label className={isCanceled ? 'disabled headline' : 'headline'}>
                  どのような内容ですか？ ※複数選択可能
                </label>
                <span>※必須</span>
                <CheckBox
                  name="announcements"
                  checkedValues={announcements}
                  options={Announcements}
                  disabled={isCanceled}
                  onChange={(e) => {
                    setAnnouncements((prev) => {
                      // チェックが外された場合
                      if (prev.includes(e.target.value)) {
                        return prev.filter((a) => a !== e.target.value);
                      }
                      return [...announcements, e.target.value];
                    });
                  }}
                />
                {errors.announcements && <ErrorText>{errors.announcements}</ErrorText>}
              </FormSection>

              <FormSection
                className={`${hasAnnouncements === HasAnnouncementsType.Yes ? '' : 'disable'}`}
              >
                <label className={isCanceled ? 'disabled headline' : 'headline'}>
                  詳細を教えてください
                </label>
                <span>※必須</span>
                <TextArea
                  name="hasAnnouncementDetail"
                  className={isCanceled ? 'disabled' : ''}
                  ref={sessionAnnouncementRef}
                  disabled={isCanceled}
                  placeholder={HasAnnouncementDetailfaultContent}
                />
                {errors.hasAnnouncementDetail && (
                  <ErrorText>{errors.hasAnnouncementDetail}</ErrorText>
                )}
              </FormSection>

              <FormSection
                className={`${hasAnnouncements === HasAnnouncementsType.Yes ? '' : 'disable'}`}
              >
                <label className={isCanceled ? 'disabled headline' : 'headline'}>
                  対応希望期限について教えてください
                </label>
                <span>※緊急の内容はSlackでお知らせください</span>
                <Radio
                  name="responseDeadline"
                  options={ResponseDeadline}
                  currentValue={responseDeadline}
                  onChange={(e) => setResponseDeadline(e.target.value)}
                  disabled={isCanceled}
                />
                {errors.responseDeadline && <ErrorText>{errors.responseDeadline}</ErrorText>}
              </FormSection>

              <FormSection className="section">
                <label className="headline">6.メモスペース</label>
                <TextArea
                  name="memo"
                  ref={sessionMemoRef}
                  placeholder="自由記述　※コーチ用の受講生に関するメモを記録するスペースです。ご自由に活用ください。"
                />
              </FormSection>
            </FormContent>
          </Form>
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled.div`
  padding: 2rem;
`;

const ModalContent = styled.div`
  margin: 0 auto;
`;

const Buttons = styled.div`
  text-align: right;
`;

const SubmitButton = styled(Button)`
  bottom: 2rem;
  margin: 0 0.1875rem;
  padding: 0.5rem 0;
  width: 8.125rem;
  font-size: 0.9375;
  font-weight: 700;
`;

const Form = styled.div`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  p {
    margin-bottom: 1rem;
  }

  textarea {
    height: 100px;
  }

  p.disable {
    display: none;
  }

  .err {
    margin-top: 0.4rem;
    font-size: 0.8rem;
    color: #e2001b;
  }

  .disabled {
    color: gray;
  }
`;

const FormContent = styled.div`
  padding: 1.25rem;
  box-sizing: border-box;
  transform: translateZ(0);
  overflow-y: scroll;

  span {
    margin-left: 1rem;
    color: #e2001b;
  }

  h4 {
    font-weight: 600;
  }
`;

const FormSection = styled.p`
  margin-bottom: 1.4rem;

  label {
    font-weight: normal;
  }

  .headline {
    display: inline-block;
    margin-bottom: 0.3rem;
    font-weight: 600;
  }

  input:not([type='radio']):not([type='checkbox']),
  textarea,
  button {
    display: block;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input:not([type='radio']):not([type='checkbox']),
  textarea {
    width: 97%;
    padding: 0.6rem;
    margin-top: 0.8rem;
    border-radius: 0px;
    font-size: 0.8rem;
    border: solid 1px rgba(0, 0, 0, 0.36);
  }
`;

const LessonInfo = styled.div`
  background-color: #ffe86d;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
`;

const LessonInfoLabel = styled.p`
  margin: 5px 0;
  display: inline-block;
  margin-bottom: 0.3rem;
  font-weight: 650;
`;

const ErrorText = styled.p`
  margin-top: 0.25rem;
  color: #e2001b;
  font-size: 0.75rem;
`;
