import React from 'react';
import styled from 'styled-components';

interface FormHeadlineProps {
  isRequired?: boolean;
  className?: string;
  children: React.ReactNode;
}

export const FormHeadline: React.FC<FormHeadlineProps> = (props) => {
  return (
    <H3 className={props.className}>
      {props.children}
      {Boolean(props.isRequired) && <span>※必須</span>}
    </H3>
  );
};

const H3 = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;

  span {
    margin-left: 0.25rem;
    color: #eb0000;
    font-size: 0.75rem;
  }
`;
