import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Markdown } from '../../common/Markdown';

import { Box } from '../atoms/Box';
import { Modal } from '../molecules/Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  content?: string;
}

export const PracticeConfirmModal: React.FC<Props> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      width="1100px"
      header={<Title>課題の概要</Title>}
    >
      <Container>
        <StyledBox padding={[6, 4]}>
          <TitleArea>
            <h1>{props.title}</h1>
            {Boolean(props.description) && <p>{props.description}</p>}
          </TitleArea>
        </StyledBox>
        <MarkdownWrapper>
          <Markdown content={props.content} type="program" />
        </MarkdownWrapper>
      </Container>
    </Modal>
  );
};

const Title = styled.h2`
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-align: center;
`;
const Container = styled.div`
  max-width: 892px;
  margin: 0 auto;
  padding: 2rem 1rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 0 1rem 2rem;
  `}
`;
const StyledBox = styled(Box)`
  ${media.lessThan('medium')`
    margin: 0 -1rem;
    padding-top: 2rem;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.1);
    border-radius: 0;
  `}
`;
const TitleArea = styled.div`
  max-width: 740px;
  margin: 0 auto;

  h1 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 2.25rem;
    font-weight: 700;
  }

  p {
    margin-top: 0.75rem;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    line-height: 1.375;
  }

  ${media.lessThan('medium')`
    h1 {
      font-size: 1.5rem;
    }

    p {
      margin-top: 2rem;
      line-height: 1.5;
    }
  `}
`;

const MarkdownWrapper = styled.div`
  max-width: 740px;
  margin: 2rem auto 0;
`;
