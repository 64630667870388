import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Markdown } from '../../common/Markdown';
import { PracticeSubmitReviewStatusIcon } from '../atoms/PracticeSubmitReviewStatusIcon';
import {
  PracticeSubmitDetailFragment,
  PracticeSubmitDetailReviewStatus,
  UserFragment,
} from '../../gen/graphql';

import { Box } from '../atoms/Box';
import { Button } from '../atoms/Button';
import { Control, MenuProps } from '../molecules/Control';
import { UserIcon } from '../organisms/UserIcon';
import { PracticeSubmitReview } from './PracticeSubmitReview';
import { PracticeSubmitReviewCreate } from './PracticeSubmitReviewCreate';

// 一覧（PracticeSubmitListFragment）と
// 詳細（PracticeSubmitFragment）とで
// 返却モデルが違うため、個々に設定
interface PracticeSubmitCardProps {
  className?: string;
  reviewStatus: PracticeSubmitDetailReviewStatus;
  reviewedCount: number;
  practiceTitle: string;
  programTitle: string;
  submitter: UserFragment;
  submitCreatedAt: string;
  submitEditedAt?: string;
  clickCard?: () => void; // 一覧用
  practiceID?: string; // 詳細用
  programID?: number; // 詳細用
  practiceSubmitDetail?: PracticeSubmitDetailFragment; // 詳細用
  index?: number; // 詳細用
  clickReSubmit?: () => void;
  setSubmitterControlMenu?: (value: string) => MenuProps[]; // 詳細用
  isDisplaySubmitterEditButton?: (
    index: number,
    reviewStatus: PracticeSubmitDetailReviewStatus,
  ) => boolean; // 詳細用
  isDisplayResubmitButton?: (
    index: number,
    reviewStatus: PracticeSubmitDetailReviewStatus,
  ) => boolean; // 詳細用
  isDisplayReviewCreateArea?: (reviewStatus: PracticeSubmitDetailReviewStatus) => boolean; // 詳細用
  isDisplayReviewerEditButton?: (reviewerUserID: number) => boolean; // 詳細用
  fetchPracticeSubmitForInstructor?: () => Promise<void>; // 詳細用
}

export const PracticeSubmitCard = ({
  className,
  reviewStatus,
  reviewedCount,
  practiceTitle,
  programTitle,
  submitter,
  submitCreatedAt,
  submitEditedAt,
  clickCard,
  practiceID,
  programID,
  practiceSubmitDetail,
  index,
  clickReSubmit,
  setSubmitterControlMenu,
  isDisplaySubmitterEditButton,
  isDisplayResubmitButton,
  isDisplayReviewCreateArea,
  isDisplayReviewerEditButton,
  fetchPracticeSubmitForInstructor,
}: PracticeSubmitCardProps): JSX.Element => {
  const handleClickCard = useCallback(() => {
    if (!clickCard) return;
    clickCard();
  }, [clickCard]);

  const handleClickReSubmit = useCallback(() => {
    if (!clickReSubmit) return;
    clickReSubmit();
  }, [clickReSubmit]);

  const setSubmitterControl = useCallback(() => {
    if (!setSubmitterControlMenu || !practiceSubmitDetail?.id) return [];
    return setSubmitterControlMenu(practiceSubmitDetail.id);
  }, [practiceSubmitDetail?.id, setSubmitterControlMenu]);

  const isSubmitterEdit = useCallback(() => {
    if (!isDisplaySubmitterEditButton || index === undefined || !practiceSubmitDetail?.reviewStatus)
      return false;
    return isDisplaySubmitterEditButton(index, practiceSubmitDetail.reviewStatus);
  }, [index, isDisplaySubmitterEditButton, practiceSubmitDetail?.reviewStatus]);

  const isResubmit = useCallback(() => {
    if (!isDisplayResubmitButton || index === undefined || !practiceSubmitDetail?.reviewStatus)
      return false;
    return isDisplayResubmitButton(index, practiceSubmitDetail.reviewStatus);
  }, [index, isDisplayResubmitButton, practiceSubmitDetail?.reviewStatus]);

  const isReviewCreate = useCallback(() => {
    if (!isDisplayReviewCreateArea || !practiceSubmitDetail?.reviewStatus) return false;
    return isDisplayReviewCreateArea(practiceSubmitDetail.reviewStatus);
  }, [isDisplayReviewCreateArea, practiceSubmitDetail?.reviewStatus]);

  // 詳細項目表示判別
  const isDisplayDetail = useMemo(() => Boolean(programID), [programID]);

  return (
    <StyledBox onClick={clickCard ? handleClickCard : undefined} className={className} article>
      <SubmitInfoContainer>
        <SubmitInfoArea isDisplayDetail={isDisplayDetail}>
          <ActionArea>
            {isSubmitterEdit() && <SpStyledControl menu={setSubmitterControl()} />}
          </ActionArea>
          <StatusAreaForSp>
            <StatusArea>
              <PracticeSubmitReviewStatusIcon reviewStatus={reviewStatus} />
              <Review>
                <span>{reviewedCount}</span>
                レビュー
              </Review>
            </StatusArea>
            {
              // 詳細表示時、かつ、スマホ画面時に表示
              isDisplayDetail && <SpTitle>{practiceTitle}</SpTitle>
            }
          </StatusAreaForSp>
          <ContentArea>
            <Title isDisplayDetail={isDisplayDetail}>{practiceTitle}</Title>
            {
              // 詳細表示時は非表示
              !isDisplayDetail && <Program>教材：{programTitle}</Program>
            }
            <StyledUserIcon
              user={submitter}
              date={submitEditedAt ?? submitCreatedAt}
              note={submitEditedAt ? '(編集済み)' : ''}
              medium
            />
            {programID && practiceID && practiceSubmitDetail && (
              <LinkItemList>
                <LinkItem>
                  <ItemLink to={`/programs/${programID}/chapters`} target="_blank">
                    教材：{programTitle}
                  </ItemLink>
                </LinkItem>
                <LinkItem>
                  <ItemLink to={`/practices/${practiceID}/body`} target="_blank">
                    課題：{practiceTitle}
                  </ItemLink>
                </LinkItem>
                {practiceSubmitDetail.url && (
                  <LinkItem>
                    <SubmitLink href={practiceSubmitDetail.url} target="_blank">
                      提出物：{practiceSubmitDetail.url}
                    </SubmitLink>
                  </LinkItem>
                )}
              </LinkItemList>
            )}
          </ContentArea>
          <ActionArea>
            {isSubmitterEdit() && <PcStyledControl menu={setSubmitterControl()} />}
            {isResubmit() && (
              <ResubmitButton onClick={handleClickReSubmit}>再提出する</ResubmitButton>
            )}
          </ActionArea>
        </SubmitInfoArea>
        {practiceSubmitDetail && (
          <SubmitCommentArea>
            <Markdown content={practiceSubmitDetail.content ?? ''} />
          </SubmitCommentArea>
        )}
      </SubmitInfoContainer>
      {practiceSubmitDetail?.practiceSubmitDetailReviewComment &&
      isDisplayReviewerEditButton &&
      fetchPracticeSubmitForInstructor ? (
        <ReviewInfoContainer>
          <PracticeSubmitReview
            practiceSubmitID={practiceSubmitDetail.practiceSubmitID}
            practiceSubmitDetailID={practiceSubmitDetail.id}
            practiceSubmitDetailReviewStatus={practiceSubmitDetail.reviewStatus}
            practiceSubmitDetailReviewComment={
              practiceSubmitDetail.practiceSubmitDetailReviewComment
            }
            isDisplayReviewerEditButton={isDisplayReviewerEditButton}
            fetchPracticeSubmitForInstructor={fetchPracticeSubmitForInstructor}
          />
        </ReviewInfoContainer>
      ) : (
        practiceSubmitDetail &&
        isReviewCreate() &&
        fetchPracticeSubmitForInstructor && (
          <ReviewInfoContainer>
            <PracticeSubmitReviewCreate
              practiceSubmitID={practiceSubmitDetail.practiceSubmitID}
              practiceSubmitDetailID={practiceSubmitDetail.id}
              fetchPracticeSubmitForInstructor={fetchPracticeSubmitForInstructor}
            />
          </ReviewInfoContainer>
        )
      )}
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const SubmitInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;

  ${media.lessThan('medium')`
    padding: 1rem;
  `}
`;

const SubmitInfoArea = styled.div<{ isDisplayDetail: boolean }>`
  display: flex;
  gap: 0.75rem;
  padding-bottom: 1rem;

  ${(props) => (props.isDisplayDetail ? media.lessThan('medium')`flex-direction: column;` : '')}
`;

const ActionArea = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;

  .pointer {
    cursor: pointer;
  }
`;

const StyledControl = styled(Control)`
  position: absolute;
  top: 0;
  right: 0;
`;

const PcStyledControl = styled(StyledControl)`
  ${media.lessThan('medium')`
    display: none;
  `}
`;

const SpStyledControl = styled(StyledControl)`
  display: none;

  ${media.lessThan('medium')`
    display: flex;
  `}
`;

const StatusAreaForSp = styled.div`
  ${media.lessThan('medium')`
    display: flex;
    gap: 0.75rem;
  `}
`;

const StatusArea = styled.div`
  width: 5rem;
`;

const Review = styled.p`
  margin-top: 0.375rem;
  color: rgba(0, 0, 0, 0.36);
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.003em;
  text-align: center;

  span {
    display: block;
    margin-bottom: 0.125rem;
    font-size: 1.5rem;
  }
`;

const SpTitle = styled.h3`
  display: none;

  ${media.lessThan('medium')`
    display: flex;
    
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.1px;
  `}
`;

const ContentArea = styled.div`
  flex: 1;
`;

const Title = styled.h3<{ isDisplayDetail: boolean }>`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.1px;

  ${(props) => (props.isDisplayDetail ? media.lessThan('medium')`display: none;` : '')}
`;

const Program = styled.p`
  margin-top: 0.25rem;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  line-height: 1.4;
  letter-spacing: 0.1px;
`;

const StyledUserIcon = styled(UserIcon)`
  margin-top: 0.5rem;
`;

const LinkItemList = styled.div`
  margin-top: 0.75rem;
`;

const LinkItem = styled.p`
  margin: 0;
  line-height: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  container-type: inline-size;

  &:nth-of-type(n + 2) {
    margin-top: 0.25rem;
  }
`;

const ItemLink = styled(Link)`
  color: #eb0000;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.1px;

  &:hover {
    text-decoration: underline;
  }
`;

const SubmitLink = styled.a`
  color: #eb0000;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.1px;

  &:hover {
    text-decoration: underline;
  }
`;

const ResubmitButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  width: 9.125rem;
  height: 2.25rem;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #eb0000;
  border-radius: 0.1875rem;
  color: #eb0000;
  font-weight: 700;
  font-size: 0.875rem;
`;

const SubmitCommentArea = styled.div`
  display: flex;
  gap: 0.75rem;
  padding-top: 1rem;
  padding-left: 5.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    padding-left: 0;
  `}
`;

const ReviewInfoContainer = styled.div`
  padding-left: 5.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    padding-left: 0;
  `}
`;
