import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import IconAmex from '../../static/image/brand_amex.png';
import IconDiners from '../../static/image/brand_diners.png';
import IconJcb from '../../static/image/brand_jcb.png';
import IconMaster from '../../static/image/brand_master.png';
import IconVisa from '../../static/image/brand_visa.png';
import IconCvc from '../../static/image/icon_cvc.png';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import stripeJs from '@stripe/stripe-js';

interface RegisterPaymentProps {
  numberError: string;
  expirationError: string;
  cvcError: string;
  setIsCheckedFrontValidation?: (isChecked: boolean) => void;
  setStripe: (stripe: stripeJs.Stripe | null) => void;
  setElements: (elements: stripeJs.StripeElements | null) => void;
  className?: string;
}

export const RegisterPayment: React.FC<RegisterPaymentProps> = (props) => {
  const [cardNumberError, setCardNumberError] = React.useState(props.numberError);
  const [expirationError, setExpirationError] = React.useState(props.expirationError);
  const [cvcError, setCvcError] = React.useState(props.cvcError);

  const [checkedCardNumber, setCheckedCardNumber] = React.useState(false);
  const [checkedExpiration, setCheckedExpiration] = React.useState(false);
  const [checkedCvc, setCheckedCvc] = React.useState(false);

  props.setStripe(useStripe());
  props.setElements(useElements());

  const cardNumberOptions = {
    showIcon: true,
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  React.useEffect(() => {
    if (props.setIsCheckedFrontValidation) {
      props.setIsCheckedFrontValidation(checkedCardNumber && checkedExpiration && checkedCvc);
    }
  }, [props, checkedCardNumber, checkedExpiration, checkedCvc]);

  return (
    <React.Fragment>
      <InputArea className={props.className}>
        <FormRow>
          <Label>
            <span className="required">必須</span>
            <label>カード番号</label>
            <Brands>
              <img src={IconJcb} alt="" />
              <img src={IconVisa} alt="" />
              <img src={IconMaster} alt="" />
              <img src={IconDiners} alt="" />
              <img src={IconAmex} alt="" />
            </Brands>
          </Label>

          <Field validation={checkedCardNumber}>
            <CardNumberElement
              options={cardNumberOptions}
              onChange={(event: stripeJs.StripeCardNumberElementChangeEvent) => {
                if (event.error) {
                  setCardNumberError(event.error.message);
                } else {
                  setCardNumberError('');
                }
                setCheckedCardNumber(event.complete);
              }}
            />
          </Field>
          <ErrorText>{cardNumberError}</ErrorText>
        </FormRow>

        <FormRow>
          <Label>
            <span className="required">必須</span>
            <label>有効期限</label>
          </Label>

          <NarrowField validation={checkedExpiration}>
            <CardExpiryElement
              onChange={(event: stripeJs.StripeCardExpiryElementChangeEvent) => {
                if (event.error) {
                  setExpirationError(event.error.message);
                } else {
                  setExpirationError('');
                }
                setCheckedExpiration(event.complete);
              }}
            />
          </NarrowField>
          <ErrorText>{expirationError}</ErrorText>
        </FormRow>

        <FormRow>
          <Label>
            <span className="required">必須</span>
            <label>セキュリティコード</label>
          </Label>

          <CvcColumn>
            <NarrowField validation={checkedCvc}>
              <CardCvcElement
                onChange={(event: stripeJs.StripeCardCvcElementChangeEvent) => {
                  if (event.error) {
                    setCvcError(event.error.message);
                  } else {
                    setCvcError('');
                  }
                  setCheckedCvc(event.complete);
                }}
              />
            </NarrowField>

            <img src={IconCvc} alt="" />
          </CvcColumn>

          <ErrorText>{cvcError}</ErrorText>
        </FormRow>
      </InputArea>
    </React.Fragment>
  );
};
const FormRow = styled.div`
  ${media.lessThan('medium')`
    position: relative;
    flex-direction: column;

    & > * + * {
      margin-top: 0.5rem;
    }

    input {
      display: block;
      width: 100% !important;
    }
  `}
`;
const Label = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 1rem;

  .required {
    display: block;
    margin-right: 0.5rem;
    padding: 0.125rem 0.5rem;
    background-color: #fd6258;
    color: #fff;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.2;
  }

  label {
    font-size: 1rem;
    font-weight: 700;
  }

  small {
    display: block;
    margin-left: 0.5rem;
    color: rgba(0, 0, 0, 0.36);
    font-size: 0.75rem;
  }
`;
const ErrorText = styled.p`
  margin-top: 0.5rem;
  color: #fd6258;
  align-self: center;
  font-weight: 500;
`;

const Brands = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-left: 0.5rem;

  img {
    display: block;
    height: 1.5rem;
  }

  ${media.lessThan('large')`
    gap: .25rem;

    img {
      height: 1rem;
    }
  `}

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const CvcColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  > img {
    display: block;
    height: 1.5rem;
  }
`;

const InputArea = styled.div`
  margin-top: 1.25rem;
  padding: 1rem;
  background: #ecf6fc;
  border-radius: 0.125rem;

  ${FormRow} + ${FormRow} {
    margin-top: 2rem;
  }

  ${media.lessThan('medium')`
    margin-top: 1rem;
    padding: .75rem;
  `}
`;
const Field = styled.div<{ validation: boolean }>`
  width: 100%;
  padding: 0.75rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.36);
  box-sizing: border-box;

  ${(props) =>
    props.validation
      ? `
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      margin: auto;
      background: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%2210%22%20fill%3D%22%234FC59A%22%2F%3E%3Cpath%20d%3D%22M8.1264%2012.7922L5.79418%2010.46L5%2011.2486L8.1264%2014.375L14.8378%207.66359L14.0492%206.875L8.1264%2012.7922Z%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E') center / contain no-repeat;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px;
    }
  `
      : ''}
`;
const NarrowField = styled(Field)`
  max-width: 110px;
`;
