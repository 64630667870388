import { User, IconUserFragment, ChatMemberUserFragment } from '../gen/graphql';
import { User as TsUser } from '../swagger/gen/ts_front';
import UserType from '../const/UserType';
import { SnsType } from '../store';

/**
 * @deprecated 用途や処理の意図が不明であり、全ロールに対応していない可能性があるため非推奨とします。
 */
export const getUserType = (
  user: IconUserFragment | TsUser | ChatMemberUserFragment | undefined,
  isInstructor: boolean,
): string => {
  // TODO isAdmin, isInstructorとisCoarchで型が違うためswitchで分岐できない
  if (user?.isAdmin) {
    return UserType.Admin;
  } else if (user?.instructor?.isCoach) {
    return UserType.Coach;
  } else if (user?.isInstructor) {
    return UserType.Instructor;
    // TODO AB#11729 swagger除去してgraphqlのUserに型を統一したらinでの分岐を削除
  } else if (user && 'isAI' in user && user.isAI) {
    return UserType.AI;
  }

  if (isInstructor) {
    if (user?.isStudent) {
      return UserType.Student;
    } else {
      switch (user?.snsType) {
        case SnsType.TeamSubscription:
          return UserType.Team;
        case SnsType.PersonalSubscription:
        case SnsType.PremiumSubscription:
          return UserType.SnsUser;
        default:
          return '';
      }
    }
  }

  return '';
};

/**
 * @deprecated 用途や処理の意図が不明であり、全ロールに対応していない可能性があるため非推奨とします。
 */
export const getUserTypeFromUser = (
  user: Pick<User, 'isInstructor' | 'isStudent'> | null,
): (typeof UserType)[keyof typeof UserType] => {
  if (user?.isInstructor) {
    return UserType.Instructor;
  }
  if (user?.isStudent) {
    return UserType.Student;
  }
  return UserType.SnsUser;
};
