import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

import DefaultImage from '../../../static/image/icon_default.svg';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Button } from '../../atoms/Button';
import { TeamMemberList } from '../../organisms/TeamMemberList';
import { CircularIcon } from '../../atoms/CircularIcon';
import { Alert } from '../../molecules/Alert';
import {
  useGetTeamExistSubscriptionQuery,
  useGetTeamMembersByTeamIdQuery,
} from '../../../gen/graphql';
import { FIRST_PAGE, FETCH_LIMIT_LOW } from '../../../const/Page';
import { useUser } from '../../../redux/user/useUser';
import { Loader } from '../../molecules/Loader';
import { TeamTabLayout } from '../../templates/TeamTabLayout';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { useURL } from '../../../common/customHooks/URL';
import { LOWER_META_TITLE } from '../../../const/Service';

export const TeamTop: React.FC = () => {
  const metaTitle = `チーム | ${LOWER_META_TITLE}`;

  const { setParams, queries } = useURL();

  const page = useMemo(() => (queries?.page ? Number(queries.page) : FIRST_PAGE), [queries?.page]);

  const setPage = useCallback(
    (value: number) => {
      setParams([{ name: 'page', value: value.toString() }]);
    },
    [setParams],
  );

  const navigate = useNavigate();
  const { user, team, permissionCheck } = useUser();

  const { data: teamExistSubscriptionData, loading: teamExistSubscriptionLoading } =
    useGetTeamExistSubscriptionQuery({
      variables: {
        teamID: user.teamID ?? '',
      },
      skip: !user.teamID,
    });
  const invalidTeamSubscription = !teamExistSubscriptionData?.teamExistSubscription;

  const { data: teamMembersByTeamIDData, loading: teamMembersByTeamIDLoading } =
    useGetTeamMembersByTeamIdQuery({
      variables: {
        input: {
          teamID: user.teamID ?? '',
          page: page,
          limit: FETCH_LIMIT_LOW,
        },
      },
      skip: !user.teamID,
    });
  const teamMembers = teamMembersByTeamIDData?.getTeamMembersByTeamID.items ?? [];
  const total = teamMembersByTeamIDData?.getTeamMembersByTeamID.total ?? 0;

  const editTeam = () => {
    navigate('/team/profile');
  };

  return (
    <>
      <Loader display={teamExistSubscriptionLoading || teamMembersByTeamIDLoading} />
      <TeamTabLayout
        activeTab="team"
        isAdmin={permissionCheck(FunctionType.TeamProfile, PermissionType.Read)}
        metaTitle={metaTitle}
      >
        {/* ユーザー権限でTabNavigationLayoutとBasicLayoutの切り替え */}
        <PageWrapper>
          {invalidTeamSubscription &&
            permissionCheck(FunctionType.RecommendTeamSubscription, PermissionType.Read) && (
              <AlertArea>
                <Alert
                  severity="error"
                  title="ご利用再開について"
                  link="/team/subscription/payment"
                  linkLabel="ご利用を再開する"
                >
                  法人アカウントが無効になっています。ご利用再開の手続きはこちらからお願いします。
                </Alert>
              </AlertArea>
            )}
          <Container>
            <SpTitle>
              <SpTeamName>{team?.team.companyName}</SpTeamName>
              {permissionCheck(FunctionType.TeamProfile, PermissionType.Read) && (
                <EditButton onClick={editTeam}>チーム設定</EditButton>
              )}
            </SpTitle>
            <Profile>
              <CircularIcon size={96} mediumSize={48} src={team?.team.image ?? DefaultImage} />
              <TeamName>{team?.team.companyName}</TeamName>
              {permissionCheck(FunctionType.TeamProfile, PermissionType.Read) && (
                <PcEditButton onClick={editTeam}>チーム設定</PcEditButton>
              )}
            </Profile>
            <MemberTitle>メンバー</MemberTitle>
            <TeamMemberList teamMembers={teamMembers} total={total} page={page} setPage={setPage} />
          </Container>
        </PageWrapper>
      </TeamTabLayout>
    </>
  );
};

const AlertArea = styled.div`
  width: 100%;
  max-width: 1006px;
  margin: 0 auto;
  box-sizing: border-box;
  margin-bottom: 2rem;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1006px;
  margin: 0 auto;
  padding: 2rem 2.5rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 0;
    border: none;
  `}
`;

const EditButton = styled(Button)`
  display: block;
  width: 7.5rem;
  padding: 0.5rem;
  background: #fff;
  border: 1px solid #eb0000;
  color: #eb0000;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    background: #eb0000;
    color: #fff;
  }
`;
const PcEditButton = styled(EditButton)`
  height: 2.25rem;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  ${media.lessThan('medium')`
    display: none;
  `}
`;

const SpTitle = styled.div`
  display: none;

  ${media.lessThan('medium')`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  `}
`;
const SpTeamName = styled.p`
  flex: 1;
  margin-right: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.5;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 9rem;
  box-sizing: border-box;
  position: relative;

  ${media.lessThan('medium')`
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: .125rem;
    box-sizing: border-box;
  `}
`;
const TeamName = styled.p`
  margin-left: 0.5rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1em;

  ${media.lessThan('medium')`
    margin: .375rem auto 0;
    font-size: .75rem;
  `}
`;

const MemberTitle = styled.h3`
  margin: 2rem auto 1rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5;

  ${media.lessThan('medium')`
    margin: 1rem auto;
    padding-top: 0;
    border-top: none;
  `}
`;
