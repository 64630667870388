import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

import { BasicLayout } from '../../templates/BasicLayout';
import { Loaders } from '../../../common/Loader';
import { PageWrapper } from '../../atoms/PageWrapper';
import { CourseProgramDetailFinish } from '../../molecules/CourseProgramDetailFinish';
import { CourseProgramHeader } from '../../molecules/CourseProgramHeader';
import { CourseProgramModal } from '../../molecules/CourseProgramModal';
import { Breadcrumb } from '../../organisms/Breadcrumb';
import { CourseProgramDetailArticle } from '../../organisms/CourseProgramDetailArticle';

import { useGetCourseProgramQuery } from '../../../gen/graphql';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';
import { useUser } from '../../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { NotFoundPage } from '../public/NotFound';
import { excludeStepsWithoutItems } from '../../../utils/CourseProgramDetail';

export const CourseProgramDetailContainer = (): JSX.Element => {
  const paramCourseProgramID = useParams<{ course_program_id: string }>().course_program_id;
  if (!paramCourseProgramID) return NotFoundPage;

  return <CourseProgramDetail courseProgramID={paramCourseProgramID} />;
};

interface CourseProgramDetailProps {
  courseProgramID: string;
}

const CourseProgramDetail: React.FC<CourseProgramDetailProps> = (props) => {
  const { user, permissionCheck } = useUser();

  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');

  const { data, loading } = useGetCourseProgramQuery({
    variables: {
      id: props.courseProgramID,
      userID: user.lmsUser?.id,
    },
    onCompleted: (data) => {
      setMetaTitle(`${data?.courseProgram?.title} | ${LOWER_META_TITLE}`);
      setMetaDescription(
        `${SERVICE_NAME}のコース教材ページです。この教材では、${data?.courseProgram?.description}`,
      );
    },
  });
  const courseProgram = data?.courseProgram;

  const filteredCourseProgramSteps = excludeStepsWithoutItems(
    courseProgram?.courseProgramSteps ?? [],
  );

  const breadcrumbs = [
    {
      label: 'ホーム',
      to: '/home',
    },
    {
      label: '教材',
      to: '/programs',
    },
    {
      label: courseProgram?.title ?? 'コース教材詳細',
    },
  ];

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [linkTo, setLinkTo] = React.useState('');

  return (
    <BasicLayout pageTitle="コース教材" metaTitle={metaTitle} metaDescription={metaDescription}>
      <CourseProgramModal
        isOpen={isModalOpen}
        toggle={(n: boolean) => setIsModalOpen(n)}
        linkTo={linkTo}
        courseProgramId={props.courseProgramID}
      />
      <PageWrapper>
        {!loading ? (
          <div>
            <StyledBreadcrumb links={breadcrumbs} />
            {courseProgram && <CourseProgramHeader courseProgram={courseProgram} />}
            <ProgramsContainer>
              {filteredCourseProgramSteps.map((courseProgramStep, i) => {
                const courseProgramStepNumber = i + 1;
                return (
                  <CourseProgramDetailArticle
                    id={courseProgramStep.id}
                    key={courseProgramStep.id}
                    courseProgramStepItems={courseProgramStep.courseProgramStepItems ?? []}
                    number={courseProgramStepNumber ?? 0}
                    title={courseProgramStep.title ?? ''}
                    description={courseProgramStep.description ?? ''}
                    isStarted={Boolean(courseProgram?.courseProgramUserData?.isStarted)}
                    showProgress={permissionCheck(FunctionType.StudyLog, PermissionType.Read)}
                    openModal={(to: string) => {
                      setLinkTo(to);
                      setIsModalOpen(true);
                    }}
                  />
                );
              })}
              <CourseProgramDetailFinish
                isFinished={
                  Boolean(courseProgram) &&
                  courseProgram?.totalChapterCount === courseProgram?.totalCompletedChapterCount
                }
              />
            </ProgramsContainer>
          </div>
        ) : (
          <Loaders width="312px" height="252px" margin="0 0.75rem 1rem 0" number={10} />
        )}
      </PageWrapper>
    </BasicLayout>
  );
};

const ProgramsContainer = styled.div`
  margin-top: 64px;

  ${media.lessThan('medium')`
    & > * {
      margin-bottom: 32px;
      margin-top: 0;
    }
  `}
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  ${media.lessThan('medium')`
    display: none;
  `}
`;
