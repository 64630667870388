import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { NotificationCard } from './NotificationCard';
import {
  useGetNotificationsQuery,
  useReadAllNotificationsMutation,
  useReadNotificationsMutation,
} from '../../gen/graphql';
import { Loader } from '../molecules/Loader';
import { Spinner } from '../atoms/Spinner';
import { FETCH_LIMIT_MIDDLE } from '../../const/Page';
import { useToastsContext } from '../../context/ToastsProvider';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { defaultErrorMessage } from '../../const/ErrorMessage';

interface Props {
  refetchNotificationUnreadCount: () => Promise<void>;
}

export const Notification: React.FC<Props> = ({ refetchNotificationUnreadCount }) => {
  const {
    data,
    error,
    loading,
    refetch: refetchNotifications,
    fetchMore,
  } = useGetNotificationsQuery({
    variables: { first: FETCH_LIMIT_MIDDLE },
  });
  const [readNotification] = useReadNotificationsMutation();

  const pageInfo = data?.getNotifications.pageInfo;

  const intersectionRef = useRef<HTMLDivElement | null>(null);

  const [refetching, setRefetching] = useState(false);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [reading, setReading] = useState(false);

  const [readAllNotificationsMutation] = useReadAllNotificationsMutation();
  const { showToast } = useToastsContext();

  const handleClick = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      try {
        await readAllNotificationsMutation();
        refetchNotificationUnreadCount();
        refetchNotifications();
      } catch (e) {
        showToast(1, defaultErrorMessage);
      }
    }, [
      readAllNotificationsMutation,
      showToast,
      refetchNotificationUnreadCount,
      refetchNotifications,
    ]),
  );

  const handleRefetchNotifications = useCallback(async (): Promise<void> => {
    setRefetching(true);
    try {
      await refetchNotifications();
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setRefetching(false);
    }
  }, [refetchNotifications]);

  const fetchMoreNotifications = useCallback(async (): Promise<void> => {
    if (!pageInfo?.hasNextPage) {
      return;
    }

    setFetchingMore(true);
    try {
      await fetchMore({
        variables: {
          after: pageInfo?.endCursor,
        },
      });
      await readNotification({
        variables: {
          first: FETCH_LIMIT_MIDDLE,
          after: pageInfo?.endCursor,
        },
      });
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setFetchingMore(false);
    }
  }, [pageInfo, fetchMore, readNotification]);

  const readNotifications = useCallback(async (): Promise<void> => {
    setReading(true);
    try {
      await readNotification({
        variables: {
          first: FETCH_LIMIT_MIDDLE,
        },
      });
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setReading(false);
    }
  }, [readNotification]);

  useEffect(() => {
    readNotifications();
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        fetchMoreNotifications();
      }
    });
    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }
    return () => {
      if (intersectionRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(intersectionRef.current);
      }
    };
  }, [fetchMoreNotifications, readNotifications]);

  if (error) {
    return (
      <Err>
        <h4>お知らせの読み込みが行われませんでした</h4>
        <p>回線状況を確認して再読み込みを行ってください</p>
        <p className="reload" onClick={handleRefetchNotifications}>
          再読み込み
        </p>
      </Err>
    );
  }
  return (
    <>
      <Loader display={loading || refetching || reading} />
      <Container>
        {data?.getNotifications.items.length === 0 ? (
          <Err>
            <h4>現在お知らせに表示させる情報がありません</h4>
            <p>学習ログへのコメントや質問に回答があるとこちらにお知らせが表示されます</p>
          </Err>
        ) : (
          <ReadAllBlock>
            <ReadAllTypography onClick={() => handleClick()}>すべて既読にする</ReadAllTypography>
          </ReadAllBlock>
        )}
        {data?.getNotifications.items.map((notification) => (
          <NotificationCard key={notification.id} notification={notification} />
        ))}
        {pageInfo?.hasNextPage && (
          <Intersection ref={intersectionRef}>
            {fetchingMore && <Spinner size="small" />}
          </Intersection>
        )}
      </Container>
    </>
  );
};

const Container = styled.section`
  position: relative;
  max-width: 51.5rem;
  background-color: #ffffff;

  & > * + * {
    margin-top: 1rem;
  }
`;

const Err = styled.div`
  padding: 48px 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  h4 {
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
  }

  p {
    margin-top: 6px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
  }

  .reload {
    background-color: #eb0000;
    display: flex;
    width: 160px;
    height: 43px;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    cursor: pointer;
    color: #ffffff;
    font-size: 0.9rem;
    font-weight: bold;
    margin: 0 auto;
    margin-top: 21px;
  }
`;

const Intersection = styled.div`
  width: 100%;
  height: 2.5rem;
`;

const ReadAllBlock = styled.div`
  text-align: right;
  margin-bottom: 1.2rem;
`;

const ReadAllTypography = styled.span`
  cursor: pointer;
  color: #e2001b;
  font-size: 0.875rem;
  font-weight: 700;
`;
