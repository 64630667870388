import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useLocation } from 'react-router-dom';

import { PracticeTabLayout } from '../../templates/PracticeTabLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import { PracticeSubmitsList } from '../../organisms/PracticeSubmitsList';
import { PracticeSubmitFilter } from '../../organisms/PracticeSubmitFilter';

import {
  PracticeSubmitSortKey,
  ProgramSearchType,
  ProgramType,
  useGetPracticeSubmitsForInstructorLazyQuery,
  useGetPracticeSubmitsLazyQuery,
  useSearchProgramsQuery,
} from '../../../gen/graphql';
import { useUser } from '../../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { FIRST_PAGE, FETCH_LIMIT_HIGH, FETCH_LIMIT_LOW } from '../../../const/Page';
import { useURL } from '../../../common/customHooks/URL';
import { PracticeSubmitFilterModal } from '../../organisms/PracticeSubmitFilterModal';
import { getPractices } from '../../../utils/Program';
import {
  getInstructorSearchType,
  getReviewed,
  getReviewStatus,
  getSearchType,
  getSortKey,
} from '../../../utils/PracticeSubmit';
import { ALL, INITIAL } from '../../../const/Filter';
import { Loader } from '../../molecules/Loader';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';

export const PracticeSubmits: React.FC = (): JSX.Element => {
  const location = useLocation();
  const pathType = useMemo(() => location.pathname.split('/').slice(-1)[0], [location.pathname]);
  const [presentType, setPresentType] = useState('');

  const { permissionCheck } = useUser();
  const permissionProgramForInstructorAndCoachRead = useMemo(
    () => permissionCheck(FunctionType.ProgramForInstructorAndCoach, PermissionType.Read),
    [permissionCheck],
  );
  const permissionPracticeForInstructorAndCoachRead = useMemo(
    () => permissionCheck(FunctionType.PracticeForInstructorAndCoach, PermissionType.Read),
    [permissionCheck],
  );

  const { setParams, queries } = useURL();
  const sortKey = useMemo(
    () => queries?.sort_key ?? PracticeSubmitSortKey.CreatedAt,
    [queries?.sort_key],
  );
  const reviewed = useMemo(
    () => (queries?.reviewed ? Number(queries.reviewed) : ALL),
    [queries?.reviewed],
  );
  const reviewStatus = useMemo(
    () => queries?.review_status ?? ALL.toString(),
    [queries?.review_status],
  );
  const programID = useMemo(
    () => (queries?.program_id ? Number(queries.program_id) : INITIAL),
    [queries?.program_id],
  );
  const practiceID = useMemo(
    () => (queries?.practice_id ? queries.practice_id : INITIAL.toString()),
    [queries?.practice_id],
  );
  const page = useMemo(() => (queries?.page ? Number(queries.page) : FIRST_PAGE), [queries?.page]);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const { data: programsData, loading: programsLoading } = useSearchProgramsQuery({
    variables: {
      input: {
        programSearchType: ProgramSearchType.All,
        type: permissionProgramForInstructorAndCoachRead ? ProgramType.Normal : undefined,
        page: FIRST_PAGE,
        limit: FETCH_LIMIT_HIGH,
      },
    },
  });
  const programs = useMemo(
    () => programsData?.programs.items ?? [],
    [programsData?.programs.items],
  );

  const query = useMemo(() => {
    return {
      sortKey: getSortKey(sortKey),
      reviewed: getReviewed(reviewed),
      reviewStatus: getReviewStatus(reviewStatus),
      programID: programID !== INITIAL ? programID : undefined,
      practiceID: practiceID !== INITIAL.toString() ? practiceID : undefined,
      page: page,
      limit: FETCH_LIMIT_LOW,
    };
  }, [sortKey, reviewed, reviewStatus, programID, practiceID, page]);
  const [getPracticeSubmits, { data: practiceSubmitsData, loading: practiceSubmitsLoading }] =
    useGetPracticeSubmitsLazyQuery();
  const [
    getPracticeSubmitsForInstructor,
    { data: practiceSubmitsForInstructorData, loading: practiceSubmitsForInstructorLoading },
  ] = useGetPracticeSubmitsForInstructorLazyQuery();
  const practiceSubmits = useMemo(
    () =>
      practiceSubmitsData?.getPracticeSubmits.items ??
      practiceSubmitsForInstructorData?.getPracticeSubmitsForInstructor.items ??
      [],
    [
      practiceSubmitsData?.getPracticeSubmits.items,
      practiceSubmitsForInstructorData?.getPracticeSubmitsForInstructor.items,
    ],
  );
  const total = useMemo(
    () =>
      practiceSubmitsData?.getPracticeSubmits.total ??
      practiceSubmitsForInstructorData?.getPracticeSubmitsForInstructor.total ??
      0,
    [
      practiceSubmitsData?.getPracticeSubmits.total,
      practiceSubmitsForInstructorData?.getPracticeSubmitsForInstructor.total,
    ],
  );

  const fetchPracticeSubmits = useCallback(() => {
    getPracticeSubmits({
      variables: {
        input: {
          type: getSearchType(pathType),
          ...query,
        },
      },
    });
  }, [getPracticeSubmits, pathType, query]);
  const fetchPracticeSubmitsForInstructor = useCallback(() => {
    getPracticeSubmitsForInstructor({
      variables: {
        input: {
          type: getInstructorSearchType(pathType),
          ...query,
        },
      },
    });
  }, [getPracticeSubmitsForInstructor, pathType, query]);

  const handleSortKeySelect = useCallback(
    (value: string) => {
      // 検索条件変更時はpageをリセット
      setParams([{ name: 'sort_key', value: value }, { name: 'page' }]);
    },
    [setParams],
  );

  const handleReviewedSelect = useCallback(
    (value: string) => {
      // 検索条件変更時はpageをリセット
      setParams([{ name: 'reviewed', value: value }, { name: 'page' }]);
    },
    [setParams],
  );

  const handleReviewStatusSelect = useCallback(
    (value: string) => {
      // 検索条件変更時はpageをリセット
      setParams([{ name: 'review_status', value: value }, { name: 'page' }]);
    },
    [setParams],
  );

  const handleProgramIDSelect = useCallback(
    (value: string) => {
      // 検索条件変更時はpageをリセット
      setParams([{ name: 'program_id', value: value }, { name: 'practice_id' }, { name: 'page' }]);
    },
    [setParams],
  );

  const handlePracticeIDSelect = useCallback(
    (value: string) => {
      // 検索条件変更時はpageをリセット
      setParams([{ name: 'practice_id', value: value }, { name: 'page' }]);
    },
    [setParams],
  );

  const filterProps = useMemo(
    () => ({
      title: '絞り込み',
      displaySortKey: {
        sortKey: sortKey,
        handleSortKeySelect,
      },
      displayReviewed: {
        reviewed: reviewed,
        handleReviewedSelect,
      },
      displayReviewStatus: {
        reviewStatus: reviewStatus,
        handleReviewStatusSelect,
      },
      displayProgram: {
        programs: programs,
        programID,
        handleProgramIDSelect,
      },
      displayPractice: {
        practices: programID ? (getPractices(programs.find((i) => i.id === programID)) ?? []) : [],
        practiceID,
        handlePracticeIDSelect,
      },
    }),
    [
      handlePracticeIDSelect,
      handleProgramIDSelect,
      handleReviewStatusSelect,
      handleReviewedSelect,
      handleSortKeySelect,
      practiceID,
      programID,
      programs,
      reviewStatus,
      reviewed,
      sortKey,
    ],
  );

  const showLoader = useMemo(
    () => programsLoading || practiceSubmitsLoading || practiceSubmitsForInstructorLoading,
    [practiceSubmitsForInstructorLoading, practiceSubmitsLoading, programsLoading],
  );

  useEffect((): void => {
    if (permissionPracticeForInstructorAndCoachRead) return;
    // 初回表示、または、別タブからの遷移の場合、取得しないでtypeを設定
    if (presentType === '' || (presentType !== '' && pathType !== presentType)) {
      setPresentType(pathType);
      return;
    }
    fetchPracticeSubmits();
  }, [fetchPracticeSubmits, pathType, permissionPracticeForInstructorAndCoachRead, presentType]);

  useEffect((): void => {
    if (!permissionPracticeForInstructorAndCoachRead) return;
    // 初回表示、または、別タブからの遷移の場合、取得しないでtypeを設定
    if (presentType === '' || (presentType !== '' && pathType !== presentType)) {
      setPresentType(pathType);
      return;
    }
    fetchPracticeSubmitsForInstructor();
  }, [
    fetchPracticeSubmitsForInstructor,
    pathType,
    permissionPracticeForInstructorAndCoachRead,
    presentType,
  ]);

  const metaTitle = useMemo(() => {
    switch (pathType) {
      case 'all':
        return `すべての提出物 | ${LOWER_META_TITLE}`;
      case 'my_students':
        return `担当受講生の提出物 | ${LOWER_META_TITLE}`;
      case 'reviewed':
        return `レビューした提出物 | ${LOWER_META_TITLE}`;
      case 'mine':
        return `あなたの提出物 | ${LOWER_META_TITLE}`;
      case 'everyone':
        return `みんなの提出物 | ${LOWER_META_TITLE}`;
      case 'my_draft':
        return `下書き | ${LOWER_META_TITLE}`;
      default:
        return `課題 | ${LOWER_META_TITLE}`;
    }
  }, [pathType]);
  const metaDescription = `${SERVICE_NAME}の課題ページです。課題にチャレンジすることでアウトプット力が鍛えられスキルを定着させることができます。課題を単純に解くだけではなく、提出&レビューを受けることでより知識が磨かれます。`;

  return (
    <>
      <Loader display={showLoader} />
      {isFilterOpen && (
        <PracticeSubmitFilterModal
          sortKey={sortKey}
          reviewed={reviewed}
          reviewStatus={reviewStatus}
          programs={programs}
          programID={programID}
          practiceID={practiceID}
          isOpen={isFilterOpen}
          isCloseModal={() => setIsFilterOpen(false)}
        />
      )}
      <PracticeTabLayout
        activeTab={pathType}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
      >
        <PageWrapper>
          <Container>
            <MainContent>
              {practiceSubmits.length !== 0 ? (
                <PracticeSubmitsList
                  practiceSubmits={practiceSubmits}
                  total={total}
                  isOpenModal={() => setIsFilterOpen(true)}
                />
              ) : (
                <EmptyBlock title="提出された課題はありません">
                  {pathType === 'my_draft'
                    ? '下書きとして保存した課題がこちらに表示されます。'
                    : ''}
                </EmptyBlock>
              )}
            </MainContent>
            <SideContent>
              <PracticeSubmitFilter {...filterProps} />
            </SideContent>
          </Container>
        </PageWrapper>
      </PracticeTabLayout>
    </>
  );
};

const Container = styled.div`
  display: flex;
  gap: 1.25rem;

  ${media.lessThan('medium')`
    display: block;
  `}
`;
const MainContent = styled.div`
  flex: 1;
`;
const SideContent = styled.div`
  width: 284px;

  ${media.lessThan('medium')`
    display: none;
  `}
`;
