import { PageWrapper } from '../../atoms/PageWrapper';
import { TeamSettingTabLayout } from '../../templates/TeamSettingTabLayout';
import { TeamSettingBreadcrumb } from '../../templates/TeamSettingBreadcrumb';
import { LOWER_META_TITLE } from '../../../const/Service';
import { InvoiceWrapperTeam } from '../../organisms/InvoiceWrapperTeam';

export const TeamInvoice: React.FC = () => {
  const metaTitle = `請求情報 | ${LOWER_META_TITLE}`;

  return (
    <>
      <TeamSettingTabLayout activeTab="invoice" metaTitle={metaTitle}>
        <PageWrapper>
          <TeamSettingBreadcrumb pageName="請求情報" />
          <InvoiceWrapperTeam />
        </PageWrapper>
      </TeamSettingTabLayout>
    </>
  );
};
