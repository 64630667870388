import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Stripe, StripeElements } from '@stripe/stripe-js';

import { Button } from '../atoms/Button';
import { PaymentMethods } from '../molecules/PaymentMethods';
import { PromotionCodeField, PromotionCodeInput } from '../molecules/PromotionCodeInput';
import { CANCELLATION_FAQ_LINK } from '../../const/Link';
import { GetPriceQuery, StripePaymentMethodFragment } from '../../gen/graphql';

interface SubscriptionPaymentFormProps {
  paymentMethods: StripePaymentMethodFragment[];
  currentPaymentMethod: StripePaymentMethodFragment | null;
  setShowLoader: (loading: boolean) => void;
  fetchPaymentMethods: () => Promise<void>;
  setStripe: (stripe: Stripe | null) => void;
  setElements: (elements: StripeElements | null) => void;
  setPaymentMethodID: (id: string | null) => void;
  selectedPayment: (item: StripePaymentMethodFragment) => boolean;
  isPaymentMethodSelected: boolean;
  setIsCheckedFrontValidation: (checked: boolean) => void;
  price?: GetPriceQuery['getPrice'];
  submit: () => void;
  canSubmit: () => boolean;
  promotionCodeField: PromotionCodeField;
}

export const SubscriptionPaymentForm: React.FC<SubscriptionPaymentFormProps> = ({
  paymentMethods,
  currentPaymentMethod,
  setShowLoader,
  fetchPaymentMethods,
  setStripe,
  setElements,
  setPaymentMethodID,
  selectedPayment,
  isPaymentMethodSelected,
  setIsCheckedFrontValidation,
  price,
  submit,
  canSubmit,
  promotionCodeField,
}) => {
  return (
    <>
      <ContentTitle>支払い方法の変更</ContentTitle>
      <PaymentMethods
        cards={paymentMethods}
        currentDefaultPaymentId={currentPaymentMethod?.id ?? ''}
        setLoading={setShowLoader}
        fetchPayments={fetchPaymentMethods}
        setStripe={setStripe}
        setElements={setElements}
        setPaymentID={setPaymentMethodID}
        selectedPayment={selectedPayment}
        isPaymentMethodSelected={isPaymentMethodSelected}
        setIsCheckedFrontValidation={setIsCheckedFrontValidation}
      />

      <ContentTitle>クーポンコード</ContentTitle>
      <PromotionCodeInput {...promotionCodeField} hiddenLabel />

      <SubscriptionNote>
        サブスクリプションは{format(new Date(), 'yyyy/M/d')}に開始され
        {price?.product?.productExtension.contractPeriod}ヶ
        {price?.product?.productExtension.contractPeriodTypeLabel}後に自動で更新されます。
        <br />
        自動更新は更新{price?.product?.productExtension.contractPeriodTypeLabel}
        にアカウント設定より無料でキャンセル可能です。
        {price?.product?.productExtension.isMultiMonthContract && (
          <>
            <br />
            更新期間外のキャンセルでは解約手数料が発生しますのでご注意ください。
          </>
        )}
      </SubscriptionNote>
      <LegalNote>
        ※「特定商取引法に基づく表記」は
        <a href="https://www.sejuku.net/corp/legal" target="_blank" rel="noopener">
          こちら
        </a>
        をご覧ください。
        <br />※ 支払時期については
        <a href="https://www.sejuku.net/corp/terakoya-agreement" target="_blank" rel="noopener">
          利用規約
        </a>
        をご覧ください。
        <br />※ 解約方法については
        <a href={CANCELLATION_FAQ_LINK} target="_blank" rel="noopener">
          こちら
        </a>
        をご覧ください。
      </LegalNote>

      <StyledSubmitButton onClick={submit} disabled={!canSubmit()}>
        決済を完了する
      </StyledSubmitButton>
    </>
  );
};

const ContentTitle = styled.h3`
  margin: 1.5rem auto 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
`;

const SubscriptionNote = styled.p`
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  line-height: 1.6;
`;

const LegalNote = styled.p`
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.75rem;
  line-height: 1.5;

  a {
    color: #fd3c2f;
    font-size: 1em;
  }
`;

const StyledSubmitButton = styled(Button)`
  margin-top: 2rem;
`;
