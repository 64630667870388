import styled from 'styled-components';
import media from 'styled-media-query';

import { Button } from '../../../atoms/lp/trial/Button';

import { SERVICE_NAME } from '../../../../const/Service';

import ImageBgPc from '../../../../static/image/lp/fv_bg_pc.png';
import ImageBgSp from '../../../../static/image/lp/fv_bg_sp.png';

import FvPcVideo from '../../../../static/movie/lp_fv_pc.mp4';

import ImageSlideSp1 from '../../../../static/image/lp/fv_slide_sp1.png';
import ImageSlideSp2 from '../../../../static/image/lp/fv_slide_sp2.png';
import ImageSlideSp3 from '../../../../static/image/lp/fv_slide_sp3.png';

interface Props {
  onClickCV: () => void;
}

export const FvContent: React.FC<Props> = (props) => {
  return (
    <Section data-gtm="fv">
      <Wrapper>
        <SlideContainer>
          <SlideWrapper>
            <Slide url={ImageSlideSp1} />
            <Slide url={ImageSlideSp2} />
            <Slide url={ImageSlideSp3} />
          </SlideWrapper>
        </SlideContainer>
        <TopWrapper>
          <Inner>
            <TitleWrapper>
              <SubTitle>
                <span className="highlight">仕事につながる</span>
                <br />
                <span className="normal">プログラミングスキル習得の第一歩を</span>
              </SubTitle>
              <Title>{SERVICE_NAME}</Title>
            </TitleWrapper>
            <PcButtonArea>
              <Button onClick={props.onClickCV} className="cvButton" />
            </PcButtonArea>
          </Inner>
          <Video loop muted autoPlay playsInline>
            <source src={FvPcVideo} type="video/mp4" />
          </Video>
        </TopWrapper>
        <Reason>
          <ReasonColumn>
            <h2>業界最安級</h2>
            <p>
              <span>2,980</span>
              <span className="small">円から</span>
            </p>
          </ReasonColumn>
          <ReasonColumn>
            <h2>講師への満足度</h2>
            <p>
              <span>95</span>
              <small>%</small>
            </p>
          </ReasonColumn>
          <ReasonColumn>
            <h2>登録者数</h2>
            <p>
              <small>累計</small>
              <span>25,000</span>
              <small>人を突破</small>
            </p>
          </ReasonColumn>
        </Reason>
        <SpButtonArea>
          <Button onClick={props.onClickCV} className="cvButton" />
        </SpButtonArea>
      </Wrapper>
    </Section>
  );
};

const Section = styled.section`
  height: 640px;
  background-color: #fff;
  padding: 3rem 0 0;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  background: url(${ImageBgPc}) center top / contain no-repeat;
  background-size: contain;
  background-color: #fff0f0;

  ${media.lessThan('medium')`
    height: auto;
    margin-top: 57px;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
    background-image: none;
  `}
`;
const Wrapper = styled.div`
  max-width: 85.25rem;
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;

  .sp {
    display: none;

    ${media.greaterThan('medium')`
      display: block;
    `}
  }
`;

const SlideContainer = styled.div`
  display: none;
  height: 300px;
  margin: 2rem auto;
  overflow: hidden;
  position: relative;
  background: url(${ImageBgSp});
  background-size: cover;

  ${media.lessThan('medium')`
    margin: 0 auto;
    display: block;
  `};
`;

const SlideWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  animation: slide-animation 15s infinite;
  @keyframes slide-animation {
    0% {
      transform: translateX(0%);
    }
    33.33% {
      transform: translateX(0%);
    }
    40% {
      transform: translateX(-100%);
    }
    73.33% {
      transform: translateX(-100%);
    }
    80% {
      transform: translateX(-200%);
    }
    100% {
      transform: translateX(-200%);
    }
  }
`;

const Slide = styled.div<{ url: string }>`
  flex: 0 0 calc(100% - 1rem);
  margin: 0 0.5rem;
  background: url(${(props) => props.url}) center center / contain no-repeat;
`;

const Video = styled.video`
  min-width: 0px;
  position: absolute;
  right: -4rem;
  top: -3rem;
  ${media.lessThan('medium')`
    display: none;
  `}
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 460px;
  position: relative;
  margin: 0 2rem;

  ${media.lessThan('medium')`
    height: 200px;
  `}
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  z-index: 1;
  gap: 2rem;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
const TitleWrapper = styled.div`
  text-align: center;
  font-family: 'Murecho', sans-serif;
`;
const SubTitle = styled.div`
  text-align: left;
  font-weight: 700;
  min-width: 600px;

  .normal {
    max-width: 500px;
    color: #122b46;
    font-size: 2em;
    line-height: 3rem;
  }

  br {
    display: none;
  }

  .highlight {
    display: block;
    color: #eb0000;
    font-size: 2.25rem;
  }

  ${media.lessThan('medium')`
    text-align: center;
    .normal {
      max-width: none;
      font-size: 1.125rem;
      line-height: 2.25rem;
    }
      
    .highlight {
      font-size: 2rem;
    }
  `}
`;
const Title = styled.h1`
  color: #122b46;
  position: relative;
  margin: 1rem 0 0 0;
  display: block;
  margin-top: 0.25rem;
  color: #eb283f;
  font-size: 6.75rem;
  text-align: left;
  font-weight: 900;
  letter-spacing: 10px;

  ${media.lessThan('medium')`
    letter-spacing: .36px;
    text-align: center;
    margin-top: .75rem;
    font-size: 4rem;
    line-height: 1;
  `}
`;
const Reason = styled.div`
  display: flex;
  gap: 0.75rem;
  margin: 2rem auto 0;
  width: 864px;
  height: 104px;

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: .5rem;
    margin-top: 0;
    width: auto;
    height: auto;
  `}
`;
const ReasonColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 1.125rem 0;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 1rem;
  filter: drop-shadow(0.0625rem 0.25rem 0.75rem rgba(111, 35, 35, 0.15));
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;

  h2 {
    margin: 0 auto 0 2rem;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.125rem;
  }

  p {
    margin: 0 auto;
    color: #eb0000;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.625rem;

    &.small {
      font-size: 1rem;
      line-height: 1;
    }

    span {
      font-size: 2.25rem;
      font-weight: 700;
      line-height: 1;
    }
  }

  ${media.lessThan('medium')`
    align-items: baseline;
    margin: 0 1rem;
    gap: .125rem;

    h2 {
      margin: 0 0 0 1rem;
      font-size: 1.125rem;
    }

    p {
      font-weight: 700;
      line-height: 1.5;

      span {
        font-size: 3rem;
      }
    }

    .small {
      font-size: 1rem;
      text-align: right;
    }
  `}
`;
const PcButtonArea = styled.div`
  width: 340px;
  display: flex;
  justify-content: center;

  .cvButton {
    padding: 0.75rem 2.5rem;
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`;

const SpButtonArea = styled.div`
  display: none;

  ${media.lessThan('medium')`
    max-width: 100%;
    margin: 0 1rem;
    display: block;
    margin-top: 2rem;

    .cvButton {
      padding: 0.75rem 0;
      width: 100%;
      max-width: none;

      .small {
        font-size: 1rem;
        line-height: 1.75;
      }

      .large {
        font-size: 1.5rem;
      }
    }
  `}
`;
