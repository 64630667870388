import React, { useContext, useEffect, useReducer, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link, useNavigate } from 'react-router-dom';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { ModalContext } from '../../../context/ModalProvider';

import { BasicLayout } from '../../templates/BasicLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { FormHeadline } from '../../atoms/FormHeadline';
import { LearnedContent, LearnedContentHandler } from '../../organisms/LearnedContent';
import { TextArea, TextAreaHandler } from '../../atoms/TextArea';
import { Button } from '../../atoms/Button';
import { Breadcrumb } from '../../organisms/Breadcrumb';
import { StudyReportCreatedModal } from '../../organisms/StudyReportCreatedModal';
import { getStudyReportContentError } from '../../../utils/StudyReport';

import TrashIcon from '../../../static/image/icon_trash.svg';
import { StudyReport } from '../../../common/Const';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';

import { ErrorCode, useCreateStudyReportMutation } from '../../../gen/graphql';
import { getApiErrorMessage, getGraphQLErrorCodes } from '../../../utils/graphqlError';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';
import { ApolloError } from '@apollo/client';
import { useToastsContext } from '../../../context/ToastsProvider';

enum LearnedContentsActionType {
  PUSH = 'PUSH',
  SPLICE = 'SPLICE',
}

interface LearnedContentsAction {
  type: LearnedContentsActionType;
  id?: number;
}

interface LearnedContentsItem {
  id: number;
  ref: React.RefObject<LearnedContentHandler>;
}

export const CreateStudyReport: React.FC = () => {
  const metaTitle = `学習ログ投稿 | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}の学習ログページです。あなたの学習状況を記録して勉強の習慣化を促進します。受講生一人ひとりの学習時間や学習されているプログラミング言語をランキング形式で見れる他、気になるログには「コメント」や「いいね」でリアクションできます。`;

  const navigate = useNavigate();
  const modalContext = useContext(ModalContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentID, setCurrentID] = useState(0);
  const contentRef = React.useRef<TextAreaHandler>(null);
  const [contentError, setContentError] = useState<string>('');
  const [learnedContentError, setLearnedContentError] = useState<string>('');
  const [createStudyReport] = useCreateStudyReportMutation();
  const { showToast } = useToastsContext();

  const [learnedContents, dispatch] = useReducer(
    (state: LearnedContentsItem[], action: LearnedContentsAction) => {
      switch (action.type) {
        case LearnedContentsActionType.PUSH:
          if (state.length >= 5) return state;
          setCurrentID(currentID + 1);
          return [...state, { id: currentID, ref: React.createRef<LearnedContentHandler>() }];
        case LearnedContentsActionType.SPLICE:
          return state.filter((learnedContent) => learnedContent.id !== action.id);
        default:
          return state;
      }
    },
    [],
  );

  const getTweetContent = () => {
    // const studyDetailText = studyReportDetails?.reduce((acc: any, cur: any) => acc + `${cur.tag?.name}: ${cur.time >= 60 ? `${Math.floor(cur.time / 60)}時間${cur.time % 60}分` : `${cur.time}分`}\n`, '');
    // const totalStudyCountText = summary?.studyReportCount ? `合計登録回数: ${summary?.studyReportCount}回\n` : '';
    // const totalStudyTimeText = summary?.studyReportTime ? `合計学習時間: ${Math.floor(summary?.studyReportTime / 60)}時間${summary?.studyReportTime % 60}分\n` : '';
    const tweetContent = contentRef.current?.getValue();

    return tweetContent ? tweetContent : '';
  };

  const validate = () => {
    const isLearnedContentsFilled = learnedContents.every((learnedContent) =>
      learnedContent.ref.current?.validate(),
    );

    const totalLearningTime = learnedContents.reduce(
      (sum, lc) => sum + Number(lc.ref.current?.getStudyTime()),
      0,
    );
    const validTotalLearningTime = totalLearningTime <= StudyReport.MAX_TIME;
    const totalLearningTimeErrorMessage = validTotalLearningTime
      ? ''
      : '合計時間が24時間を超えています';

    setLearnedContentError(totalLearningTimeErrorMessage);
    const contentError = getStudyReportContentError(contentRef.current?.getValue());
    setContentError(contentError);

    return isLearnedContentsFilled && !contentError && validTotalLearningTime;
  };

  const submit = useSafeAsyncCallback(async () => {
    if (!validate()) return;

    const content = contentRef.current?.getValue();
    const studyReportDetails = learnedContents.map((learnedContent) => {
      const content = {
        time: learnedContent.ref.current?.getStudyTime(),
        tag: learnedContent.ref.current?.getTag(),
        program: learnedContent.ref.current?.getProgram(),
      };

      if (!content.time || !content.tag) return null;

      return {
        time: parseInt(content.time),
        tagID: content.tag.id,
        programID: content.program ? content.program.id : null,
      };
    });

    if (!content) return;

    try {
      await createStudyReport({
        variables: {
          input: {
            date: selectedDate.toISOString(),
            content: content,
            studyReportDetails: studyReportDetails,
          },
        },
        onCompleted: () => {
          const tweetContent = getTweetContent();
          modalContext.setContent(
            <StudyReportCreatedModal tweetContent={tweetContent ? tweetContent : ''} />,
          );
          modalContext.open();
          navigate('/study_report/my');
        },
      });
    } catch (e) {
      if (e instanceof ApolloError && getGraphQLErrorCodes(e).includes(ErrorCode.InvalidTag)) {
        showToast(1, getApiErrorMessage(e));
        return;
      }
      setContentError(getApiErrorMessage(e));
    }
  });

  useEffect(() => {
    dispatch({ type: LearnedContentsActionType.PUSH });
    contentRef.current?.setValue(StudyReport.DEFAULT_VALUE);
  }, []);

  const breadcrumbs = [
    {
      label: 'ホーム',
      to: '/home',
    },
    {
      label: '自分の学習ログ',
      to: '/study_report/my',
    },
    {
      label: '投稿',
    },
  ];

  return (
    <BasicLayout pageTitle="学習ログ" metaTitle={metaTitle} metaDescription={metaDescription}>
      <PageWrapper>
        <StyledBreadcrumb links={breadcrumbs} />
        <Container>
          <Section>
            <StyledHeadline>学習日</StyledHeadline>
            <FormContent>
              <DatePicker
                selected={selectedDate}
                onChange={(d: Date) => setSelectedDate(d)}
                dateFormat="yyyy年MM月dd日"
                minDate={new Date(2000, 0, 1)}
                disabledKeyboardNavigation
                placeholderText="選択してください"
                className="input datepicker-date"
                maxDate={new Date()}
              />
            </FormContent>
          </Section>
          <Section>
            <StyledHeadline>学習内容</StyledHeadline>
            <FormContent>
              {learnedContents.map((learnedContent) => {
                return (
                  <Row key={learnedContent.id}>
                    <LearnedContent ref={learnedContent.ref} />
                    {learnedContents.length >= 2 ? (
                      <Icon
                        onClick={() =>
                          dispatch({
                            type: LearnedContentsActionType.SPLICE,
                            id: learnedContent.id,
                          })
                        }
                        src={TrashIcon}
                        alt="ゴミ箱"
                      />
                    ) : (
                      ''
                    )}
                  </Row>
                );
              })}
              {learnedContents.length < StudyReport.LEARNED_CONTENT_MAX_NUMBER_OF_ITEM ? (
                <Add onClick={() => dispatch({ type: LearnedContentsActionType.PUSH })}>
                  + 内容を追加する
                </Add>
              ) : (
                ''
              )}
              <ErrP>{learnedContentError}</ErrP>
            </FormContent>
          </Section>
          <Section>
            <StyledHeadline isRequired>ひとこと</StyledHeadline>
            <FormContent>
              <TextArea
                ref={contentRef}
                name="content"
                error={Boolean(contentError)}
                width="100%"
                height="139px"
                placeholder={
                  '学習した内容（つまずいたことやどのように解決したかなど）を記入しましょう'
                }
              />
              <ErrP>{contentError}</ErrP>
            </FormContent>
          </Section>
          <StyledButton onClick={submit}>投稿する</StyledButton>
          <Cancel to="/study_report/my">キャンセル</Cancel>
        </Container>
      </PageWrapper>
    </BasicLayout>
  );
};

const StyledBreadcrumb = styled(Breadcrumb)`
  max-width: 800px;
  margin: 0 auto 1.25rem;

  ${media.lessThan('medium')`
    margin-bottom: 1rem;
  `}
`;
const Container = styled.div`
  max-width: 50rem;
  min-height: 100%;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    margin: 0 -1rem;
    padding: 0 1rem;
    border: none;
  `}
`;

const Section = styled.section`
  display: flex;
  margin-bottom: 2rem;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;
const StyledHeadline = styled(FormHeadline)`
  width: 7.5rem;
  margin: 0;
  padding-top: 1rem;

  ${media.lessThan('medium')`
    width: 100%;
    margin-bottom: 1rem;
    padding: 0;
  `}
`;
const FormContent = styled.div`
  flex: 1;

  .datepicker-date {
    border: 1px solid #ddd;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding-right: 2.5rem;
  position: relative;

  &:nth-of-type(n + 2) {
    padding-top: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
`;
const Add = styled.p`
  color: #e2001b;
  font-weight: 600;
  cursor: pointer;
`;
const Icon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  ${media.lessThan('large')`
    margin-left: 1rem;
  `}
`;

const ErrP = styled.p`
  color: #f00;
  margin: 0.25rem 0 0.5rem;
`;

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
`;
const Cancel = styled(Link)`
  display: block;
  width: 5rem;
  margin: 1.5rem auto 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
`;
