import { Errors, Fields, FieldStatuses } from '../types/ClaimDetail';

export const defaultFields: Fields = {
  category: 'カテゴリーを選択してください ▼',
  student: undefined,
  lesson: '専属レッスンを選択してください ▼',
  spotLesson: '単発レッスンを選択してください ▼',
  amount: '',
  course: undefined,
  unitPrice: '',
  charaAmount: '',
  charaUnitPrice: '',
  additionalPayment: '',
  remark: '',
  perItemUnitPrice: '',
  perItemAmount: '',
};

export const defaultFieldStatuses: FieldStatuses = {
  hasTime: false,
  hasStudent: false,
  hasLesson: false,
  hasSpotLesson: false,
  hasAdditionalPayment: false,
  hasHourlyPayment: false,
  hasTimelinePayment: false,
  hasPerItemPayment: false,
  hasPerItem: false,
  remarkPlaceholder: '',
  remarkIsRequired: false,
  dateReadOnly: false,
  timeReadOnly: false,
};

export const defaultErrors: Errors = {
  category: '',
  student: '',
  lesson: '',
  spotLesson: '',
  amount: '',
  unitPrice: '',
  charaAmount: '',
  charaUnitPrice: '',
  additionalPayment: '',
  remark: '',
  perItemUnitPrice: '',
  perItemAmount: '',
};

export const hasErrors = (errors: Errors) => {
  return Object.values(errors).some((error) => error !== '');
};
