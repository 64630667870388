import { useEffect, useCallback } from 'react';

import { useGetInvoicesLazyQuery } from '../../gen/graphql';

import { useUser } from '../../redux/user/useUser';
import { InvoiceContainer } from './InvoiceContainer';
import { FETCH_LIMIT_LOW } from '../../const/Page';
import { FunctionType, PermissionType } from '../../const/UserPermission';

export const InvoiceWrapperPersonal: React.FC = () => {
  const { loading, invoices, hasPrevious, hasNext, fetchNextInvoices, fetchPreviousInvoices } =
    useInvoices();

  const { permissionCheck } = useUser();
  const showTeamInvoiceLink = permissionCheck(FunctionType.TeamInvoice, PermissionType.Read);

  return (
    <InvoiceContainer
      invoices={invoices}
      loading={loading}
      hasNext={hasNext}
      hasPrevious={hasPrevious}
      fetchNextInvoices={fetchNextInvoices}
      fetchPreviousInvoices={fetchPreviousInvoices}
      showTeamInvoiceLink={showTeamInvoiceLink}
    />
  );
};

const useInvoices = () => {
  const [getPersonalInvoices, { data, loading, refetch }] = useGetInvoicesLazyQuery();

  const invoices = data?.invoices;

  useEffect(() => {
    getPersonalInvoices({
      variables: {
        input: {
          limit: FETCH_LIMIT_LOW,
        },
      },
    });
  }, [getPersonalInvoices]);

  const nextCursor = invoices?.nextCursor ?? '';
  const previousCursor = invoices?.previousCursor ?? '';

  const fetchNextInvoices = useCallback(async () => {
    refetch({
      input: {
        nextCursor,
        limit: FETCH_LIMIT_LOW,
      },
    });
  }, [refetch, nextCursor]);

  const fetchPreviousInvoices = useCallback(async () => {
    refetch({
      input: {
        previousCursor,
        limit: FETCH_LIMIT_LOW,
      },
    });
  }, [refetch, previousCursor]);

  return {
    loading: loading,
    invoices: invoices?.items ?? [],
    hasPrevious: invoices?.hasPrevious ?? false,
    hasNext: invoices?.hasNext ?? false,
    fetchNextInvoices,
    fetchPreviousInvoices,
  };
};
