import React from 'react';
import * as Sentry from '@sentry/react';
import {
  ApolloClient,
  ApolloProvider,
  FieldPolicy,
  from,
  fromPromise,
  HttpLink,
  InMemoryCache,
  ServerError,
  ServerParseError,
  split,
} from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Auth } from 'aws-amplify';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition, relayStylePagination } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { GraphQLFormattedError } from 'graphql';
import { isErrorCode } from '../utils/graphqlError';
import { ErrorCode } from '../gen/graphql';
import { UserActionTypes } from '../redux/user/actions';
import { useDispatch } from 'react-redux';
import axios from 'axios';

// fetchMoreの結果を結合するための関数
const customPagination = (): FieldPolicy => ({
  keyArgs: [],
  merge(existing, incoming) {
    if (!incoming?.pageInfo?.hasPreviousPage && !incoming?.adminPageInfo?.hasPreviousPage) {
      return incoming;
    }
    return {
      ...incoming,
      items: [...existing.items, ...incoming.items],
    };
  },
});

// fetchMoreの結果を結合するための関数(チャットルーム用)
const chatRoomsCustomPagination = (): FieldPolicy => ({
  keyArgs: [],
  merge(existing, incoming) {
    if (!incoming?.pageInfo?.hasPreviousPage && !incoming?.adminPageInfo?.hasPreviousPage) {
      return incoming;
    }

    const items = [...existing.items, ...incoming.items];

    // チャットルームの一覧で重複を削除
    const resultItems = Array.from(new Map(items.map((item) => [item.__ref, item])).values());

    return {
      ...incoming,
      items: resultItems,
    };
  },
});

export const ApolloClientProvider: React.FC = ({ children }): JSX.Element => {
  const dispatch = useDispatch();

  // authorizationヘッダーの有無でエンドポイントを切り替える
  const directionalLink = new RetryLink({ attempts: { max: 1 } }).split(
    ({ getContext }) => Boolean(getContext().headers?.authorization),
    // 非公開(要認証)エンドポイント
    createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT }),
    // 公開エンドポイント
    // NOTE: 公開エンドポイントでもアップロード機能が必要になったらcreateUploadLinkに切り替える
    new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_PUBLIC_ENDPOINT }),
  );

  const authLink = setContext(async (_, { headers }) => {
    try {
      const cognitoUserSession = await Auth.currentSession();
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${cognitoUserSession.getIdToken().getJwtToken()}`,
        },
      };
    } catch (_) {
      // 未ログイン時はこっちに入ってくる
      return { headers };
    }
  });

  // 裏でユーザーが無効化されていた場合をハンドル。
  const invalidUserErrorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (shouldLogout(graphQLErrors)) {
      // useUserに実装されているlogoutと同等の処理をしたいがうまくいかないため、下記の処理とする。
      // Cookieとローカルストレージがゴミとして残るが、裏でユーザーが無効化されるのはレアケースのため許容する。
      // useCookies・useSelector・useNavigateを使うと原因不明の無限レンダリング・無限API呼び出しが発生したり、毎回無駄なレンダリングが発生したりしてしまう。
      dispatch({
        type: UserActionTypes.logout,
      });
      axios.defaults.headers.common['Authorization'] = '';
      Auth.signOut();

      // これをすると、無効化されたユーザーで再ログインしようとした際にエラーメッセージを表示せずにトップページに遷移してしまう。
      // location.href = '/';
      // 先ほどログアウトさせているので、現在の画面が未ログインでも表示できるものならそのまま表示され、そうでなければ403が表示されることになる。

      // 全てのエラーを削除する。
      return forward(operation).map((response) => {
        if (response.errors) {
          response.errors = undefined;
        }
        return response;
      });
    }

    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) {
      // 現状、認証エラー時はエラーオブジェクトではなくステータスコード401が返ってくるようになっている
      // networkErrorの型的にstatusCodeにはアクセスできないのでタイプアサーションで無理やりアクセスする
      const serverError = networkError as ServerError | ServerParseError;
      if (serverError.statusCode === 401) {
        return fromPromise(
          Auth.currentSession()
            .then((cognitoUserSession) => cognitoUserSession.getIdToken().getJwtToken())
            .catch(() => null), // 未ログイン(ログアウト後)にAuth.currentSessionを呼ぶとrejectされる
        )
          .filter((token) => Boolean(token))
          .flatMap((token) => {
            // Modify the operation context with a new token
            const oldHeaders = operation.getContext().headers;
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${token}`,
              },
            });
            // Retry the request, returning the new observable
            return forward(operation);
          });
      }
    }

    // Server側でgraphqlのエラーはSentryに送っているため、
    // 予期しないエラー（Extensionsが設定されてない）のみSentryに送る
    if (
      graphQLErrors &&
      (!graphQLErrors[0].extensions ||
        !Object.prototype.hasOwnProperty.call(graphQLErrors[0].extensions, 'code'))
    ) {
      // graphQLErrorsや配列の要素をそのままSentryに渡すと型が適合しなそう(※)なので、
      // メッセージを整形してErrorオブジェクトに詰め直す
      // ※ Sentry上で「unknown」や「Non-Error exception captured with keys: message, path」のようになってしまう
      const error = new Error(`${operation.operationName}:${JSON.stringify(graphQLErrors)}`);
      Sentry.captureException(error);
    }
  });

  const waitForHealthy = (): Promise<boolean> => {
    return new Promise((resolve) => {
      const client = createClient({
        url: String(process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_ENDPOINT),
        retryAttempts: 0, // fail immediately
        lazy: false, // connect as soon as the client is created
        on: {
          closed: () => resolve(false), // connection rejected, probably not supported
          connected: () => {
            resolve(true); // connected = supported
            client.dispose(); // dispose after check
          },
        },
      });
    });
  };

  let retryCount = 0;

  // @see https://www.apollographql.com/docs/react/api/link/apollo-link-subscriptions
  const wsLink = new GraphQLWsLink(
    // @see https://github.com/enisdenjo/graphql-ws/blob/master/docs/interfaces/client.ClientOptions.md
    createClient({
      url: String(process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_ENDPOINT),
      connectionParams: async () => {
        const cognitoUserSession = await Auth.currentSession();
        return {
          reconnect: true,
          authorization: `Bearer ${cognitoUserSession.getIdToken().getJwtToken()}`,
        };
      },
      disablePong: false,
      keepAlive: 120000,
      retryAttempts: Number(process.env.REACT_APP_WEBSOCKET_RETRY_COUNT),
      retryWait: async () => {
        await waitForHealthy();

        await new Promise((resolve) =>
          setTimeout(resolve, Number(process.env.REACT_APP_WEBSOCKET_RETRY_WAIT_TIME)),
        );
      },
      on: {
        connected: () => {
          retryCount = 0;
        },
        closed: () => {
          retryCount++;

          if (retryCount > Number(process.env.REACT_APP_WEBSOCKET_RETRY_COUNT)) {
            alert('回線状況が不安定な為、時間をおいてからリロードしてください');
          }
        },
      },
    }),
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    authLink.concat(directionalLink),
  );

  const client = new ApolloClient({
    link: from([errorLink, invalidUserErrorLink, splitLink]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
      },
    },
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          // 画面仕様的に無限スクロールが必要なqueryはここに追加(fetchMoreにおけるupdateQueryはdeprecatedなので、現時点ではこのやり方)
          // @see https://www.apollographql.com/blog/announcement/frontend/announcing-the-release-of-apollo-client-3-0/
          fields: {
            // タイムライン一覧
            getTimelinesV1: customPagination(),
            // マイページ投稿一覧
            getTimelinesByUserID: customPagination(),
            // お知らせ一覧
            getNotifications: customPagination(),
            // アナウンス一覧
            notices: customPagination(),
            // チャットルーム一覧
            chatRooms: chatRoomsCustomPagination(),
            // チャットメッセージ一覧
            chatMessages: relayStylePagination(),
          },
        },
      },
    }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export const shouldLogout: (
  graphQLErrorsState: ReadonlyArray<GraphQLFormattedError> | undefined,
) => boolean = (graphQLErrorsState) => {
  return (
    graphQLErrorsState?.some(
      (graphqlError) =>
        isErrorCode(graphqlError.extensions?.code) &&
        authErrorCodes.some((errorCode) => errorCode === graphqlError.extensions?.code),
    ) ?? false
  );
};

const authErrorCodes: ErrorCode[] = [
  ErrorCode.CommonInvalidUser,
  ErrorCode.CommonPublicUserIsDenied,
];
