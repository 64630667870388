import React from 'react';
import styled from 'styled-components';
import constate from 'constate';
import { Z_INDEX_TOAST } from '../const/ZIndex';
import useLocationChange from '../common/customHooks/LocationChange';

interface Toast {
  id: number;
  type: number;
  message: string;
  lifeTime: number;
}

const INFO_TOAST_DISPLAY_TIME = 4000;
const ERROR_TOAST_DISPLAY_TIME = 9999999999;

export const TOAST_TYPE_INFO = 0;
export const TOAST_TYPE_ERROR = 1;
export const TOAST_TYPE_ERROR_PERSISTENT = 2;
type ToastType =
  | typeof TOAST_TYPE_INFO
  | typeof TOAST_TYPE_ERROR
  | typeof TOAST_TYPE_ERROR_PERSISTENT;

const useToast = () => {
  const [toasts, setToasts] = React.useState<Toast[]>([]);

  const showToast = React.useCallback(
    (type: ToastType, message: string) => {
      const toast = {
        id: toasts.length,
        type,
        message,
        lifeTime: type === TOAST_TYPE_INFO ? INFO_TOAST_DISPLAY_TIME : ERROR_TOAST_DISPLAY_TIME,
      };

      const newToasts = toasts.filter(
        (oldToast) => oldToast.type !== type || oldToast.message !== message,
      );

      setToasts([toast, ...newToasts]);
    },
    [toasts],
  );

  return {
    toasts,
    setToasts,
    showToast,
  };
};

export const [ToastsProvider, useToastsContext] = constate(useToast);

export const ToastsContainer: React.FC = ({ children }) => {
  return (
    <ToastsProvider>
      {children}
      <Toasts />
    </ToastsProvider>
  );
};

interface ToastProps {
  id: number;
  type: number;
  lifeTime: number;
  message: string;
  onHide: (id: number) => void;
}

const Toast: React.FC<ToastProps> = ({ id, type, lifeTime, message, onHide }) => {
  useLocationChange(() => {
    if (type === TOAST_TYPE_ERROR) {
      onHide(id);
    }
  });

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    ref.current?.classList.add('show');

    const timeout = setTimeout(() => {
      onHide && onHide(id);
    }, lifeTime);

    return () => {
      clearTimeout(timeout);
    };
  }, [id, lifeTime, onHide, type]);

  return (
    <ToastContainerElement ref={ref} type={type}>
      <ToastElement>{message}</ToastElement>
      {(type === TOAST_TYPE_ERROR || type === TOAST_TYPE_ERROR_PERSISTENT) && (
        <ToastCloseArea onClick={() => onHide(id)}>×</ToastCloseArea>
      )}
    </ToastContainerElement>
  );
};

const ToastContainerElement = styled.div<{ type: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 0.75rem;
  background-color: ${(props) => (props.type === TOAST_TYPE_INFO ? '#009621' : '#e2001b')};
  transition: opacity 0.2s;
  color: #ffffff;
  border-radius: 4px;
  opacity: 0;

  &.show {
    opacity: 1;
  }
`;

const ToastCloseArea = styled.span`
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  font-size: 24px;
  text-align: center;
  width: 40px;
  cursor: pointer;
`;
const ToastElement = styled.span`
  white-space: pre-line;
`;

export const Toasts: React.FC = () => {
  const { toasts, setToasts } = useToastsContext();

  const onToastFinished = React.useCallback(
    (id: number) => {
      setToasts(toasts.filter((toast) => toast.id !== id));
    },
    [toasts, setToasts],
  );

  return (
    <ToastsContainerElement>
      {toasts.map((toast, index) => (
        <Toast key={index} {...toast} onHide={onToastFinished} />
      ))}
    </ToastsContainerElement>
  );
};

const ToastsContainerElement = styled.div`
  position: fixed;
  left: 50%;
  bottom: 0.5rem;
  transform: translateX(-50%);
  width: 500px;
  max-width: calc(100vw - 1rem);
  z-index: ${Z_INDEX_TOAST};

  & > * + * {
    margin-top: 0.25rem;
  }
`;
