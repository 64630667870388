import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { PracticeSubmitCard } from './PracticeSubmitCard';
import Pagination from './Pagination';

import ToggleImage from '../../static/image/icon_filter.svg';

import { FIRST_PAGE, FETCH_LIMIT_LOW } from '../../const/Page';
import { useURL } from '../../common/customHooks/URL';
import {
  AuthorizationType,
  PracticeSubmitListFragment,
  PracticeSubmitDetailReviewStatus,
} from '../../gen/graphql';
import { useNavigate } from 'react-router-dom';
import { useToastsContext } from '../../context/ToastsProvider';
import { PermissionType } from '../../const/UserPermission';
import { useCommonModal } from '../../redux/common_modal/useCommonModal';
import { getPracticeFunctionType } from '../../utils/Program';

interface Props {
  practiceSubmits: PracticeSubmitListFragment[];
  total: number;
  isOpenModal?: () => void;
}

export const PracticeSubmitsList: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { openModal } = useCommonModal();
  const { showToast } = useToastsContext();

  const { setParams, queries } = useURL();
  const page = useMemo(() => (queries?.page ? Number(queries.page) : FIRST_PAGE), [queries?.page]);
  const setPage = useCallback(
    (value: number) => {
      setParams([{ name: 'page', value: value.toString() }]);
    },
    [setParams],
  );

  const clickCard = useCallback(
    (
      type: AuthorizationType,
      isViewable: boolean,
      practiceSubmit: PracticeSubmitListFragment,
      practiceSubmitID: string,
    ) => {
      const functionType = getPracticeFunctionType(type);
      if (!functionType) return;
      if (
        !(practiceSubmit.reviewStatus === PracticeSubmitDetailReviewStatus.Passed) &&
        openModal(functionType, PermissionType.Read)
      )
        return;

      if (!isViewable) {
        showToast(1, '課題に合格するまで他の人の提出物は見られません');
        return;
      }

      navigate(`/practices/submits/${practiceSubmitID}`);
    },
    [navigate, openModal, showToast],
  );

  return (
    <>
      <CountArea>
        <Count>{props.total}件の結果</Count>
        {props.isOpenModal && <ToggleIcon src={ToggleImage} onClick={props.isOpenModal} />}
      </CountArea>
      <List>
        {props.practiceSubmits.map((practiceSubmit) => (
          <StyledPracticeSubmitCard
            key={practiceSubmit.id}
            reviewStatus={practiceSubmit.reviewStatus}
            reviewedCount={practiceSubmit.reviewedCount}
            practiceTitle={practiceSubmit.practice.title ?? ''}
            programTitle={practiceSubmit.practice.program.title}
            submitter={practiceSubmit.user}
            submitCreatedAt={practiceSubmit.createdAt}
            submitEditedAt={practiceSubmit.latestPracticeSubmitDetailEditedAt ?? undefined}
            clickCard={() =>
              clickCard(
                practiceSubmit.practice.type,
                practiceSubmit.isViewable,
                practiceSubmit,
                practiceSubmit.id,
              )
            }
          />
        ))}
      </List>
      <StyledPagination
        total={props.total}
        perPage={FETCH_LIMIT_LOW}
        page={page}
        setPage={setPage}
      />
    </>
  );
};

const CountArea = styled.div`
  ${media.lessThan('medium')`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;
const Count = styled.p`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1em;
`;
const ToggleIcon = styled.img`
  display: none;

  ${media.lessThan('medium')`
    display: block;
    cursor: pointer;
  `}
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;

  ${media.lessThan('medium')`
    gap: 0;
    margin: 1rem -1rem 0;
  `}
`;
const StyledPracticeSubmitCard = styled(PracticeSubmitCard)`
  ${media.lessThan('medium')`
    border-left: none;
    border-right: none;

    &:nth-of-type(n + 2) {
      border-top: none;
    }
  `}
`;
const StyledPagination = styled(Pagination)`
  margin: 1rem auto 0;

  ${media.lessThan('medium')`
    margin: 1.5rem auto 0;
  `}
`;
