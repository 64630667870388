import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import * as E from 'fp-ts/lib/Either';
import * as O from 'fp-ts/Option';
import stripeJs from '@stripe/stripe-js';

import { TOAST_TYPE_ERROR, useToastsContext } from '../../../context/ToastsProvider';

import { SubscriptionLayout } from '../../templates/SubscriptionLayout';
import { NotFoundPage } from '../public/NotFound';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Loader } from '../../molecules/Loader';
import { useApplyPromotionCode, usePromotionCodeField } from '../../molecules/PromotionCodeInput';
import { useCreditCard, usePaymentMethods } from '../../molecules/PaymentMethods';
import { DefaultPaymentAddModal } from '../../organisms/DefaultPaymentAddModal';
import { SubscriptionPaymentForm } from '../../organisms/SubscriptionPaymentForm';

import IconLock from '../../../static/image/icon_lock_gray.svg';

import {
  useSubscribeMutation,
  useGetPriceQuery,
  useMySubscriptionContractsQuery,
  useGetCampaignQuery,
  PaymentMethodType,
  GetPriceQuery,
  StripePaymentMethodFragment,
} from '../../../gen/graphql';
import {
  LmsStripeValidationError,
  StripePaymentService,
  StripePaymentServiceImpl,
  StripeSystemError,
} from '../../../infrastructure/externalService/StripePaymentService';
import { PRICE_ID_BASIC_PLAN } from '../../../const/Product';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { getApiErrorMessage } from '../../../utils/graphqlError';
import { LOWER_META_TITLE } from '../../../const/Service';
import { sessionStorageSupport } from '../../../utils/sessionStorageSupport';
import { Box } from '../../atoms/Box';
import { PlanCard } from '../../organisms/PlanCard';
import { useURL } from '../../../common/customHooks/URL';

export const AccountSubscriptionPayment: React.FC = (): JSX.Element => {
  const metaTitle = `加入プラン | ${LOWER_META_TITLE}`;

  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const { data: campaignData } = useGetCampaignQuery();

  // queryパラメータからpriceIDを取得(ないor無効な場合は1:ベーシック)
  const { priceID } = useQueryParams();

  const { price, loading: priceLoading } = usePrice(priceID);

  const {
    submit,
    fetchPaymentMethods,
    setIsCheckedFrontValidation,
    canSubmit,
    setElements,
    getPaymentMethodsLoading,
    paymentMethods,
    currentPaymentMethod,
    isPaymentMethodSelected,
    setStripe,
    selectedPayment,
    setPaymentMethodID,
    loadingMyPreviousContractExists,
    applyPromotionCodeInfo,
    promotionCodeField,
  } = useAccountSubscriptionPaymentForm({ setShowLoader, price: price });

  // priceが取得できない場合は設定がおかしい。
  if (!price && !priceLoading) {
    return NotFoundPage;
  }

  const loading =
    showLoader || getPaymentMethodsLoading || priceLoading || loadingMyPreviousContractExists;

  return (
    <>
      <Loader display={loading} />
      <SubscriptionLayout metaTitle={metaTitle}>
        <Wrapper>
          <StyledPageWrapper>
            <Side>
              <SideTitle>お申し込みプラン</SideTitle>
              <PlanCard price={price} applyPromotionCodeInfo={applyPromotionCodeInfo} />
              {Boolean(campaignData?.getCampaign) && (
                <CampaignImage
                  src={campaignData!.getCampaign!.imageURL}
                  alt={campaignData!.getCampaign!.title}
                />
              )}
            </Side>
            <Container padding={[8, 15]} spPadding={[6, 4, 8]}>
              <Title>ご請求情報の入力</Title>
              <Description>入力いただいた情報は暗号化されて送信されます。</Description>

              <SubscriptionPaymentForm
                paymentMethods={paymentMethods}
                currentPaymentMethod={currentPaymentMethod}
                setShowLoader={setShowLoader}
                fetchPaymentMethods={fetchPaymentMethods}
                setStripe={setStripe}
                setElements={setElements}
                setPaymentMethodID={setPaymentMethodID}
                selectedPayment={selectedPayment}
                isPaymentMethodSelected={isPaymentMethodSelected}
                setIsCheckedFrontValidation={setIsCheckedFrontValidation}
                price={price}
                submit={submit}
                canSubmit={canSubmit}
                promotionCodeField={promotionCodeField}
              />
            </Container>
          </StyledPageWrapper>
        </Wrapper>
      </SubscriptionLayout>
      <DefaultPaymentAddModal
        isOpen={paymentModalIsOpen}
        toggle={setPaymentModalIsOpen}
        fetchPayments={fetchPaymentMethods}
        cards={paymentMethods}
        currentDefaultPaymentId={currentPaymentMethod?.id ?? ''}
        setLoading={setShowLoader}
      />
    </>
  );
};

const useAccountSubscriptionPaymentForm = ({
  setShowLoader,
  price,
}: {
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
  price?: GetPriceQuery['getPrice'];
}) => {
  const [stripe, setStripe] = useState<stripeJs.Stripe | null>(null);
  const stripeService: StripePaymentService = useMemo(() => {
    return new StripePaymentServiceImpl(stripe);
  }, [stripe]);
  const [elements, setElements] = useState<stripeJs.StripeElements | null>(null);

  const {
    initCreditInfoValidationMsg,
    setCardValidationStripeError,
    cardNumberError,
    expirationError,
    cvcError,
  } = useCreditCard();

  const {
    paymentMethodID,
    setPaymentMethodID,
    isPaymentMethodSelected,
    currentPaymentMethodID,
    setCurrentPaymentMethodID,
    getPaymentMethodsLoading,
    paymentMethods,
    selectedPayment,
    currentPaymentMethod,
    currentDefaultPaymentId,
    changePaymentMethod,
    fetchPaymentMethods,
    setNewPaymentMethod,
  } = usePaymentMethods(stripeService);

  const { promotionCode, promotionCodeIsDirty, inputPromotionCode, handleResetDirty } =
    usePromotionCodeField();

  const {
    applyPromotionCodeInfo,
    setPromotionCodeError,
    applyPromotionCode,
    promotionCodeInfo,
    promotionCodeError,
    handleClickApply,
  } = useApplyPromotionCode({
    priceID: price?.id,
    promotionCode,
    promotionCodeIsDirty,
    handleResetDirty,
  });

  const [isCheckedFrontValidation, setIsCheckedFrontValidation] = useState(false);

  const { myPreviousContractExists, loading: loadingMyPreviousContractExists } =
    useMyPreviousContractExists();

  const changeSelectPayment = useSafeAsyncCallback(
    useCallback(
      async (providerID: string): Promise<void> => {
        initCreditInfoValidationMsg();
        changePaymentMethod(providerID, initCreditInfoValidationMsg);
      },
      [changePaymentMethod, initCreditInfoValidationMsg],
    ),
  );

  const submitPaymentMethod = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      initCreditInfoValidationMsg();

      if (!isPaymentMethodSelected) {
        // 新規カードでの支払い変更
        const result = await stripeService.checkCreditCard(O.fromNullable(elements));

        if (E.isLeft(result)) {
          const e = result.left;
          if (!(e instanceof StripeSystemError)) {
            setCardValidationStripeError(e);
          }
          throw e;
        } else if (E.isRight(result)) {
          const paymentMethodID = result.right.paymentId;
          await setNewPaymentMethod(paymentMethodID);
          setCurrentPaymentMethodID(paymentMethodID);
          initCreditInfoValidationMsg();
          fetchPaymentMethods();
        }
      } else {
        //既存カードでの支払い変更
        if (!paymentMethodID || paymentMethodID === currentDefaultPaymentId) {
          return;
        }
        changeSelectPayment(paymentMethodID);
      }
    }, [
      initCreditInfoValidationMsg,
      isPaymentMethodSelected,
      stripeService,
      elements,
      setCardValidationStripeError,
      setNewPaymentMethod,
      setCurrentPaymentMethodID,
      fetchPaymentMethods,
      paymentMethodID,
      currentDefaultPaymentId,
      changeSelectPayment,
    ]),
  );

  const { submit, canSubmit } = useSubmitButton({
    price,
    promotionCodeIsDirty,
    setPromotionCodeError,
    setShowLoader,
    submitPaymentMethod,
    myPreviousContractExists,
    applyPromotionCode,
    currentPaymentMethodID,
    isPaymentMethodSelected,
    isCheckedFrontValidation,
    currentPaymentMethod,
  });

  return {
    submit,
    fetchPaymentMethods,
    cardNumberError,
    cvcError,
    expirationError,
    canSubmit,
    isCheckedFrontValidation,
    setIsCheckedFrontValidation,
    setElements,
    getPaymentMethodsLoading,
    paymentMethods,
    currentPaymentMethod,
    isPaymentMethodSelected,
    setStripe,
    selectedPayment,
    setPaymentMethodID,
    loadingMyPreviousContractExists,
    applyPromotionCodeInfo,
    promotionCodeField: {
      promotionCode,
      promotionCodeInfo,
      promotionCodeError,
      handleClickApply,
      inputPromotionCode,
      handleResetDirty,
    },
  };
};

const useMyPreviousContractExists = () => {
  const { loading, data } = useMySubscriptionContractsQuery({
    variables: {
      input: {
        page: 1,
        limit: 1,
      },
    },
  });
  const myPreviousContractExists = data?.mySubscriptionContracts?.total > 0;

  return {
    myPreviousContractExists,
    loading,
  };
};

const usePrice = (priceId: number) => {
  const { data, loading } = useGetPriceQuery({
    variables: {
      id: priceId,
    },
  });
  return {
    price: data?.getPrice,
    loading,
  };
};

const useQueryParams = () => {
  const { queries } = useURL();
  if (queries?.priceID) {
    const parsedPriceID = parseInt(queries.priceID, 10);
    if (parsedPriceID > 0) {
      return { priceID: parsedPriceID };
    }
  }
  return { priceID: PRICE_ID_BASIC_PLAN };
};

const useSubmitButton = ({
  price,
  promotionCodeIsDirty,
  setPromotionCodeError,
  setShowLoader,
  submitPaymentMethod,
  myPreviousContractExists,
  applyPromotionCode,
  currentPaymentMethodID,
  isPaymentMethodSelected,
  isCheckedFrontValidation,
  currentPaymentMethod,
}: {
  price?: GetPriceQuery['getPrice'];
  promotionCodeIsDirty: boolean;
  setPromotionCodeError: (message: string) => void;
  setShowLoader: (show: boolean) => void;
  submitPaymentMethod: () => Promise<void>;
  myPreviousContractExists: boolean;
  applyPromotionCode: string | null;
  currentPaymentMethodID: string | null;
  isPaymentMethodSelected: boolean;
  isCheckedFrontValidation: boolean;
  currentPaymentMethod: StripePaymentMethodFragment | null;
}) => {
  const { showToast } = useToastsContext();
  const navigate = useNavigate();

  const [subscribe] = useSubscribeMutation();

  const canSubmit = useCallback(() => {
    if (!isPaymentMethodSelected) {
      return isCheckedFrontValidation;
    } else {
      return currentPaymentMethod !== null;
    }
  }, [isPaymentMethodSelected, isCheckedFrontValidation, currentPaymentMethod]);

  const submit = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (!price) {
        return;
      }

      if (promotionCodeIsDirty) {
        setPromotionCodeError(
          'クーポンコードを適用するか、クーポンコードを空にしてから決済を完了してください。',
        );
        return;
      }

      setShowLoader(true);

      try {
        await submitPaymentMethod();
      } catch (e) {
        if (e instanceof LmsStripeValidationError) {
          showToast(TOAST_TYPE_ERROR, e.message);
        } else {
          showToast(TOAST_TYPE_ERROR, getApiErrorMessage(e));
        }
        return;
      } finally {
        setShowLoader(false);
      }

      try {
        await subscribe({
          variables: {
            input: {
              priceID: price.id,
              promotionCode: applyPromotionCode ? applyPromotionCode : undefined,
              providerPaymentMethodID: currentPaymentMethodID,
              paymentMethodType: PaymentMethodType.CreditCard,
              timezoneOffset: new Date().getTimezoneOffset(),
            },
          },
        });
      } catch (e) {
        showToast(TOAST_TYPE_ERROR, getApiErrorMessage(e));
        return;
      } finally {
        setShowLoader(false);
      }

      sessionStorageSupport.setItem('REGISTERED_TYPE', 'recharge');
      sessionStorageSupport.setItem('QUESTIONNAIRE', 'true');
      sessionStorageSupport.setItem('IS_RE_CONTRACT', myPreviousContractExists.toString());
      navigate('/account/subscription/registered');
    }, [
      price,
      promotionCodeIsDirty,
      setPromotionCodeError,
      setShowLoader,
      submitPaymentMethod,
      subscribe,
      applyPromotionCode,
      currentPaymentMethodID,
      showToast,
      myPreviousContractExists,
      navigate,
    ]),
  );

  return { submit, canSubmit };
};

const Wrapper = styled.div`
  padding: 1.5rem 0;
  background-color: #fafafa;
`;
const StyledPageWrapper = styled(PageWrapper)`
  display: flex;
  gap: 1.25rem;

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 2rem;
  `}
`;

const Side = styled.div`
  width: 19.125rem;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
const SideTitle = styled.h2`
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.25rem;

  ${media.lessThan('medium')`
    text-align: center;
  `}
`;

const CampaignImage = styled.img`
  display: block;
  width: 100%;
  margin-top: 1rem;

  ${media.lessThan('medium')`
    margin-top: 2rem;
  `}
`;

const Container = styled(Box)`
  flex: 1;

  ${media.lessThan('medium')`
    margin: 0 -1rem;
  `}
`;
const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
`;
const Description = styled.p`
  margin-top: 1.25rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  line-height: 1.25;

  &:after {
    content: '';
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background: url(${IconLock}) center / auto no-repeat;
    vertical-align: top;
  }
`;
