import styled from 'styled-components';
import media from 'styled-media-query';

import { Box } from '../atoms/Box';
import { PracticeSubmitReviewStatusIcon } from '../atoms/PracticeSubmitReviewStatusIcon';

import TimeIcon from '../../static/image/icon_time.svg';
import DifficultyIcon from '../../static/image/icon_difficulty.svg';

import { Practice, PracticeDifficulty, PracticeSubmitDetailReviewStatus } from '../../gen/graphql';
import { PracticeDifficultyLables } from '../../const/Practice';

import inquiryIcon from '../../static/image/inquiry_icon.svg';

interface Props {
  practice:
    | Pick<
        Practice,
        | 'title'
        | 'description'
        | 'requireTime'
        | 'difficulty'
        | 'submitPassedCount'
        | 'isSubmittable'
      >
    | null
    | undefined;
  submitReviewStatus?: PracticeSubmitDetailReviewStatus;
  isExplanationTitle?: boolean;
  className?: string;
}

export const PracticeTitle: React.FC<Props> = ({
  practice,
  submitReviewStatus,
  isExplanationTitle,
  className,
}) => (
  <Box padding={[12, 8]} spPadding={[8, 4, 6]} className={className}>
    {!practice?.isSubmittable && <ClosedNotice />}
    <Label>課題</Label>
    <Title data-testid={dataTestIds.title}>{practice?.title ?? ''}</Title>
    <Description data-testid={dataTestIds.description}>{practice?.description ?? ''}</Description>
    {!isExplanationTitle && (
      <Info>
        <InfoText>
          <img src={TimeIcon} alt="" />
          <p>
            <strong data-testid={dataTestIds.requireTime}>{practice?.requireTime ?? 0}</strong>
            <span>分</span>
          </p>
        </InfoText>
        <InfoText>
          <img src={DifficultyIcon} alt="" />
          <p>
            <strong data-testid={dataTestIds.difficulty}>
              {PracticeDifficultyLables[practice?.difficulty ?? PracticeDifficulty.Normal]}
            </strong>
          </p>
        </InfoText>
        <InfoText>
          <p>
            <strong data-testid={dataTestIds.submitPassedCount}>
              {practice?.submitPassedCount ?? 0}
            </strong>
            <span>人が合格</span>
          </p>
        </InfoText>
        <PracticeSubmitReviewStatusIcon reviewStatus={submitReviewStatus} />
      </Info>
    )}
  </Box>
);

const Label = styled.p`
  color: #eb0000;
  font-size: 0.875rem;
  font-weight: 700;
`;
const Title = styled.h1`
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.87);
  font-size: 2.25rem;
  font-weight: 700;

  ${media.lessThan('medium')`
    line-height: 2.125rem;
    font-size: 1.5rem;
  `}
`;
const Description = styled.p`
  line-height: 1.5em;
  margin-top: 0.5rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
`;
const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.75rem;
`;
const InfoInner = styled.div`
  display: flex;
  align-items: center;
  gap: 0.125rem;
`;
const InfoText = styled(InfoInner)`
  img {
    display: block;
    width: 1rem;
    height: auto;
  }

  p {
    line-height: 1em;
    font-size: 1.125rem;

    strong {
      font-weight: 700;
    }

    span {
      line-height: 1em;
      font-size: 1rem;
      font-weight: 700;
    }
  }

  ${media.lessThan('medium')`
    img {
      width: .875rem;
    }

    p {
      font-size: .875rem;
    }
  `}
`;

const ClosedNotice: React.FC = () => {
  return (
    <ClosedNoticeDiv>
      <ClosedNoticeIconDiv>
        <img src={inquiryIcon} />
      </ClosedNoticeIconDiv>
      <ClosedNoticeTextColumnDiv>
        <ClosedNoticeText1>本課題は提出受付を終了しました</ClosedNoticeText1>
        <ClosedNoticeText2>
          「課題の解説」を公開しています。本課題を実施する場合は、「課題の解説」を参照のうえセルフレビューにて実施をお願いします。
        </ClosedNoticeText2>
      </ClosedNoticeTextColumnDiv>
    </ClosedNoticeDiv>
  );
};

const ClosedNoticeDiv = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 10px;
`;

const ClosedNoticeIconDiv = styled.div`
  width: 20px;
  height: 20px;
`;

const ClosedNoticeTextColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

const ClosedNoticeText1 = styled.div`
  color: #fd3c2f;
  font-feature-settings:
    'liga' off,
    'clig' off;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const ClosedNoticeText2 = styled.div`
  color: #fd3c2f;
  font-feature-settings:
    'liga' off,
    'clig' off;

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`;

export const dataTestIds = {
  title: 'PracticeTitle-title',
  description: 'PracticeTitle-description',
  requireTime: 'PracticeTitle-requireTime',
  difficulty: 'PracticeTitle-difficulty',
  submitPassedCount: 'PracticeTitle-submitPassedCount',
} as const;
