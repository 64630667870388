import React, { RefObject, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Button } from '../atoms/Button';
import { RichInput, RichInputHandler } from '../molecules/RichInput';

import IconBook from '../../static/image/icon_book_black.svg';
import IconQuestion from '../../static/image/icon_question_markdown.svg';
import { PracticeHintModal } from './PracticeHintModal';
import { PracticeConfirmModal } from './PracticeConfirmModal';
import { PracticeFragment } from '../../gen/graphql';
import { ErrorText } from '../atoms/ErrorText';
import { InputLabel } from '../atoms/InputLabel';

interface PracticeSubmitInputContentProps {
  practice: PracticeFragment;
  url: string;
  urlError: string;
  commentRef: RefObject<RichInputHandler>;
  onCommentKeyUp: (e: React.KeyboardEvent) => void;
  onURLChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PracticeSubmitInputContent: React.FC<PracticeSubmitInputContentProps> = ({
  practice,
  url,
  urlError,
  commentRef,
  onCommentKeyUp,
  onURLChange,
}): JSX.Element => {
  const { isOpenConfirmModal, isOpenHintModal, setIsOpenHintModal, setIsOpenConfirmModal } =
    usePracticeSubmitInputContent();

  return (
    <>
      <PracticeHintModal isOpen={isOpenHintModal} onClose={() => setIsOpenHintModal(false)} />
      <PracticeConfirmModal
        isOpen={isOpenConfirmModal}
        onClose={() => setIsOpenConfirmModal(false)}
        title={practice.title ?? ''}
        description={practice.description}
        content={practice.content ?? ''}
      />
      <FlexGroup>
        <Title>
          <SectionTitle>課題名</SectionTitle>
          <PracticeTitle>{practice.title}</PracticeTitle>
        </Title>
        <ModalButton onClick={() => setIsOpenConfirmModal(true)} gray>
          <ModalButtonInner>
            <img src={IconBook} alt="Book Icon" />
            課題を確認する
          </ModalButtonInner>
        </ModalButton>
      </FlexGroup>
      <Url isError={Boolean(urlError)}>
        <FlexGroupInputLabel>
          <StyledLabel for="practice_submit_url">提出物用URL</StyledLabel>
          {urlError && (
            <ErrorForPC data-testid="error-text-for-pc">
              <ErrorMessage>{urlError}</ErrorMessage>
            </ErrorForPC>
          )}
        </FlexGroupInputLabel>
        <StyledInput
          type="text"
          id="practice_submit_url"
          name="practice_submit_url"
          value={url}
          onChange={onURLChange}
          placeholder="URLを記入してください"
        />
      </Url>
      {urlError && (
        <ErrorForSP data-testid="error-text-for-sp">
          <ErrorMessage>{urlError}</ErrorMessage>
        </ErrorForSP>
      )}
      <Comment>
        <CommentTitle>
          コメントを記入
          <CommentNotice>
            ※課題の内容やレビュー結果に関する質問は、課題提出時のコメントではなく、Q&A掲示板で質問してください。
          </CommentNotice>
        </CommentTitle>
        <CommentLink onClick={() => setIsOpenHintModal(true)}>
          <img src={IconQuestion} alt="Question Icon" />
          課題提出でつまづいたら
        </CommentLink>
        <StyledRichInput
          name="practice_submit_comment"
          fileUpload={false}
          imageUpload
          placeholder="関連のスクリーンショットなど、提出時に記載の指定があるものを記載してください。"
          onKeyUp={onCommentKeyUp}
          ref={commentRef}
        />
      </Comment>
    </>
  );
};

const usePracticeSubmitInputContent = () => {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenHintModal, setIsOpenHintModal] = useState(false);

  return {
    isOpenHintModal,
    isOpenConfirmModal,
    setIsOpenConfirmModal,
    setIsOpenHintModal,
  };
};

const FlexGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  ${media.lessThan('medium')`
    display: block;
    position: relative;
  `}
`;
const FlexGroupInputLabel = styled.div`
  display: flex;
  ${media.lessThan('medium')`
    display: block;
    position: relative;
  `}
`;
const Title = styled.div`
  flex: 1;
`;
const SectionTitle = styled.h2`
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.75rem;
  line-height: 1.5;

  ${media.lessThan('medium')`
    font-weight: 500;
    line-height: 1em;
  `}
`;
const PracticeTitle = styled.p`
  margin-top: 0.125rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;

const ModalButton = styled(Button)`
  padding: 0.25rem 0.75rem;

  ${media.lessThan('medium')`
    padding: 0;
    background: none;
    border: none;
    position: absolute;
    top: 0;
    right: 0;

    &:hover {
      background: none;
    }
  `}
`;
const ModalButtonInner = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-size: 0.75rem;
  line-height: 1rem;

  ${media.lessThan('medium')`
    gap: .25rem;
  `}
`;

const Url = styled.div<{ isError?: boolean }>`
  margin-top: 1.125rem;
  padding: 0.375rem 0.75rem 0.75rem;
  background: #fff;
  border: 1px solid #${(props) => (props.isError ? 'eb0000' : 'dbdbdb')};
  border-radius: 0.125rem;

  ${media.lessThan('medium')`
    margin-top: 1.5rem;
  `}
`;
const StyledLabel = styled(InputLabel)`
  display: block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1em;
  min-width: 0;
`;
const StyledInput = styled.input`
  display: block;
  width: 100%;
  height: 1.25rem;
  margin-top: 0.375rem;
  padding: 0;
  border: none;
  box-shadow: none;
  outline: none;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;

  &::placeholder {
    color: rgba(0, 0, 0, 0.36);
  }

  &:focus {
    background: #fff;
    border: none;
    box-shadow: none;
    outline: none;
  }
`;
const ErrorForPC = styled.div`
  display: block;
  ${media.lessThan('medium')`
    display: none;
  `}
`;
const ErrorForSP = styled.div`
  margin-top: 0.75rem;
  display: none;
  ${media.lessThan('medium')`
    display: block;
  `}
`;
const ErrorMessage = styled(ErrorText)``;

const Comment = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2rem;

  ${media.lessThan('medium')`
    display: block;
    margin-top: 1.5rem;
  `}
`;
const CommentTitle = styled(SectionTitle)`
  font-size: 1.25rem;
  font-weight: 700;

  ${media.lessThan('medium')`
    font-size: 1rem;
  `}
`;
const CommentNotice = styled.span`
  display: inline;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-left: 4px;

  ${media.lessThan('medium')`
    display: block;
    margin-left: 0;
  `}
`;
const CommentLink = styled.p`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #eb0000;
  font-size: 0.75rem;
  line-height: 1rem;
`;
const StyledRichInput = styled(RichInput)`
  width: 100%;
  margin-top: 1rem;

  ${media.lessThan('medium')`
    margin-top: .5rem;
  `}
`;
