import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { TeamMemberHeader, TeamMemberColumn } from '../molecules/TeamMemberColumn';

import ScrollImage from '../../static/image/scroll_hint.png';
import Pagination from './Pagination';
import { FETCH_LIMIT_LOW } from '../../const/Page';
import { TeamMemberListFragment } from '../../gen/graphql';

interface Props {
  teamMembers: TeamMemberListFragment[];
  total: number;
  page: number;
  setPage: (page: number) => void;
}

export const TeamMemberList: React.FC<Props> = ({ teamMembers, total, page, setPage }) => {
  const [displayHint, setDisplayHint] = React.useState(true);

  return (
    <Container onClick={() => setDisplayHint(false)} onScroll={() => setDisplayHint(false)}>
      <TeamMemberHeader />
      {teamMembers.map((teamMember) => (
        <TeamMemberColumn key={teamMember.id} teamMember={teamMember} />
      ))}
      <StyledPagination total={total} perPage={FETCH_LIMIT_LOW} page={page} setPage={setPage} />
      <Scroll src={ScrollImage} isDisplay={displayHint} alt="" />
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  ${media.lessThan('large')`
    overflow-x: auto;
  `}

  ${media.lessThan('medium')`
    margin: 0 -1rem;
    padding: 0 1rem;
  `}
`;
const Scroll = styled.img<{ isDisplay: boolean }>`
  display: none;
  max-width: 10rem;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ${(props) =>
    props.isDisplay &&
    media.lessThan('medium')`
    display: block;
  `}
`;

const StyledPagination = styled(Pagination)`
  margin: 2rem auto 0;
`;
