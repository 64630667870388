import React, { useState } from 'react';
import styled from 'styled-components';

import { Button } from '../atoms/Button';
import { PageWrapper } from '../atoms/PageWrapper';
import { SearchType } from './SearchType';
import { QuestionFilter } from './QuestionFilter';

import FilterIcon from '../../static/image/icon_filter.svg';

import { useUser } from '../../redux/user/useUser';
import { chapterOrPracticeOptionValue } from '../../utils/Filter';
import { getChapters, getPractices } from '../../utils/Program';
import { INITIAL_ULID } from '../../const/Filter';
import { FIRST_PAGE, FETCH_LIMIT_HIGH } from '../../const/Page';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { setParamsValue, useURL } from '../../common/customHooks/URL';
import {
  DocumentType,
  ProgramType,
  ProgramSearchType,
  CountSearchInput,
  SearchQuestionStatus,
  useGetTagsQuery,
  useSearchProgramsQuery,
} from '../../gen/graphql';
import { strToEnum } from '../../utils/common';

interface Props {
  onClose: () => void;
  searchTypes?: string[];
  answered: number;
  solved: number;
  searchQuestionStatus: SearchQuestionStatus;
  programID?: number;
  chapterID?: number;
  practiceID?: string;
  searchTag: string;
  tagID: number;
  query: CountSearchInput;
}

export const SearchFilter: React.FC<Props> = (props) => {
  const { setParams } = useURL();
  const { permissionCheck } = useUser();

  const [types, setTypes] = useState<string[] | undefined>(props.searchTypes);
  const [answered, setAnswered] = useState<number>(props.answered);
  const [solved, setSolved] = useState<number>(props.solved);
  const [searchQuestionStatus, setSearchQuestionStatus] = useState<SearchQuestionStatus>(
    props.searchQuestionStatus,
  );
  const [programID, setProgramID] = useState<number | undefined>(props.programID);
  const [chapterID, setChapterID] = useState<number | undefined>(props.chapterID);
  const [practiceID, setPracticeID] = useState<string | undefined>(props.practiceID);
  const [searchTag, setSearchTag] = useState<string>(props.searchTag);
  const [tagID, setTagID] = useState<number>(props.tagID);

  const [hideQuestionFilter, setHideQuestionFilter] = useState<boolean>(
    !types?.includes(DocumentType.Qa),
  );

  const { data: programsData } = useSearchProgramsQuery({
    variables: {
      input: {
        programSearchType: ProgramSearchType.All,
        type: permissionCheck(FunctionType.HomeForInstructorAndCoach, PermissionType.Read) // インスト・コーチの場合は全教材検索
          ? undefined
          : ProgramType.Normal,
        page: FIRST_PAGE,
        limit: FETCH_LIMIT_HIGH,
      },
    },
  });

  const programs = programsData?.programs;

  const { data: tagData } = useGetTagsQuery({
    variables: {
      input: {
        page: FIRST_PAGE,
        limit: FETCH_LIMIT_HIGH,
      },
    },
  });

  const tagsData = tagData?.getTags;

  const handleAnsweredSelect = (value: string) => {
    setAnswered(parseInt(value));
  };

  const handleSolvedSelect = (value: string) => {
    setSolved(parseInt(value));
  };

  const handleSearchQuestionStatusSelect = (value: string) => {
    setSearchQuestionStatus(strToEnum(value, SearchQuestionStatus) ?? SearchQuestionStatus.All);
  };

  const handleProgramIDSelect = (value: string) => {
    const selectProgramID = parseInt(value);
    setProgramID(selectProgramID);
    setChapterID(0);
    setPracticeID(INITIAL_ULID);
  };

  const handleChapterIDOrPracticeIDSelect = (value: string) => {
    const [chapterId, practiceId] = chapterOrPracticeOptionValue(value);
    setChapterID(parseInt(chapterId));
    setPracticeID(practiceId);
  };

  const handleSearchTagSet = (value: string) => {
    setSearchTag(value);
  };

  const handleTagReset = () => {
    setTagID(0);
  };

  const handleTagIDSelect = (value: number) => {
    setTagID(value !== tagID ? value : 0);
  };

  const handleTypes = (value?: string) => {
    const valueArray = value ? value.split(',') : undefined;
    if (!valueArray) {
      setTypes(undefined);
      setHideQuestionFilter(false);
    } else {
      setTypes(valueArray);
      setHideQuestionFilter(!valueArray.includes(DocumentType.Qa));

      if (!valueArray.includes(DocumentType.Qa)) {
        handleAnsweredSelect('0');
        handleSolvedSelect('0');
      }
    }
  };

  const submit = () => {
    const params = [];

    params.push(
      types ? setParamsValue('search_type', types.join(',')) : setParamsValue('search_type'),
    );
    params.push(answered ? setParamsValue('answered', answered) : setParamsValue('answered'));
    params.push(solved ? setParamsValue('solved', solved) : setParamsValue('solved'));
    params.push(
      searchQuestionStatus
        ? setParamsValue('search_question_status', searchQuestionStatus)
        : setParamsValue('search_question_status'),
    );
    params.push(programID ? setParamsValue('program_id', programID) : setParamsValue('program_id'));
    params.push(chapterID ? setParamsValue('chapter_id', chapterID) : setParamsValue('chapter_id'));
    params.push(
      practiceID ? setParamsValue('practice_id', practiceID) : setParamsValue('practice_id'),
    );
    params.push(tagID ? setParamsValue('tag_id', tagID) : setParamsValue('tag_id'));
    // 検索条件変更時はpageをリセット
    params.push(setParamsValue('page'));

    setParams(params);

    props.onClose();
  };

  const QuestionFilterProps = {
    permissionCheck,
    displayAnswered: {
      answered,
      handleAnsweredSelect,
    },
    displaySolved: {
      solved,
      handleSolvedSelect,
    },
    displaySearchQuestionStatus: {
      searchQuestionStatus,
      handleSearchQuestionStatusSelect,
    },
    displayProgram:
      programID !== undefined
        ? { programs: programs?.items ?? [], programID, handleProgramIDSelect }
        : undefined,
    displayChapterOrPractice:
      chapterID !== undefined || practiceID !== undefined
        ? {
            chapters: programID
              ? (getChapters(programs?.items.find((i) => i.id === programID)) ?? [])
              : [],
            chapterID: chapterID !== undefined ? chapterID : 0,
            practices: programID
              ? (getPractices(programs?.items.find((i) => i.id === programID)) ?? [])
              : [],
            practiceID: practiceID !== undefined ? practiceID : '',
            handleChapterIDOrPracticeIDSelect,
          }
        : undefined,
    displayTag: {
      searchTag,
      handleSearchTagSet,
      tags: tagsData?.items ?? [],
      tagID,
      handleTagReset,
      handleTagIDSelect,
    },
  };

  return (
    <>
      <FilterTitleSection>
        <h2>フィルター</h2>
        <img src={FilterIcon} onClick={props.onClose} />
      </FilterTitleSection>
      <PageWrapper>
        <SearchType searchTypes={types} setValue={handleTypes} query={props.query} />
        <StyledFilter
          {...QuestionFilterProps}
          search
          hideQuestion={hideQuestionFilter}
          searchQuery={props.query}
        />
      </PageWrapper>
      <ButtonWrapper>
        <SubmitButton onClick={submit}>絞り込み</SubmitButton>
      </ButtonWrapper>
    </>
  );
};

const FilterTitleSection = styled.div`
  padding: 0.75rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;

  h2 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
  }

  img {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin: auto;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1rem;
  }
`;
const StyledFilter = styled(QuestionFilter)`
  padding: 0;
`;
const ButtonWrapper = styled.div`
  padding: 0.625rem 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;
const SubmitButton = styled(Button)`
  display: block;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
`;
