import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { SUBSCRIPTION_PLANS } from '../../../const/Prices';

import IconCircle from '../../../static/image/lp/icon_circle.svg';
import IconCross from '../../../static/image/lp/icon_cross.svg';
import IconTriangle from '../../../static/image/lp/icon_triangle.svg';

interface Props {
  forwardToSubscription?: boolean;
}

export const PlanTable: React.FC<Props> = (props) => {
  return (
    <Container isSubscription={props.forwardToSubscription}>
      <RowTitleCell
        row={2}
        column={1}
        as="h4"
        isSubscription={Boolean(props.forwardToSubscription)}
      >
        教材利用
      </RowTitleCell>
      <RowTitleCell
        row={3}
        column={1}
        as="h4"
        isSubscription={Boolean(props.forwardToSubscription)}
      >
        課題提出・レビュー
      </RowTitleCell>
      <RowTitleCell
        row={4}
        column={1}
        as="h4"
        isSubscription={Boolean(props.forwardToSubscription)}
      >
        学習ログ
      </RowTitleCell>
      <RowTitleCell
        row={5}
        column={1}
        as="h4"
        isSubscription={Boolean(props.forwardToSubscription)}
      >
        Q&A
      </RowTitleCell>
      <RowTitleCell
        row={6}
        column={1}
        as="h4"
        isSubscription={Boolean(props.forwardToSubscription)}
      >
        レッスン
      </RowTitleCell>
      <RowTitleCell
        row={7}
        column={1}
        as="h4"
        isSubscription={Boolean(props.forwardToSubscription)}
      >
        月々の料金
      </RowTitleCell>

      {/* プレミアムプラン */}
      <ColumnTitleCell
        row={1}
        column={2}
        as="h3"
        isSubscription={Boolean(props.forwardToSubscription)}
      >
        <span>
          週1ペースで
          <br />
          レッスン可能
        </span>
        プレミアム
        <br className="sp" />
        プラン
      </ColumnTitleCell>
      <Cell row={2} column={2} isSubscription={Boolean(props.forwardToSubscription)}>
        <Icon src={IconCircle} />
      </Cell>
      <Cell row={3} column={2} isSubscription={Boolean(props.forwardToSubscription)}>
        <Icon src={IconCircle} />
      </Cell>
      <Cell row={4} column={2} isSubscription={Boolean(props.forwardToSubscription)}>
        <Icon src={IconCircle} />
      </Cell>
      <Cell row={5} column={2} isSubscription={Boolean(props.forwardToSubscription)}>
        <Icon src={IconCircle} />
      </Cell>
      <Cell row={6} column={2} isSubscription={Boolean(props.forwardToSubscription)}>
        <Num>
          4<span>回/月</span>
        </Num>
      </Cell>
      <Cell row={7} column={2} isSubscription={Boolean(props.forwardToSubscription)}>
        <Price>{SUBSCRIPTION_PLANS.PREMIUM[0].taxIncludedPrice.toLocaleString()}円〜</Price>
      </Cell>

      {/* ベーシックプラン */}
      <ColumnTitleCell
        row={1}
        column={3}
        as="h3"
        isSubscription={Boolean(props.forwardToSubscription)}
      >
        <span>
          月2回のレッスンで
          <br />
          効率的な学習が可能
        </span>
        ベーシック
        <br className="sp" />
        プラン
      </ColumnTitleCell>
      <Cell row={2} column={3} isSubscription={Boolean(props.forwardToSubscription)}>
        <Icon src={IconCircle} />
      </Cell>
      <Cell row={3} column={3} isSubscription={Boolean(props.forwardToSubscription)}>
        <Icon src={IconCircle} />
      </Cell>
      <Cell row={4} column={3} isSubscription={Boolean(props.forwardToSubscription)}>
        <Icon src={IconCircle} />
      </Cell>
      <Cell row={5} column={3} isSubscription={Boolean(props.forwardToSubscription)}>
        <Icon src={IconCircle} />
      </Cell>
      <Cell row={6} column={3} isSubscription={Boolean(props.forwardToSubscription)}>
        <Num>
          2<span>回/月</span>
        </Num>
      </Cell>
      <Cell row={7} column={3} isSubscription={Boolean(props.forwardToSubscription)}>
        <Price>{SUBSCRIPTION_PLANS.BASIC[0].taxIncludedPrice.toLocaleString()}円〜</Price>
      </Cell>

      {/* ライトプラン */}
      <ColumnTitleCell
        row={1}
        column={4}
        as="h3"
        isSubscription={Boolean(props.forwardToSubscription)}
      >
        <span>
          教材中心の
          <br />
          学習を行う
        </span>
        ライト
        <br className="sp" />
        プラン
      </ColumnTitleCell>
      <Cell row={2} column={4} isSubscription={Boolean(props.forwardToSubscription)}>
        <Icon src={IconCircle} />
      </Cell>
      <Cell row={3} column={4} isSubscription={Boolean(props.forwardToSubscription)}>
        <div>
          <Icon src={IconTriangle} />
          <LimitText>制限あり</LimitText>
        </div>
      </Cell>
      <Cell row={4} column={4} isSubscription={Boolean(props.forwardToSubscription)}>
        <Icon src={IconCircle} />
      </Cell>
      <Cell row={5} column={4} isSubscription={Boolean(props.forwardToSubscription)}>
        <Icon src={IconCircle} />
      </Cell>
      <Cell row={6} column={4} isSubscription={Boolean(props.forwardToSubscription)}>
        <Num>
          1<span>回/月</span>
        </Num>
      </Cell>
      <Cell row={7} column={4} isSubscription={Boolean(props.forwardToSubscription)}>
        <Price>{SUBSCRIPTION_PLANS.LIGHT[0].taxIncludedPrice.toLocaleString()}円〜</Price>
      </Cell>

      {/* フリープラン */}
      {Boolean(props.forwardToSubscription) && (
        <>
          <ColumnTitleCell
            row={1}
            column={5}
            as="h3"
            isSubscription={Boolean(props.forwardToSubscription)}
          >
            フリー
            <br className="sp" />
            プラン
          </ColumnTitleCell>
          <Cell row={2} column={5} isSubscription={Boolean(props.forwardToSubscription)}>
            <div>
              <Icon src={IconTriangle} />
              <LimitText>制限あり</LimitText>
            </div>
          </Cell>
          <Cell row={3} column={5} isSubscription={Boolean(props.forwardToSubscription)}>
            <Icon src={IconCross} />
          </Cell>
          <Cell row={4} column={5} isSubscription={Boolean(props.forwardToSubscription)}>
            <Icon src={IconCircle} />
          </Cell>
          <Cell row={5} column={5} isSubscription={Boolean(props.forwardToSubscription)}>
            <div>
              <Icon src={IconTriangle} />
              <LimitText>制限あり</LimitText>
            </div>
          </Cell>
          <Cell row={6} column={5} isSubscription={Boolean(props.forwardToSubscription)}>
            <Icon src={IconCross} />
          </Cell>
          <Cell row={7} column={5} isSubscription={Boolean(props.forwardToSubscription)}>
            <Price>無料</Price>
          </Cell>
        </>
      )}
    </Container>
  );
};

const Style = {
  BORDER: {
    STYLE: '.125rem solid #ececec',
    RADIUS: '.1875rem',
  },
  CELL: {
    ROW: {
      // HACK: 課題の行が追加されたらここも変更
      LAST: 6,
    },
  },
};

const Container = styled.div<{ isSubscription?: boolean }>`
  display: grid;
  grid-auto-rows: minmax(5.625rem, auto);
  margin: 0 auto;

  ${(props) =>
    !props.isSubscription
      ? `
    grid-template-columns: 12.5rem 1fr 1fr 1fr;
    max-width: 800px;
  `
      : `
    grid-template-columns: 12.5rem 1fr 1fr 1fr 1fr;
    width: 1056px;
  `}

  ${media.lessThan('medium')`
    grid-auto-rows: minmax(5rem, auto);
  `}

  ${(props) =>
    !props.isSubscription
      ? media.lessThan('medium')`
    width: 460px;
    grid-template-columns: 6.25rem 1fr 1fr 1fr;
  `
      : media.lessThan('medium')`
    width: 580px;
    grid-template-columns: 6.25rem 1fr 1fr 1fr 1fr;
  `}
`;
const Cell = styled.div<{
  row: number;
  column: number;
  isSubscription: boolean;
  rowTitle?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: ${(props) => `${props.column} / ${props.column + 1}`};
  grid-row: ${(props) => `${props.row} / ${props.row + 1}`};
  padding: 1rem;
  background: #${(props) => (props.row % 2 === 0 ? 'fff' : 'f6f6f6')};
  border-left: ${Style.BORDER.STYLE};

  ${(props) =>
    props.isSubscription &&
    props.column === 5 &&
    `
    border-right: ${Style.BORDER.STYLE};
  `}
  ${(props) =>
    !props.isSubscription &&
    props.column === 4 &&
    `
    border-right: ${Style.BORDER.STYLE};
  `}
  ${(props) =>
    props.row === Style.CELL.ROW.LAST &&
    `
    border-bottom: ${Style.BORDER.STYLE};
  `}
  ${(props) =>
    props.row === 2 &&
    props.column === 1 &&
    `
    border-top: ${Style.BORDER.STYLE};
  `}
`;
const RowTitleCell = styled(Cell)`
  background: #${(props) => (props.row % 2 === 0 ? 'f2f2f2' : 'ececec')};
  ${(props) =>
    props.row === 2 &&
    `
    border-radius: ${Style.BORDER.RADIUS} 0 0 0;
  `}
  ${(props) =>
    props.isSubscription &&
    props.row === Style.CELL.ROW.LAST + 1 &&
    `
    border-radius: 0 0 0 ${Style.BORDER.RADIUS};
  `}
  ${(props) =>
    !props.isSubscription &&
    props.row === Style.CELL.ROW.LAST &&
    `
    border-radius: 0 0 0 ${Style.BORDER.RADIUS};
  `}

  color: #333;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: .75rem;
  `}
`;
const ColumnTitleCell = styled(Cell)`
  flex-direction: column;
  gap: 0.25rem;
  padding: 1.5rem 0.5rem;
  border-top: ${Style.BORDER.STYLE};
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;

  span {
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;
  }

  .sp {
    display: none;
  }

  ${(props) => {
    switch (props.column) {
      case 2:
        return `
          background: #ff3e3e;
          border-radius: ${Style.BORDER.RADIUS} 0 0 0;
        `;
      case 3:
        return 'background: #ff5f5f;';
      case 4:
        return `
          background: #ff7878;
        `;
      case 5:
        return `
          background: #f2f2f2;
          color: rgba(0,0,0,.87);
        `;
    }
  }}

  ${(props) =>
    props.isSubscription &&
    props.column === 5 &&
    `
    border-radius: 0 ${Style.BORDER.RADIUS} 0 0;
  `}

  ${(props) =>
    !props.isSubscription &&
    props.column === 4 &&
    `
    border-radius: 0 ${Style.BORDER.RADIUS} 0 0;
  `}

  ${media.lessThan('medium')`
    padding: 1.125rem 0;
    font-size: .875rem;

    span {
      font-size: .75rem;
    }

    .sp {
      display: block;
    }
  `}
`;
const Icon = styled.img`
  display: block;
  margin: auto;

  ${media.lessThan('medium')`
    width: 1.25rem;
    height: 1.25rem;
  `}
`;
const LimitText = styled.p`
  margin-top: 0.25rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.125rem;
`;
const Num = styled.p`
  color: #333;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;

  span {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.125rem;
  }

  ${media.lessThan('medium')`
    font-size: 1rem;
  `}
`;
const Price = styled.p`
  color: #333;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;

  ${media.lessThan('medium')`
    font-size: .75rem;
  `}
`;
