import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Z_INDEX_FAB } from '../../const/ZIndex';

interface FabProps {
  onClick: () => void;
  disabled?: boolean;
}

export const Fab: React.FC<FabProps> = (props) => {
  return (
    <StyledFab onClick={props.onClick} disabled={props.disabled} data-e2e="btn">
      {props.children}
    </StyledFab>
  );
};

const StyledFab = styled.button<{ disabled?: boolean }>`
  appearance: none;
  padding: 1rem 2rem;
  border: none;
  border-radius: 1.5rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.4);
  background-color: #eb0000;
  cursor: pointer;
  color: #fff;
  font-size: 0.8rem;
  line-height: 1em;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: ${Z_INDEX_FAB};

  ${(props) =>
    Boolean(props.disabled) &&
    `
    background: #e5e5e5;
    color: rgba(0,0,0,.87);
    pointer-events: none;
  `}

  ${media.lessThan('small')`
    right: 1rem;
    bottom: 1rem;
  `}
`;
