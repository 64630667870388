import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface Props {
  title: string;
  className?: string;
}

export const EmptyBlock: React.FC<Props> = (props) => {
  return (
    <Container className={props.className}>
      <svg
        width="65"
        height="64"
        viewBox="0 0 65 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.655 8C17.0987 8 8.5 16.5987 8.5 27.155C8.5 37.7113 17.0987 46.3102 27.655 46.3102C32.2456 46.3102 36.4614 44.68 39.7653 41.9741L51.2023 53.4108C51.3788 53.5948 51.5901 53.7414 51.8241 53.8426C52.0582 53.9438 52.3097 53.9973 52.5647 53.9999C52.8195 54.0025 53.0725 53.9542 53.3082 53.8579C53.5443 53.7616 53.7587 53.619 53.9387 53.4387C54.119 53.2587 54.2616 53.0443 54.3579 52.8082C54.4542 52.5725 54.5025 52.3195 54.4999 52.0647C54.4973 51.8097 54.4438 51.5582 54.3426 51.3241C54.2414 51.0901 54.0948 50.8788 53.9108 50.7023L42.4741 39.2653C45.18 35.9614 46.8102 31.7456 46.8102 27.155C46.8102 16.5987 38.2113 8 27.655 8ZM27.655 11.831C36.141 11.831 42.9792 18.6691 42.9792 27.155C42.9792 35.641 36.141 42.4792 27.655 42.4792C19.1691 42.4792 12.331 35.641 12.331 27.155C12.331 18.6691 19.1691 11.831 27.655 11.831Z"
          fill="black"
          fillOpacity="0.36"
        />
      </svg>
      <Title>{props.title}</Title>
      {Boolean(props.children) && <Description>{props.children}</Description>}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 3rem 1rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  box-sizing: border-box;

  svg {
    display: block;
    margin: 0 auto;
  }
`;
const Title = styled.p`
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.125rem;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 1rem;
  `}
`;
const Description = styled.p`
  margin-top: 0.25rem;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.1px;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: .75rem;
    line-height: 1.125rem;
  `}
`;
