import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Button } from '../atoms/Button';
import { useApplyPromotionCodeLazyQuery } from '../../gen/graphql';
import { TOAST_TYPE_ERROR, useToastsContext } from '../../context/ToastsProvider';
import { ApolloError } from '@apollo/client';
import { getApiErrorMessage } from '../../utils/graphqlError';
import { defaultErrorMessage } from '../../const/ErrorMessage';

export interface PromotionCodeField {
  promotionCode: string;
  promotionCodeInfo: string;
  promotionCodeError: string;
  handleClickApply: () => void;
  inputPromotionCode: (value: string) => void;
}

interface PromotionCodeInputProps extends PromotionCodeField {
  hiddenLabel?: boolean;
}

export const PromotionCodeInput: React.FC<PromotionCodeInputProps> = ({
  hiddenLabel,
  promotionCode,
  promotionCodeInfo,
  promotionCodeError,
  handleClickApply,
  inputPromotionCode,
}) => {
  const disabledPromotionCode = promotionCode === undefined || promotionCode.trim().length === 0;

  return (
    <PromotionCode>
      {!hiddenLabel && (
        <Label>
          <label>クーポンコード</label>
        </Label>
      )}

      <PromotionCodeColumn>
        <StyledInput
          placeholder="クーポンコードを入力"
          name="promotionCode"
          value={promotionCode}
          type="text"
          error={Boolean(promotionCodeError)}
          onChange={(e) => inputPromotionCode(e.target.value)}
        />
        <ApplyButton onClick={handleClickApply} disabled={disabledPromotionCode}>
          適用
        </ApplyButton>
      </PromotionCodeColumn>

      <InfoText>{promotionCodeInfo}</InfoText>
      <ErrorText>{promotionCodeError}</ErrorText>
    </PromotionCode>
  );
};

export const usePromotionCodeField = () => {
  const [promotionCode, setPromotionCode] = useState('');
  const [promotionCodeIsDirty, setPromotionCodeIsDirty] = useState(false);

  const inputPromotionCode = (value: string) => {
    const trimmedValue = value.trim();
    setPromotionCode(trimmedValue);
    setPromotionCodeIsDirty(trimmedValue.length > 0);
  };

  const handleResetDirty = () => setPromotionCodeIsDirty(false);

  return {
    promotionCode,
    promotionCodeIsDirty,
    inputPromotionCode,
    handleResetDirty,
  };
};

export const useApplyPromotionCode = ({
  priceID,
  promotionCode,
  promotionCodeIsDirty,
  handleResetDirty,
}: {
  priceID?: number;
  promotionCode: string;
  promotionCodeIsDirty: boolean;
  handleResetDirty: () => void;
}) => {
  const { showToast } = useToastsContext();

  const [applyPromotionCodeQuery, { data: applyPromotionCodeData }] =
    useApplyPromotionCodeLazyQuery();
  const [applyPromotionCode, setApplyPromotionCode] = useState('');
  const applyPromotionCodeInfo = applyPromotionCodeData?.applyPromotionCode;
  const promotionCodeInfo = applyPromotionCodeInfo ? 'クーポンコードが適用されました' : '';
  const [promotionCodeError, setPromotionCodeError] = useState('');

  const handleClickApply = async () => {
    if (!priceID) {
      return;
    }

    try {
      const { error } = await applyPromotionCodeQuery({
        variables: {
          promotionCode,
          priceID,
        },
      });
      if (error) {
        throw error;
      }
      setPromotionCodeError('');
      handleResetDirty();
      setApplyPromotionCode(promotionCode);
    } catch (e) {
      if (e instanceof ApolloError) {
        setPromotionCodeError(getApiErrorMessage(e));
        return;
      }
      showToast(TOAST_TYPE_ERROR, defaultErrorMessage);
    }
  };

  return {
    applyPromotionCodeInfo,
    promotionCodeInfo,
    promotionCodeIsDirty,
    promotionCodeError,
    setPromotionCodeError,
    handleClickApply,
    promotionCode,
    applyPromotionCode,
  };
};

const PromotionCode = styled.div``;

const Label = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 1rem;

  .required {
    display: block;
    margin-right: 0.5rem;
    padding: 0.125rem 0.5rem;
    background-color: #fd6258;
    color: #fff;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.2;
  }

  label {
    font-size: 1rem;
    font-weight: 700;
  }

  small {
    display: block;
    margin-left: 0.5rem;
    color: rgba(0, 0, 0, 0.36);
    font-size: 0.75rem;
  }
`;
const PromotionCodeColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  > img {
    display: block;
    height: 1.5rem;
  }
`;
const StyledInput = styled.input<{ error?: boolean }>`
  padding: 0.5rem;
  flex: 1 1 100%;
  width: 100%;
  appearance: none;
  border: none;
  font-size: 1rem;
  box-sizing: border-box;
  background-color: ${(props): string => (props.error ? '#fce5e8' : '#ffffff')};
  border: 1px solid rgba(0, 0, 0, 0.36);
`;
const ApplyButton = styled(Button)`
  width: 5.625rem;
  padding-left: 0;
  padding-right: 0;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 80px;
  `}
`;
const InfoText = styled.p`
  margin-top: 0.5rem;
  color: #000000;
  align-self: center;
  font-weight: 500;
`;
const ErrorText = styled.p`
  margin-top: 0.5rem;
  color: #fd6258;
  align-self: center;
  font-weight: 500;
`;
