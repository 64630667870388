import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { isNone, Option } from 'fp-ts/Option';
import { findFirst } from 'fp-ts/Array';
import { format, parseISO } from 'date-fns';
import TagManager from 'react-gtm-module';

import { useUser } from '../../../redux/user/useUser';
import { useToastsContext } from '../../../context/ToastsProvider';

import { Button } from '../../atoms/Button';
import { Spacer } from '../../atoms/Spacer';
import { Loader } from '../../molecules/Loader';
import { SubscriptionCancelModalsArea } from '../../organisms/SubscriptionCancelModalsArea';
import { SubscriptionUpdateScheduleCancelModalsArea } from '../../organisms/SubscriptionUpdateScheduleCancelModalsArea';
import { SubscriptionModifyFlowModal } from '../../organisms/SubscriptionModifyFlowModal';
import { SubscriptionContractList } from '../../organisms/SubscriptionContractList';

import {
  StripePaymentMethodFragment,
  ProviderSubscriptionStatus,
  useGetPaymentMethodsQuery,
  useSubscriptionQuery,
  useGetTeamExistMySubscriptionQuery,
  useMySubscriptionContractsQuery,
  useIsUpgradableQuery,
} from '../../../gen/graphql';
import { defaultErrorMessage } from '../../../const/ErrorMessage';
import { StripeStatusLabels } from '../../../const/Stripe';
import { Payment } from '../../../const/Payment';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { AccountTabLayout } from '../../templates/AccountTabLayout';
import { useURL } from '../../../common/customHooks/URL';
import { FIRST_PAGE, FETCH_LIMIT_LOW } from '../../../const/Page';
import { useNavigate } from 'react-router-dom';
import { LOWER_META_TITLE } from '../../../const/Service';

// このコンポーネントで表示する日付のフォーマット(画面によって違うので共通化はしない)
const formatDate = (dateString: string) => format(parseISO(dateString), 'yyyy/MM/dd HH:mm:ss');

export const AccountSubscription = (): JSX.Element => {
  const metaTitle = `加入プラン | ${LOWER_META_TITLE}`;

  const { setParams, queries } = useURL();
  const navigate = useNavigate();

  const page = useMemo(() => (queries?.page ? Number(queries.page) : FIRST_PAGE), [queries?.page]);

  const setPage = useCallback(
    (value: number) => {
      setParams([{ name: 'page', value: value.toString() }]);
    },
    [setParams],
  );

  const {
    data,
    loading: subscriptionLoading,
    refetch: refetchSubscription,
  } = useSubscriptionQuery({
    onError: () => {
      showToast(1, defaultErrorMessage);
    },
  });

  const { loading: contractLoading, ...contractData } = useMySubscriptionContractsQuery({
    variables: {
      input: {
        page: page,
        limit: FETCH_LIMIT_LOW,
      },
    },
    onError: () => {
      showToast(1, defaultErrorMessage);
    },
  });
  const subscription = data?.subscription ?? undefined;
  const scheduledSubscriptionContract = data?.scheduledSubscriptionContract ?? undefined;
  const subscriptionCancellationPeriod = data?.subscriptionCancellationPeriod ?? undefined;
  const contracts = contractData.data?.mySubscriptionContracts?.items ?? [];
  const total = contractData.data?.mySubscriptionContracts?.total ?? 0;

  // 最新の契約情報のみを取得
  const {
    loading: currentContractLoading,
    refetch: refetchMySubscription,
    ...currentContractData
  } = useMySubscriptionContractsQuery({
    fetchPolicy: 'no-cache', // 更新後キャッシュされないようにする
    variables: {
      input: {
        page: 1,
        limit: 1,
      },
    },
    onError: () => {
      showToast(1, defaultErrorMessage);
    },
  });
  const currentSubscriptionContract =
    (currentContractData.data?.mySubscriptionContracts?.items.length ?? 0) > 0
      ? currentContractData?.data?.mySubscriptionContracts?.items[0]
      : undefined;
  const cancelReserved = currentSubscriptionContract?.canceledAt ? true : false;

  const [cancelIsOpen, setCancelIsOpen] = useState(false);
  const [modifyFlowIsOpen, setModifyFlowIsOpen] = useState(false);
  const [cancelUpdateScheduleIsOpen, setCancelUpdateScheduleIsOpen] = useState(false);

  const { showToast } = useToastsContext();
  const { user, permissionCheck, isSocialLoginUser } = useUser();

  const { data: teamExistMySubscriptionData, loading: teamExistMySubscriptionLoading } =
    useGetTeamExistMySubscriptionQuery();
  const validTeamSubscription = teamExistMySubscriptionData?.teamExistMySubscription ?? false;

  const { data: fetchCardData, loading: fetchCardLoading } = useGetPaymentMethodsQuery({
    variables: {
      limit: Payment.MAX_ITEM_NUMBER,
    },
    onError: () => {
      showToast(1, defaultErrorMessage);
    },
  });
  const paymentMethods = useMemo(() => fetchCardData?.paymentMethods.items ?? [], [fetchCardData]);

  const {
    data: isUpgradableData,
    loading: isUpgradableLoading,
    refetch: refetchIsUpgradable,
  } = useIsUpgradableQuery({
    fetchPolicy: 'no-cache', // 更新後キャッシュされないようにする
  });
  const isUpgradable = isUpgradableData?.isUpgradable ?? false;

  const refetchSubscriptionAll = useCallback(async (): Promise<void> => {
    await refetchSubscription();
    await refetchMySubscription();
    await refetchIsUpgradable();
  }, [refetchSubscription, refetchMySubscription, refetchIsUpgradable]);

  const currentCardNumberLast4 = useMemo((): string => {
    if (paymentMethods.length <= 0) return '';

    const currentPayment: Option<StripePaymentMethodFragment> = findFirst(
      (p: StripePaymentMethodFragment) => p.isDefault,
    )(paymentMethods);
    if (isNone(currentPayment)) {
      return '';
    }

    return currentPayment.value.cardNumber;
  }, [paymentMethods]);

  const handleClickCancel = useCallback((): void => {
    if (scheduledSubscriptionContract) {
      showToast(1, 'プラン変更予約をキャンセルしてから解約申請ください');
      return;
    }

    // NOTE: 解約時のみイベント送信(ボタンが解約と解約取り消し同じなので)
    if (!cancelReserved) {
      TagManager.dataLayer({
        dataLayer: {
          userId: user.lmsUser?.id,
          user: user.lmsUser,
          event: 'gtm-churn-cancel-click',
          eventData: {},
        },
        dataLayerName: 'LMSDataLayer',
      });
    }
    setCancelIsOpen(true);
  }, [cancelReserved, scheduledSubscriptionContract, showToast, user.lmsUser]);

  const handleClickUpgrade = useCallback((): void => {
    if (subscription?.price?.isMultiMonthContract) {
      setModifyFlowIsOpen(true);
      return;
    }
    navigate(`/account/subscription/select`);
  }, [navigate, subscription?.price?.isMultiMonthContract]);

  const handleClickCancelUpdateSchedule = useCallback((): void => {
    setCancelUpdateScheduleIsOpen(true);
  }, []);

  const loading =
    fetchCardLoading ||
    subscriptionLoading ||
    contractLoading ||
    teamExistMySubscriptionLoading ||
    currentContractLoading ||
    isUpgradableLoading;

  return (
    <React.Fragment>
      <Loader display={loading}></Loader>
      {subscription && (
        <React.Fragment>
          <SubscriptionCancelModalsArea
            isOpen={cancelIsOpen}
            toggle={setCancelIsOpen}
            subscription={subscription}
            currentSubscriptionContract={currentSubscriptionContract}
            subscriptionCancellationPeriod={subscriptionCancellationPeriod}
            cancelReserved={cancelReserved}
            reFetch={refetchSubscriptionAll}
          />
          <SubscriptionModifyFlowModal isOpen={modifyFlowIsOpen} toggle={setModifyFlowIsOpen} />
        </React.Fragment>
      )}
      {scheduledSubscriptionContract && (
        <SubscriptionUpdateScheduleCancelModalsArea
          isOpen={cancelUpdateScheduleIsOpen}
          toggle={setCancelUpdateScheduleIsOpen}
          reFetch={refetchSubscriptionAll}
        />
      )}
      <AccountTabLayout
        activeTab="subscription"
        permissionCheck={permissionCheck}
        isSocialLoginUser={isSocialLoginUser()}
        metaTitle={metaTitle}
      >
        {!loading ? (
          <Wrapper>
            <Container>
              <Title>現在の契約</Title>
              {subscription && currentSubscriptionContract ? (
                <PlanInfo>
                  <dt>ご利用中のプラン</dt>
                  <dd>{subscription.price?.product.name}</dd>
                  <dt>契約期間</dt>
                  <dd>
                    {formatDate(currentSubscriptionContract.periodStart)} ~{' '}
                    {formatDate(currentSubscriptionContract.periodEnd)}
                  </dd>
                  {subscription.price?.isMultiMonthContract && subscriptionCancellationPeriod && (
                    <>
                      <dt>解約受付期間</dt>
                      <dd>
                        {formatDate(subscriptionCancellationPeriod.startDate)} ~{' '}
                        {formatDate(subscriptionCancellationPeriod.endDate)}
                      </dd>
                    </>
                  )}
                  <dt>お支払い方法</dt>
                  <dd>**** **** **** {currentCardNumberLast4}</dd>
                  <dt>支払いステータス</dt>
                  {/* TODO api側から返ってくるstatusが大文字になったら.toUpperCase()を削除 */}
                  <dd>{StripeStatusLabels[subscription?.providerStatus.toUpperCase()]}</dd>
                </PlanInfo>
              ) : (
                <React.Fragment>
                  {validTeamSubscription ? (
                    <Text>チームプランを契約済みのため、個人プランの契約はできません</Text>
                  ) : (
                    <React.Fragment>
                      <Text>お客様は現在サブスクリプションに登録されていません</Text>
                      {permissionCheck(
                        FunctionType.AccountSubscriptionRegister,
                        PermissionType.Read,
                      ) && <StartButton to="/account/subscription/lp">開始する</StartButton>}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              <Spacer height={'1rem'} />
              <PlanInfoFooterArea>
                <div>
                  <Info>※こちらにマンツーマンレッスンの契約内容は記載されておりません。</Info>
                  <Info>
                    ※「特定商取引法に基づく表記」は
                    <a href="https://www.sejuku.net/corp/legal" target="_blank">
                      こちら
                    </a>
                    をご覧ください。
                  </Info>
                  {(subscription?.providerStatus === ProviderSubscriptionStatus.Incomplete ||
                    subscription?.providerStatus === ProviderSubscriptionStatus.PastDue) && (
                    <Alert>
                      ※決済に失敗しました。新しい支払い情報を設定すると決済を再試行します。
                    </Alert>
                  )}
                </div>
                {
                  // 登録直後に表示されるケースがあるのでSubscriptionの存在確認も行う
                  subscription && Boolean(isUpgradable) && (
                    <UpgradeButton onClick={handleClickUpgrade} gray>
                      プランを変更する
                    </UpgradeButton>
                  )
                }
              </PlanInfoFooterArea>
              {subscription && (
                <PlanCancelArea>
                  {cancelReserved ? (
                    <div>
                      <Info>上記プランはキャンセル予約済みです。</Info>
                      <Info>
                        次回の請求タイミングまでご利用が可能となっており、次回の請求タイミングで自動的に解約されます。
                      </Info>
                    </div>
                  ) : (
                    <div>
                      <Info>
                        現在のプランは上記契約期間まで有効です。期間満了時に自動的に契約が継続されます。
                      </Info>
                    </div>
                  )}
                  {permissionCheck(FunctionType.AccountSubscription, PermissionType.Delete) && (
                    <CancelButton
                      onClick={handleClickCancel}
                      gray={cancelReserved}
                      red={!cancelReserved}
                    >
                      {cancelReserved ? '解約を取り消す' : '解約申請する'}
                    </CancelButton>
                  )}
                </PlanCancelArea>
              )}
            </Container>
            {scheduledSubscriptionContract ? (
              <Container>
                <Title>次回更新時以降の契約</Title>
                <React.Fragment>
                  <PlanInfo>
                    <dt>ご利用中のプラン</dt>
                    <dd>{scheduledSubscriptionContract.product?.name}</dd>
                    <dt>契約期間</dt>
                    <dd>
                      {formatDate(scheduledSubscriptionContract.startDate)} ~{' '}
                      {formatDate(scheduledSubscriptionContract.endDate)}
                    </dd>
                  </PlanInfo>
                  <Spacer height={'1rem'} />
                  <PlanCancelArea>
                    <div>
                      <Info>
                        上記のプランは、現在のプランが次回更新される時に自動的に有効化されます。
                      </Info>
                    </div>
                    {permissionCheck(FunctionType.AccountSubscription, PermissionType.Delete) && (
                      <CancelButton onClick={handleClickCancelUpdateSchedule} red>
                        プランの変更予約をキャンセルする
                      </CancelButton>
                    )}
                  </PlanCancelArea>
                </React.Fragment>
              </Container>
            ) : (
              ''
            )}
            <SubscriptionContractList
              contracts={contracts}
              total={total}
              page={page}
              perPage={FETCH_LIMIT_LOW}
              setPage={setPage}
            />
          </Wrapper>
        ) : null}
      </AccountTabLayout>
    </React.Fragment>
  );
};

const Container = styled.section`
  padding: 2rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    margin: 0 -2rem;
  `}
`;
const Wrapper = styled.div`
  width: 100%;
  max-width: 54rem;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;

  ${Container} + ${Container} {
    margin-top: 2rem;
  }
`;
const Title = styled.h2`
  margin: 0 auto 2rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1em;

  ${media.lessThan('medium')`
    margin-bottom: 2rem;
  `}
`;
const Text = styled.p`
  font-size: 1rem;
  line-height: 1.125rem;
  text-align: center;

  ${media.lessThan('medium')`
    line-height: 1.375rem;
  `}
`;
const StartButton = styled(Link)`
  display: block;
  width: 12.5rem;
  margin: 3rem auto 2rem;
  padding: 1rem 1rem;
  background: #e2001b;
  border: none;
  border-radius: 0.1875rem;
  cursor: pointer;
  outline: none;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1em;
  text-align: center;

  ${media.lessThan('medium')`
    max-width: 20rem;
  `}
`;
const Alert = styled.p`
  margin-top: 0.6rem;
  font-size: 0.8rem;
  color: red;
`;

const Info = styled.p`
  font-size: 0.8rem;
  line-height: 1.3rem;
  color: rgba(0, 0, 0, 0.87);

  a {
    font-size: 0.8rem;
    color: #e2001b;
  }
`;

const PlanInfo = styled.dl`
  display: flex;
  flex-wrap: wrap;

  dt {
    width: 8.625rem;
    margin-right: 3rem;
    font-weight: 700;

    &:nth-of-type(n + 2) {
      margin-top: 2rem;
    }
  }

  dd {
    width: calc(100% - 11.625rem);

    &:nth-of-type(n + 2) {
      margin-top: 2rem;
    }
  }

  ${media.lessThan('medium')`
    flex-direction: column;

    dt {
      width: 100%;
    }

    dd {
      width: 100%;
      margin-top: 1rem;
      line-height: 1.375rem;

      &:nth-of-type(n + 1) {
        margin-top: 1rem;
      }
    }
  `}
`;
const PlanInfoFooterArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 1rem;
  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;

const PlanCancelArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  box-sizing: border-box;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;

const CancelButton = styled(Button)`
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 400;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;

const UpgradeButton = styled(Button)`
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 400;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
