import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import {
  PracticeSearchFragment,
  PracticeSubmitDetailReviewStatus,
  PracticeSubmitListFragment,
  PracticeSubmitSortKey,
} from '../../gen/graphql';

import { FIRST_PAGE, FETCH_LIMIT_LOW } from '../../const/Page';

import { ALL, INITIAL } from '../../const/Filter';
import { PracticeSubmitFilter } from './PracticeSubmitFilter';
import { EmptyBlock } from '../molecules/EmptyBlock';
import { PracticeSubmitsList } from './PracticeSubmitsList';
import { PracticeSubmitFilterModal } from './PracticeSubmitFilterModal';
import { useURL } from '../../common/customHooks/URL';
import { getReviewed, getReviewStatus, getSortKey } from '../../utils/PracticeSubmit';

export interface PracticeSubmitSearchInputParams {
  sortKey: PracticeSubmitSortKey;
  reviewed?: boolean;
  reviewStatus?: PracticeSubmitDetailReviewStatus;
  practiceID?: string;
  page: number;
  limit: number;
}

interface PracticeSubmitTabContentProps {
  practiceSubmits: PracticeSubmitListFragment[];
  total: number;
  practices?: PracticeSearchFragment[]; // 教材詳細で必須
  fetch: (input: PracticeSubmitSearchInputParams) => void;
}

export const PracticeSubmitTabContent: React.FC<PracticeSubmitTabContentProps> = ({
  practiceSubmits,
  total,
  practices,
  fetch,
}) => {
  const { setParams, queries } = useURL();
  const sortKey = queries?.sort_key ?? PracticeSubmitSortKey.CreatedAt;
  const reviewed = queries?.reviewed ? Number(queries.reviewed) : ALL;
  const reviewStatus = queries?.review_status ?? ALL.toString();
  const practiceID = queries?.practice_id ? queries.practice_id : INITIAL.toString();
  const page = queries?.page ? Number(queries.page) : FIRST_PAGE;

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const query = useMemo((): PracticeSubmitSearchInputParams => {
    const params: PracticeSubmitSearchInputParams = {
      sortKey: getSortKey(sortKey),
      reviewed: getReviewed(reviewed),
      reviewStatus: getReviewStatus(reviewStatus),
      page: page,
      limit: FETCH_LIMIT_LOW,
    };
    // 教材詳細で入力時のみ設定
    if (practiceID !== INITIAL.toString()) params.practiceID = practiceID;

    return params;
  }, [sortKey, reviewed, reviewStatus, practiceID, page]);

  const handleSortKeySelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'sort_key', value: value }, { name: 'page' }]);
  };

  const handleReviewedSelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'reviewed', value: value }, { name: 'page' }]);
  };

  const handleReviewStatusSelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'review_status', value: value }, { name: 'page' }]);
  };

  const handlePracticeIDSelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'practice_id', value: value }, { name: 'page' }]);
  };

  const filterProps = {
    title: '絞り込み',
    displaySortKey: {
      sortKey: sortKey,
      handleSortKeySelect,
    },
    displayReviewed: {
      reviewed: reviewed,
      handleReviewedSelect,
    },
    displayReviewStatus: {
      reviewStatus: reviewStatus,
      handleReviewStatusSelect,
    },
    displayPractice: practices
      ? {
          practices,
          practiceID,
          handlePracticeIDSelect,
        }
      : undefined,
  };

  useEffect(() => {
    fetch(query);
  }, [fetch, query]);

  return (
    <>
      {isFilterOpen && (
        <PracticeSubmitFilterModal
          sortKey={sortKey}
          reviewed={reviewed}
          reviewStatus={reviewStatus}
          practices={practices}
          practiceID={practiceID}
          isOpen={isFilterOpen}
          isCloseModal={() => setIsFilterOpen(false)}
        />
      )}
      <Container>
        <MainContent>
          {practiceSubmits.length !== 0 ? (
            <PracticeSubmitsList
              practiceSubmits={practiceSubmits}
              total={total}
              isOpenModal={() => setIsFilterOpen(true)}
            />
          ) : (
            <EmptyBlock title="提出された課題はありません">
              ユーザーが提出されるまで、
              <br />
              今しばらくお待ちください。
            </EmptyBlock>
          )}
        </MainContent>
        <SideContent>
          <PracticeSubmitFilter {...filterProps} />
        </SideContent>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 1.25rem;

  ${media.lessThan('medium')`
    display: block;
  `}
`;
const MainContent = styled.div`
  flex: 1;
`;
const SideContent = styled.div`
  width: 284px;

  ${media.lessThan('medium')`
    display: none;
  `}
`;
