import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../atoms/LpInner';
import { LpButton } from '../../atoms/LpButton';

import BgPc from '../../../static/image/lp/campaign_bg_pc.png';
import BgSp from '../../../static/image/lp/campaign_bg_sp.png';

import { useGetCampaignQuery } from '../../../gen/graphql';

interface Props {
  onClickCV?: () => void;
  className?: string;
  subscription?: boolean;
}

export const Campaign: React.FC<Props> = (props) => {
  const { data } = useGetCampaignQuery();

  return data?.getCampaign ? (
    <Section className={props.className}>
      <Inner>
        <Title>
          <TitleText>
            学び応援キャンペーン
            <br />
            実施中
          </TitleText>
        </Title>
        <SubTitle>
          <span>登録するなら今がおすすめ</span>
        </SubTitle>
        <Card>
          <Image src={data.getCampaign.imageURL} alt={data.getCampaign.title} />
        </Card>
        {Boolean(props.onClickCV) && (
          <Link onClick={props.onClickCV!} subscription={props.subscription} />
        )}
      </Inner>
    </Section>
  ) : (
    <></>
  );
};

const Image = styled.img`
  width: 100%;
  box-sizing: border-box;
  filter: drop-shadow(0 0.25rem 1rem rgb(0, 0, 0, 0.1));
`;

const Section = styled.section`
  padding: 5rem 0 7.5rem;
  background: #fff6f6 url(${BgPc}) center / contain no-repeat;

  ${media.lessThan('medium')`
    padding: 2rem 0 5.5rem;
    background-image: url(${BgSp});
  `}
`;
const Title = styled.h2`
  text-align: center;
  position: relative;
`;
const TitleText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e2001b;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 3rem;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    width: 0.125rem;
    height: 2.25rem;
    background: #e2001b;
  }

  &:before {
    margin-right: 1rem;
    transform: rotate(-30deg);
  }
  &:after {
    margin-left: 1rem;
    transform: rotate(30deg);
  }

  br {
    display: none;
  }

  ${media.lessThan('medium')`
    font-size: 1.25rem;
    line-height: 1.75rem;
  `}

  ${media.lessThan('small')`
    &:before,
    &:after {
      width: .0625rem;
      height: 2.625rem;
    }

    &:before {
      margin-right: .5rem;
      transform: rotate(-12deg);
    }

    &:after {
      margin-left: .5rem;
      transform: rotate(12deg);
    }

    br {
      display: block;
    }
  `}
`;
const SubTitle = styled.h3`
  margin-top: 1.5rem;
  text-align: center;

  span {
    display: inline-block;
    color: #eb0000;
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 1;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 3px;
      margin-top: 0.5rem;
      background: transparent;
      background-image: radial-gradient(
        circle farthest-side,
        #eb0000 20%,
        transparent 20%,
        transparent 100%
      );
      background-size: 15px 3px;
      background-position: 0 0;
    }
  }

  ${media.lessThan('medium')`
    margin-top: .25rem;

    span {
      font-size: 1rem;
      line-height: 1.5;

      &:after {
        margin-top: .3125rem;
      }
    }
  `}
`;

const Card = styled.div`
  width: 100%;
  max-width: 460px;
  margin: 2rem auto 0;

  ${media.lessThan('medium')`
    max-width: 392px;
    margin-top: 1.5rem;
  `}
`;

const Link = styled(LpButton)`
  margin-top: 1.875rem;

  ${media.lessThan('medium')`
    margin-top: 2rem;
  `}
`;
