import { AccountTabLayout } from '../../templates/AccountTabLayout';
import { useUser } from '../../../redux/user/useUser';
import { LOWER_META_TITLE } from '../../../const/Service';
import { PageWrapper } from '../../atoms/PageWrapper';
import { InvoiceWrapperPersonal } from '../../organisms/InvoiceWrapperPersonal';

export const AccountInvoice: React.FC = () => {
  const metaTitle = `請求情報 | ${LOWER_META_TITLE}`;

  const { permissionCheck, isSocialLoginUser } = useUser();

  return (
    <>
      <AccountTabLayout
        activeTab="invoice"
        permissionCheck={permissionCheck}
        isSocialLoginUser={isSocialLoginUser()}
        metaTitle={metaTitle}
      >
        <PageWrapper>
          <InvoiceWrapperPersonal />
        </PageWrapper>
      </AccountTabLayout>
    </>
  );
};
