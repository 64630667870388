import { useCallback, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { UserPageLayout } from '../../templates/UserPageLayout';
import { NotFoundPage } from '../public/NotFound';
import {
  useGetTimelinesByUserIdQuery,
  useGetUserForUserPageLayoutQuery,
} from '../../../gen/graphql';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Loader } from '../../molecules/Loader';
import styled from 'styled-components';
import { TimelineList } from '../../organisms/TimelineList';
import media from 'styled-media-query';
import { LOWER_META_TITLE } from '../../../const/Service';

export const UserTimelinesContainer = (): JSX.Element => {
  const paramID = useParams<{ id: string }>().id;
  const pathUserID = Number(paramID);
  if (Number.isNaN(pathUserID)) return NotFoundPage;

  return <UserTimelines userID={pathUserID} />;
};

interface UserTimelinesProps {
  userID: number;
}

const UserTimelines = (props: UserTimelinesProps): JSX.Element => {
  const metaTitle = `投稿 | ${LOWER_META_TITLE}`;

  const location = useLocation();

  const { data: userData, loading: userLoading } = useGetUserForUserPageLayoutQuery({
    variables: { id: props.userID },
  });

  const { data, loading, refetch, fetchMore, error } = useGetTimelinesByUserIdQuery({
    variables: { first: 20, userID: props.userID },
  });
  const pageInfo = data?.getTimelinesByUserID.pageInfo;

  const [refetching, setRefetching] = useState(false);

  const refetchTimelines = useCallback(async (): Promise<void> => {
    setRefetching(true);
    try {
      await refetch();
    } catch (e) {
      // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
    } finally {
      setRefetching(false);
    }
  }, [refetch]);

  const fetchMoreTimelines = useCallback(async (): Promise<void> => {
    if (!pageInfo?.hasNextPage) {
      return;
    }

    try {
      await fetchMore({
        variables: {
          after: pageInfo?.endCursor,
        },
      });
    } catch (e) {
      // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
    }
  }, [pageInfo, fetchMore]);

  // ユーザー情報取得前は画面レイアウトを表示できないためローディングだけを返す
  if (userLoading) {
    return <Loader display />;
  }

  if (!userData || !userData.user) {
    return NotFoundPage;
  }

  return (
    <UserPageLayout activeTab="timelines" user={userData.user} metaTitle={metaTitle}>
      <Loader display={loading || refetching} />
      <PageWrapper>
        {error ? (
          <Err>
            <h4>投稿の読み込みが行われませんでした</h4>
            <p>回線状況を確認して再読み込みを行ってください</p>
            <p className="reload" onClick={refetchTimelines}>
              再読み込み
            </p>
          </Err>
        ) : (
          <>
            {data?.getTimelinesByUserID.items.length === 0 && (
              <Err>
                <h4>投稿がまだありません</h4>
              </Err>
            )}
            <TimelineList
              items={data?.getTimelinesByUserID.items ?? []}
              refetch={refetchTimelines}
              fetchMore={fetchMoreTimelines}
              hasNext={Boolean(pageInfo?.hasNextPage)}
              referer={location.pathname}
            />
          </>
        )}
      </PageWrapper>
    </UserPageLayout>
  );
};

const Err = styled.div`
  padding: 3rem 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  h4 {
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
  }

  p {
    margin-top: 6px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
  }

  .reload {
    background-color: #eb0000;
    display: flex;
    width: 160px;
    height: 43px;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    cursor: pointer;
    color: #ffffff;
    font-size: 0.9rem;
    font-weight: bold;
    margin: 0 auto;
    margin-top: 21px;
  }

  ${media.lessThan('medium')`
    width: calc(100% - 2rem);
    margin: 0 auto 2rem;
  `}
`;
