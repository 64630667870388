import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { BasicLayout } from '../../templates/BasicLayout';

import { H4 } from '../../atoms/Typography';
import { PageWrapper } from '../../atoms/PageWrapper';
import { TopCourseInfo } from '../../organisms/TopCourseInfo';
import { Onboarding } from '../../organisms/Onboarding';
import { StudentHomeSummary } from '../../organisms/StudentHomeSummary';
import { Loaders } from '../../../common/Loader';

import { TopProgramCard } from '../../organisms/TopProgramCard';
import { TopNextLessonCard } from '../../organisms/TopNextLessonCard';
import { TopNextSessionCard } from '../../organisms/TopNextSessionCard';

import { useUser } from '../../../redux/user/useUser';
import { TopNextLessonEmptyCard } from '../../organisms/TopNextLessonEmptyCard';
import { TopProgramEmptyCard } from '../../organisms/TopProgramEmptyCard';
import {
  BannerCidType,
  ProgramSearchType,
  useGetCourseProgramsQuery,
  useGetCoursesForStudentHomeQuery,
  useGetLastStudiedWhileStudyingProgramQuery,
  useGetNextSessionForStudentQuery,
  useGetNextLessonForStudentQuery,
  useGetProgramsQuery,
  useGetStudySummaryQuery,
  useGetNextSpotLessonQuery,
} from '../../../gen/graphql';
import { useBannerCids } from '../../../common/customHooks/BannerCids';
import { CourseProgramArticle } from '../../organisms/CourseProgramArticle';
import { FETCH_LIMIT_MIDDLE } from '../../../const/Page';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { LOWER_META_TITLE } from '../../../const/Service';
import { NickNameWarningModal } from '../../organisms/NickNameWarningModal';
import { useNavigate } from 'react-router-dom';

enum ShowNextType {
  SPOT_LESSON,
  SPOT_LESSON_EMPTY,
  LESSON,
  SESSION,
  EMPTY,
}

export const Top: React.FC = () => {
  const metaTitle = `ホーム | ${LOWER_META_TITLE}`;

  const { user, permissionCheck } = useUser();
  const navigate = useNavigate();

  const [isNickNameWarningModalOpen, setIsNickNameWarningModalOpen] = useState(
    !user.lmsUser?.nickName,
  );

  const { pcBanners } = useBannerCids([BannerCidType.TopCenter], FETCH_LIMIT_MIDDLE);
  const centerPcBanners = pcBanners.filter((pcBanner) => pcBanner.type === BannerCidType.TopCenter);

  const handleModalLink = (): void => {
    navigate('/account/profile?mode=editing');
  };

  const permissionShowNextTypeSpotLesson = permissionCheck(
    FunctionType.ShowNextTypeSpotLesson,
    PermissionType.Read,
  );
  const { data: nextLessonData, loading: nextLessonLoading } = useGetNextLessonForStudentQuery({
    // スポットレッスンを取得する場合は取得しない
    skip: permissionShowNextTypeSpotLesson,
  });
  const nextLesson = nextLessonData?.nextLessonForStudent;

  const { data: completedStudyProgramData, loading: completedStudyProgramLoading } =
    useGetProgramsQuery({
      variables: {
        input: {
          programSearchType: ProgramSearchType.CompletedStudy,
        },
        userID: user.lmsUser?.id,
      },
    });
  const completedStudyProgramsCount = completedStudyProgramData?.programs.items.length ?? 0;

  const { data: studySummaryData, loading: studySumamaryLoading } = useGetStudySummaryQuery();
  const studyReportCount = studySummaryData?.getStudySummary.studyReportCount ?? 0;
  const studyReportCurrentRunningLayDays =
    studySummaryData?.getStudySummary?.studyReportCurrentRunningLayDays ?? 0;
  const studyReportRunningLayDays =
    studySummaryData?.getStudySummary?.studyReportRunningLayDays ?? 0;

  const {
    data: lastStudiedWhileStudyingProgramData,
    loading: lastStudiedWhileStudyingProgramLoading,
  } = useGetLastStudiedWhileStudyingProgramQuery({
    variables: {
      userID: user.lmsUser?.id,
    },
    skip: !permissionCheck(FunctionType.StudyLog, PermissionType.Read),
  });
  const lastStudiedWhileStudyingProgram =
    lastStudiedWhileStudyingProgramData?.lastStudiedWhileStudyingProgram;

  const { data: coursesData, loading: coursesLoading } = useGetCoursesForStudentHomeQuery();
  const courses = coursesData?.coursesForStudent ?? [];

  const hasCoaching = courses.some((c) => c.coaching);
  // レッスン、セッションの回数は全コース分の合算値
  const [lessonCount, totalLessonCount, sessionCount, totalSessionCount] = courses.reduce(
    ([lessonCount, totalLessonCount, sessionCount, totalSessionCount], c) => [
      lessonCount + (c.finishedLessonCount ?? 0),
      totalLessonCount + (c.plannedLessonCount ?? 0),
      sessionCount + (c.coaching?.finishedSessionCount ?? 0),
      totalSessionCount + (c.coaching?.plannedSessionCount ?? 0),
    ],
    [0, 0, 0, 0],
  );

  const { data: nextSessionData, loading: nextSessionLoading } = useGetNextSessionForStudentQuery({
    // スポットレッスンを取得する場合は取得しない
    skip: permissionShowNextTypeSpotLesson,
  });
  const nextSession = nextSessionData?.nextSessionForStudent;

  const { data: nextSpotLessonData, loading: nextSpotLessonLoading } = useGetNextSpotLessonQuery({
    skip: !permissionShowNextTypeSpotLesson,
  });
  const nextSpotLesson = nextSpotLessonData?.nextSpotLesson;

  const isShowType = useMemo((): ShowNextType => {
    if (permissionShowNextTypeSpotLesson) {
      if (nextSpotLesson) {
        return ShowNextType.SPOT_LESSON;
      } else {
        return ShowNextType.SPOT_LESSON_EMPTY;
      }
    }

    // セッションとレッスンの近い方を取得する
    if (nextLesson && nextSession) {
      if (nextLesson.startAt < nextSession.startAt) {
        return ShowNextType.LESSON;
      } else {
        return ShowNextType.SESSION;
      }
    } else if (nextLesson) {
      return ShowNextType.LESSON;
    } else if (nextSession) {
      return ShowNextType.SESSION;
    }

    return ShowNextType.EMPTY;
  }, [permissionShowNextTypeSpotLesson, nextSpotLesson, nextLesson, nextSession]);

  const { data: courseProgramsData, loading: courseProgramsLoading } = useGetCourseProgramsQuery({
    variables: {
      input: {
        registeredCoursePrograms: true,
      },
    },
  });

  return (
    <>
      <NickNameWarningModal
        isOpen={isNickNameWarningModalOpen}
        onClose={(): void => setIsNickNameWarningModalOpen(false)}
        handleLink={handleModalLink}
      />
      <BasicLayout pageTitle="ホーム" hasIntercom metaTitle={metaTitle}>
        <PageWrapper>
          <Container>
            <Left>
              {user.lmsUser && (
                <>
                  <WelcomeBackMessage>
                    おかえりなさい、{user.lmsUser.nickName}さん
                  </WelcomeBackMessage>
                  {completedStudyProgramLoading || studySumamaryLoading || coursesLoading ? (
                    <Loaders width="25%" height="5.625rem" margin="0" number={4} />
                  ) : (
                    <StudentHomeSummary
                      user={user.lmsUser}
                      studyReportCount={studyReportCount}
                      studyReportCurrentRunningLayDays={studyReportCurrentRunningLayDays}
                      studyReportRunningLayDays={studyReportRunningLayDays}
                      completedProgramCount={completedStudyProgramsCount}
                      lessonCount={lessonCount}
                      totalLessonCount={totalLessonCount}
                      sessionCountInfo={
                        hasCoaching
                          ? { count: sessionCount, totalCount: totalSessionCount }
                          : undefined
                      }
                    />
                  )}
                </>
              )}

              {courses.map((c, i) => (
                <TopCourseInfo course={c} key={i} />
              ))}

              <Onboarding />

              <CardContainer>
                {nextLessonLoading || nextSessionLoading || nextSpotLessonLoading ? (
                  <Loaders width="100%" height="9.375rem" margin="0" number={1} />
                ) : isShowType === ShowNextType.SPOT_LESSON && nextSpotLesson ? (
                  <TopNextLessonCard
                    startAt={new Date(nextSpotLesson.startAt)}
                    endAt={new Date(nextSpotLesson.endAt)}
                    title={nextSpotLesson.categories?.map(({ name }) => name).join('、')}
                    user={nextSpotLesson.instructorUser ?? undefined}
                    isSpot
                  />
                ) : isShowType === ShowNextType.SPOT_LESSON_EMPTY ? (
                  <TopNextLessonEmptyCard isSpot />
                ) : isShowType === ShowNextType.SESSION && nextSession ? (
                  <TopNextSessionCard
                    startAt={new Date(nextSession.startAt)}
                    endAt={new Date(nextSession.endAt)}
                    title={nextSession.title}
                    user={nextSession.coaching.coach?.user}
                  />
                ) : isShowType === ShowNextType.LESSON && nextLesson ? (
                  // startAt endAtがないことは仕様的にありえないはず */}
                  <TopNextLessonCard
                    startAt={new Date(nextLesson.startAt ?? 0)}
                    endAt={new Date(nextLesson.endAt ?? 0)}
                    title={nextLesson.title}
                    user={nextLesson.course?.instructor?.user}
                  />
                ) : (
                  <TopNextLessonEmptyCard />
                )}
                {lastStudiedWhileStudyingProgramLoading ? (
                  <Loaders width="100%" height="9.375rem" margin="0" number={1} />
                ) : lastStudiedWhileStudyingProgram ? (
                  <TopProgramCard
                    programId={lastStudiedWhileStudyingProgram.id}
                    title={lastStudiedWhileStudyingProgram.title}
                    thumbnail={lastStudiedWhileStudyingProgram.thumbnail}
                    studyLogCount={lastStudiedWhileStudyingProgram.studyLogCount}
                    chapterCount={lastStudiedWhileStudyingProgram.chapterCount ?? 0}
                  />
                ) : (
                  <TopProgramEmptyCard />
                )}
              </CardContainer>

              <CenterPcBanner>
                {centerPcBanners.map((centerPcBanner) => (
                  <a
                    href={centerPcBanner.banner.link + centerPcBanner.cid}
                    target="_blank"
                    key={centerPcBanner.id}
                  >
                    <img src={centerPcBanner.banner.imageURL} alt={centerPcBanner.banner.title} />
                  </a>
                ))}
              </CenterPcBanner>

              <Header>
                <H4 color="DARK">おすすめコース教材</H4>
              </Header>
              <CurriculumContainer>
                {!courseProgramsLoading ? (
                  courseProgramsData?.coursePrograms?.map((p) => {
                    return (
                      <StyledCourseProgramArticle
                        id={p.id}
                        key={p.id}
                        image={p.image ?? ''}
                        title={p.title ?? ''}
                        description={p.description ?? ''}
                        totalChapterCount={p.totalChapterCount}
                        totalRequireTime={p.totalRequireTime}
                        totalCompletedTime={p.totalCompletedTime}
                      />
                    );
                  })
                ) : (
                  <Loaders
                    width="calc(33.3% - 0.75rem)"
                    height="14.0625rem"
                    margin="0 0.75rem 1rem 0"
                    number={9}
                  />
                )}
              </CurriculumContainer>
            </Left>
          </Container>
        </PageWrapper>
      </BasicLayout>
    </>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  ${media.lessThan('medium')`
    display: block;
  `}
`;

const WelcomeBackMessage = styled.p`
  font-weight: 900;
  font-size: 1rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 1rem;
`;

const Left = styled.section`
  flex: 1;
`;

const CenterPcBanner = styled.section`
  position: relative;
  width: 100%;
  min-width: 284px;

  > a {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;

    &:first-of-type {
      margin-top: 2rem;
    }

    &:last-of-type {
      margin-bottom: 2rem;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  ${media.lessThan('small')`
    display: none;
  `}
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`;

const CardContainer = styled.section`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
  width: 100%;
  margin-top: 1rem;

  & > * {
    flex: 1;
  }

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 1rem;

    & > * {
      width: 100%;
    } 
  `}
`;

const CurriculumContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 2rem 1.25rem;

  ${media.lessThan('medium')`
    gap: 1.5rem 1rem;
  `}
`;

const StyledCourseProgramArticle = styled(CourseProgramArticle)`
  width: calc(50% - 0.75rem);

  ${media.lessThan('medium')`
    width: calc(50% - .5rem);
  `}

  ${media.lessThan('small')`
    width: 100%;
  `}
`;
