import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { startOfMonth, endOfMonth, endOfDay } from 'date-fns';

import { TabNavigationLayout } from '../../templates/TabNavigationLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { SpotLessonFilter } from '../../organisms/SpotLessonFilter';
import { SpotLessonArticle } from '../../organisms/SpotLessonArticle';
import Pagination from '../../organisms/Pagination';
import { useToastsContext } from '../../../context/ToastsProvider';
import { Mv } from '../../organisms/Mv';
import {
  useCompleteSpotLessonForInstructorMutation,
  useNonAttendanceSpotLessonForInstructorMutation,
  useGetSpotLessonsForInstructorLazyQuery,
  SpotLessonPhase,
} from '../../../gen/graphql';
import { FETCH_LIMIT_LOW } from '../../../const/Page';

import MvImage from '../../../static/image/mv/instructor_spot_lesson.svg';

import { SPOT_LESSON_TIME } from '../../../const/SpotLesson';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import { getApiErrorMessage } from '../../../utils/graphqlError';

export const InstructorSpotLessons: React.FC = () => {
  const metaTitle = `レッスン | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}のレッスンページです。現役エンジニアとの1on1レッスンを実施できます。学習中のエラー解決や何気ない疑問はもちろん、転職や副業などの相談もできます。`;

  const { showToast } = useToastsContext();
  const [startAt, setStartAt] = React.useState(startOfMonth(new Date()));
  const [endAt, setEndAt] = React.useState(endOfMonth(new Date()));
  const [statuses, setStatuses] = React.useState<SpotLessonPhase[]>([
    SpotLessonPhase.Incomplete,
    SpotLessonPhase.Complete,
    SpotLessonPhase.Cancel,
    SpotLessonPhase.NonAttendance,
  ]);
  const navigate = useNavigate();
  const query = queryString.parse(useLocation().search);
  const firstPage =
    query.page && typeof query.page === 'string' && !isNaN(parseInt(query.page))
      ? parseInt(query.page)
      : 1;
  const [page, setPage] = useState<number>(firstPage);
  const perPage = FETCH_LIMIT_LOW;

  const [completeSpotLesson] = useCompleteSpotLessonForInstructorMutation();

  const [nonAttendanceSpotLesson] = useNonAttendanceSpotLessonForInstructorMutation();

  // NOTE:日付変更時にFromを選択直後にToがNullになるため、LazyQueryを利用して両方揃った場合にクエリを発行するように制御
  const [getSpotLesson, { data, loading: isLoadingSpotLesson }] =
    useGetSpotLessonsForInstructorLazyQuery();
  const spotLessons = data?.spotLessonsForInstructor.items ?? [];
  const total = data?.spotLessonsForInstructor.total ?? 0;

  const fetchSpotLessons = useCallback(
    async (
      page: number,
      newStartAt: Date,
      newEndAt: Date,
      statuses: SpotLessonPhase[],
    ): Promise<void> => {
      try {
        await getSpotLesson({
          variables: {
            input: {
              limit: perPage,
              page: page,
              phases: statuses,
              from: newStartAt.toISOString(),
              to: newEndAt.toISOString(),
            },
          },
        });
      } catch {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      }
    },
    [getSpotLesson, perPage],
  );

  const onChangeDate = (newDates: [Date, Date]) => {
    const [newStartAt, newEndAt] = newDates;

    setStartAt(newStartAt);
    setEndAt(newEndAt);

    if (newStartAt && newEndAt) {
      navigate('?page=1');
      fetchSpotLessons(page, newStartAt, endOfDay(newEndAt), statuses);
    }
  };

  const onChangeStatus = (newStatuses: SpotLessonPhase[]) => {
    navigate('?page=1');
    setStatuses(newStatuses);
    fetchSpotLessons(page, startAt, endAt, newStatuses);
  };

  const changePage = (nextPage: number) => {
    navigate(`?page=${nextPage}`);
    setPage(nextPage);
    fetchSpotLessons(nextPage, startAt, endAt, statuses);
  };

  const onClickDone = useCallback(
    async (id: number) => {
      if (isLoadingSpotLesson) return;

      try {
        await completeSpotLesson({
          variables: {
            id: id,
          },
        });
      } catch (e) {
        showToast(1, getApiErrorMessage(e));
        return;
      } finally {
        // 成功時のコールバック(refetch)
        fetchSpotLessons(page, startAt, endAt, statuses);
      }
    },

    [
      completeSpotLesson,
      endAt,
      fetchSpotLessons,
      isLoadingSpotLesson,
      page,
      showToast,
      startAt,
      statuses,
    ],
  );

  const onClickNonAttendance = useCallback(
    async (id: number) => {
      if (isLoadingSpotLesson) return;

      try {
        await nonAttendanceSpotLesson({
          variables: {
            id: id,
          },
        });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        showToast(1, '更新に失敗しました。');
        return;
      } finally {
        // 成功時のコールバック(refetch)
        fetchSpotLessons(page, startAt, endAt, statuses);
      }
    },

    [
      nonAttendanceSpotLesson,
      endAt,
      fetchSpotLessons,
      isLoadingSpotLesson,
      page,
      showToast,
      startAt,
      statuses,
    ],
  );

  useEffect(() => {
    fetchSpotLessons(page, startAt, endAt, statuses);
  }, [endAt, fetchSpotLessons, page, startAt, statuses]);

  const clickMv = () => {
    window.open(
      'https://tayori.com/faq/8921b734331f31134b24218f9de5fc4d5ad32b21/detail/654f5f559a6d8c2c6d04ab4677ffcf33a18df75b',
      '_blank',
    );
  };

  const mvData = {
    title: 'サブスク会員限定サービス「レッスン」',
    description: `レッスンとは、サブスク型プログラミングスクール『${SERVICE_NAME}』の受講生用のサービスです。受講生は1回${SPOT_LESSON_TIME}分間のレッスンをスポットで受講できます。\nレッスンに対応できるインストラクターを募集しております。詳細は下記のリンクからご確認ください。`,
    bg: '#E5ECFD',
    image: MvImage,
    buttonText: '募集要項を見る',
    onClick: clickMv,
    minHeihgt: '320px',
  };

  return (
    <TabNavigationLayout
      pageTitle="レッスン"
      tabs={[
        { label: '予約一覧', to: '/spot_lessons', active: true },
        { label: 'スケジュール管理', to: '/spot_lessons/schedule', active: false },
      ]}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      <PageWrapper>
        <Mv mv={mvData} />
        <Wrapper>
          <SpotLessonFilter
            startDate={startAt}
            endDate={endAt}
            onChange={onChangeDate}
            statuses={statuses}
            onChangeStatus={onChangeStatus}
          />
          <LessonsTitle>予約中のレッスン/過去のレッスン</LessonsTitle>
          {spotLessons.length > 0 ? (
            <>
              <LessonsContainer>
                {spotLessons.map((sl) => {
                  return (
                    <SpotLessonArticle
                      spotLesson={sl}
                      onClickDone={onClickDone}
                      onClickNonAttendance={onClickNonAttendance}
                    />
                  );
                })}
              </LessonsContainer>
              <Pagination total={total} perPage={perPage} page={page} setPage={changePage} />
            </>
          ) : (
            <Empty title="選択された期間内のレッスンはありません" />
          )}
        </Wrapper>
      </PageWrapper>
    </TabNavigationLayout>
  );
};

const Wrapper = styled.div`
  min-height: 100vh;
  margin-top: 2rem;

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}

  h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;

    ${media.lessThan('medium')`
      margin-top: 2rem;
    `}
  }
`;

const LessonsTitle = styled.h4`
  margin-top: 2rem;
  font-size: 1.25rem;
  font-weight: 700;

  ${media.lessThan('medium')`
    margin: 1.5rem auto 1rem;
    font-size: 1rem;
  `}
`;

const LessonsContainer = styled.div`
  padding: 0 2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 2rem;

  ${media.lessThan('medium')`
    margin-top: 1rem;
    padding: 0;
  `}

  & > * + *:not(:nth-of-type(1)) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const Empty = styled(EmptyBlock)`
  margin-top: 2rem;

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;
