import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import { Button } from '../atoms/Button';
import { Modal } from '../molecules/Modal';
import {
  WithdrawalQuestionnaireDifficultyLevel,
  WithdrawalQuestionnaireInput,
  WithdrawalQuestionnaireReasonType,
} from '../../gen/graphql';
import { TextArea, TextAreaHandler } from '../atoms/TextArea';

const ReasonTypes = {
  [WithdrawalQuestionnaireReasonType.Achieved]: '目的を達成したから',
  [WithdrawalQuestionnaireReasonType.StopLearning]: 'プログラミング学習自体をやめる',
  [WithdrawalQuestionnaireReasonType.Expensive]: '金額が高い',
  [WithdrawalQuestionnaireReasonType.Unnecessary]: '必要性を感じなかった',
  [WithdrawalQuestionnaireReasonType.DissatisfiedWithPrograms]: '教材に不満を感じた',
  [WithdrawalQuestionnaireReasonType.DissatisfiedWithLesson]: 'レッスンに不満を感じた',
  [WithdrawalQuestionnaireReasonType.DissatisfiedWithQa]: 'QAに不満を感じた',
  [WithdrawalQuestionnaireReasonType.NotAcquiredSkills]: 'スキルが身につかなかった',
  [WithdrawalQuestionnaireReasonType.FoundOtherServices]: '他に良いサービスが見つかった',
  [WithdrawalQuestionnaireReasonType.Busy]: '忙しくて勉強する時間が取れないから',
  [WithdrawalQuestionnaireReasonType.ChangePlan]: 'プランを変更したいから',
  [WithdrawalQuestionnaireReasonType.UnsupportedSkill]: '習いたい言語が対応不可だったから',
  [WithdrawalQuestionnaireReasonType.Others]: '上記以外の理由',
} as const;

const DifficultyLevels = {
  [WithdrawalQuestionnaireDifficultyLevel.TooDifficult]: '自分には難易度が高すぎた',
  [WithdrawalQuestionnaireDifficultyLevel.JustRight]: 'ちょうどよかった',
  [WithdrawalQuestionnaireDifficultyLevel.TooEasy]: '自分には難易度が低すぎた',
  [WithdrawalQuestionnaireDifficultyLevel.NotUsed]: 'そもそも使用していない',
} as const;

// その他要望などのプレースホルダー(改行位置やスペースに注意)
const otherRequestsPlaceholder = `例 Q&Aの回答がわかりにくかった。/ 機械学習の教材がなかったので増やして欲しい。
     教材の説明が初心者向けに書かれていなく挫折してしまった。
`;

interface Props {
  isOpen: boolean;
  isWithdrawal: boolean; // true:退会 / false:サブスクの解約
  loading?: boolean;
  onSubmit: (input: WithdrawalQuestionnaireInput) => void;
  onClose: () => void;
}

export const WithdrawalQuestionnaireModal: React.FC<Props> = ({
  isOpen,
  isWithdrawal,
  onSubmit,
  onClose,
  ...props
}): JSX.Element => {
  const [reasonTypes, setReasonTypes] = useState<WithdrawalQuestionnaireReasonType[]>([]);
  const [difficultyLevel, setDifficultyLevel] =
    useState<WithdrawalQuestionnaireDifficultyLevel | null>(null);
  const otherRequestsRef = useRef<TextAreaHandler | null>(null);

  // 必須項目が未入力の場合はボタンを非活性化しておく
  const disabledSubmit = reasonTypes.length === 0 || difficultyLevel === null;

  // 理由変更
  const changeReasonType = useCallback(
    (value: string, checked: boolean): void => {
      const reasonType = Object.values(WithdrawalQuestionnaireReasonType).find(
        (reasonType) => reasonType === value,
      );
      if (!reasonType) {
        return;
      }

      if (checked) {
        setReasonTypes([...reasonTypes, reasonType]);
      } else {
        setReasonTypes(reasonTypes.filter((value) => value !== reasonType));
      }
    },
    [reasonTypes],
  );

  // 難易度変更
  const changeDifficultyLevel = useCallback((value: string): void => {
    const difficultyLevel = Object.values(WithdrawalQuestionnaireDifficultyLevel).find(
      (difficultyLevel) => difficultyLevel === value,
    );
    setDifficultyLevel(difficultyLevel ?? null);
  }, []);

  // 送信
  const handleSubmit = useCallback((): void => {
    // 未入力時はボタンを押せないようにはしているが、念の為のチェック＆型エラー回避
    if (reasonTypes.length === 0 || difficultyLevel === null) {
      return;
    }
    onSubmit({
      reasonTypes,
      difficultyLevel,
      otherRequests: otherRequestsRef.current?.getValue().trim(),
    });
  }, [onSubmit, reasonTypes, difficultyLevel]);

  useEffect(() => {
    // 閉じた時にフォームのクリア
    if (!isOpen) {
      setReasonTypes([]);
      setDifficultyLevel(null);
      otherRequestsRef.current?.setValue('');
    }
  }, [isOpen]);

  return (
    <Modal
      underlayer
      isOpen={isOpen}
      loading={props.loading}
      onClose={onClose}
      width={'50rem'}
      header={<Title>アンケート</Title>}
      footer={
        <ButtonWrapper>
          <Button onClick={onClose} gray>
            キャンセル
          </Button>
          <Button onClick={handleSubmit} disabled={disabledSubmit}>
            <span>アンケートに</span>回答して{isWithdrawal ? '退会' : '解約'}する
          </Button>
        </ButtonWrapper>
      }
    >
      <Container>
        <Label>
          <span className="required">必須</span>
          <label>1 {isWithdrawal ? '退会' : 'サブスクを解約'}する理由を教えて下さい。</label>
        </Label>
        <ReasonTypesContainer>
          {Object.entries(ReasonTypes).map(([value, label]) => (
            <StyledFormControlLabel
              key={value}
              control={<Checkbox />}
              label={
                value === WithdrawalQuestionnaireReasonType.Others ? (
                  <>
                    {label}
                    <ReasonTypeNote>※3でご回答ください</ReasonTypeNote>
                  </>
                ) : (
                  label
                )
              }
              value={value}
              checked={Boolean(reasonTypes.find((reasonType) => reasonType === value))}
              onChange={(_, checked) => changeReasonType(value, checked)}
            />
          ))}
        </ReasonTypesContainer>
        <Label>
          <span className="required">必須</span>
          <label>2 教材・カリキュラムなどの難易度は適切でしたか？</label>
        </Label>
        <StyledRadioGroup
          value={difficultyLevel}
          onChange={(_, value) => changeDifficultyLevel(value)}
        >
          {Object.entries(DifficultyLevels).map(([value, label]) => (
            <StyledFormControlLabel key={value} control={<Radio />} label={label} value={value} />
          ))}
        </StyledRadioGroup>
        <Label>
          <label>3 その他要望など</label>
        </Label>
        <StyledTextArea
          name="otherRequests"
          height="8.75rem"
          placeholder={otherRequestsPlaceholder}
          ref={otherRequestsRef}
        />
      </Container>
    </Modal>
  );
};

const Title = styled.h2`
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 auto;
`;
const Container = styled.div`
  padding: 2.25rem 2rem 0.75rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 2rem 1.4rem;
  `}
`;

const ReasonTypesContainer = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
`;

const ReasonTypeNote = styled.span`
  margin-left: 0.25rem;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.36);
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row !important;
  margin-bottom: 1rem;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;

const Label = styled.div`
  display: flex;
  margin: 0 auto 1rem;

  .required {
    display: block;
    margin-right: 0.5rem;
    padding: 0.125rem 0.5rem;
    background-color: #fd6258;
    color: #fff;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.2;
    min-width: 2.25rem;
    height: 1rem;
    box-sizing: border-box;
  }

  label {
    font-size: 1rem;
    font-weight: 700;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  & > .MuiRadio-root,
  .MuiCheckbox-root {
    padding: 0.375rem;
  }
`;

const StyledTextArea = styled(TextArea)`
  padding: 0.625rem 1rem;
  line-height: 1.25;
  &::placeholder {
    color: rgba(0, 0, 0, 0.36);
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  ${media.lessThan('small')`
    span {
      display: none;
    }
  `}
`;
