import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import {
  GetPriceQuery,
  PromotionCodeApplyResultFragment,
  SpCouponDuration,
} from '../../gen/graphql';

interface PlanCardProps {
  price?: GetPriceQuery['getPrice'];
  applyPromotionCodeInfo?: PromotionCodeApplyResultFragment;
}

export const PlanCard: React.FC<PlanCardProps> = ({ price, applyPromotionCodeInfo }) => {
  return (
    <PlanCardWrapper>
      <PlanCardName>{price?.product?.name}</PlanCardName>
      <PlanCardInner>
        {applyPromotionCodeInfo ? (
          <PlanCardDiscountPrice>
            <p className="cost">
              ¥{applyPromotionCodeInfo.price?.toLocaleString()}(税込)/
              {price?.product?.productExtension.contractPeriodTypeLabel}
            </p>
            <PlanCardPrice>
              <span>{getPvdDuration(applyPromotionCodeInfo)}</span>¥
              {applyPromotionCodeInfo.appliedPrice?.toLocaleString()}
              <span>(税込)</span>
            </PlanCardPrice>
          </PlanCardDiscountPrice>
        ) : (
          <PlanCardPrice>
            ¥{price?.unitAmountIncludingTax.toLocaleString()}
            <span>(税込) / {price?.product?.productExtension.contractPeriodTypeLabel}</span>
          </PlanCardPrice>
        )}
        <PlanCardNote>
          登録料{price?.product?.productExtension.contractPeriod === 1 && '・解約金'}
          は一切かかりません
        </PlanCardNote>
      </PlanCardInner>
    </PlanCardWrapper>
  );
};

const getPvdDuration = (applyPromotionCodeInfo: PromotionCodeApplyResultFragment) => {
  switch (applyPromotionCodeInfo.pvdDuration) {
    case SpCouponDuration.Once:
      return '初回のみ！';
    case SpCouponDuration.Forever:
      return '永年！';
    case SpCouponDuration.Repeating:
      return applyPromotionCodeInfo.pvdDurationInMonths + 'ヶ月間！';
    default:
      return '';
  }
};

const PlanCardWrapper = styled.div`
  margin-top: 1rem;
  background: #fff;
  border: 0.125rem solid #eb0000;
  border-radius: 0.25rem;
  text-align: center;
`;
const PlanCardName = styled.h3`
  padding: 0.5rem 0.5rem 0.625rem;
  background: #eb0000;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.125rem;

  ${media.lessThan('medium')`
    padding: .5rem;
    line-height: 1.25rem;
  `}
`;
const PlanCardInner = styled.div`
  padding: 0.75rem 0.5rem;
`;
const PlanCardPrice = styled.p`
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.375rem;

  span {
    font-size: 1rem;
  }
`;
const PlanCardDiscountPrice = styled.div`
  .cost {
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.375rem;
    text-decoration: line-through;
  }

  ${PlanCardPrice} {
    color: #eb0000;
    line-height: 2rem;

    &:before {
      content: '↓';
      display: block;
      color: #000;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.125rem;
    }
  }
`;
const PlanCardNote = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  line-height: 1.125rem;
`;
